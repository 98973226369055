<template>
  <v-main>
    <dashboard-queue-header :clinic="clinicName" />
    <h1
      @click="
        () => {
          handleCheck(false);
        }
      "
      class="mt-4"
    >
      PENGAMBILAN OBAT
    </h1>
    <section style="overflow: hidden;">
      <v-row class="pa-10">
        <v-col cols="8" class="container-counter">
          <div
            v-if="queueStatus.queue.length >= 2"
            class="containter-border w-full"
            style="height: 32.5vh; width: 60vw"
          >
            <h4 class="header-text">{{ queueStatus.queue[0]?.counter }}</h4>
            <h1 class="counter-text">{{ queueStatus.queue[0]?.calling }}</h1>
          </div>
          <div
            v-if="queueStatus.queue.length >= 2"
            class="containter-border"
            style="height: 32.5vh; width: 60vw"
          >
            <h4 class="header-text">{{ queueStatus.queue[1]?.counter }}</h4>
            <h1 class="counter-text">{{ queueStatus.queue[1]?.calling }}</h1>
          </div>
          <div
            v-else
            style="display: flex; justify-content: center; align-items: center; gap: 2rem; flex-wrap: wrap;"
          >
            <div
              class="containter-border w-full"
              style="height: 32.5vh; width: 30vw"
            >
              <h4 class="header-text">{{ queueStatus.queue[0]?.counter }}</h4>
              <h1 class="counter-text">{{ queueStatus.queue[0]?.calling }}</h1>
            </div>
            <div class="containter-border" style="height: 32.5vh; width: 30vw">
              <h4 class="header-text">{{ queueStatus.queue[1]?.counter }}</h4>
              <h1 class="counter-text">{{ queueStatus.queue[1]?.calling }}</h1>
            </div>
            <div
              class="containter-border w-full"
              style="height: 32.5vh; width: 30vw"
            >
              <h4 class="header-text">{{ queueStatus.queue[2]?.counter }}</h4>
              <h1 class="counter-text">{{ queueStatus.queue[2]?.calling }}</h1>
            </div>
            <div class="containter-border" style="height: 32.5vh; width: 30vw">
              <h4 class="header-text">{{ queueStatus.queue[3]?.counter }}</h4>
              <h1 class="counter-text">{{ queueStatus.queue[3]?.calling }}</h1>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="containter-border" style="height: 69vh;">
            <h4 class="header-text mb-4">ANTREAN DILEWATI</h4>
            <div class="container-item-skip">
              <div
                :class="[
                  'container-skip',
                  queueStatus.queueMissed.length > 15 ? 'marquee' : '',
                ]"
              >
                <span
                  v-for="(item, index) in queueStatus.queueMissed"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </v-main>
</template>

<script>
import DashboardQueueHeader from '../../Service/components/PatientQueue/DashboardQueueHeader.vue';
import PharmacyQueueMixin from '@/mixin/SocketMixin/PharmacyQueueMixin';
import { getQueueStatus } from '@/fetchApi/Pharmacy';

export default {
  name: 'PharmacyQueueForPatient',
  components: {
    DashboardQueueHeader,
  },
  mixins: [PharmacyQueueMixin],
  data() {
    return {
      clinicName: 'neurovi clinic medical system',
      data: [],
      queueStatus: {
        queue: [],
        totalMissed: '-',
        queueMissed: [],
      },
      listenMessageCallPatientData: {},
    };
  },
  methods: {
    handleCheck(data) {
      this.emitMessageCheck(data);
    },
    async handleGetQueueStatus() {
      this.handleCheck(true);
      try {
        const response = await getQueueStatus();
        const queueStatus = response.data.data;
        this.queueStatus = {
          totalMissed: queueStatus.totalMissed || '-',
          queue: queueStatus.queue || [],
          queueMissed: queueStatus.queueMissed || [],
        };
      } catch (error) {
        this.queueStatus = {
          totalMissed: '-',
          queue: [],
          queueMissed: [],
        };
        return error;
      }
    },
  },
  mounted() {
    this.handleGetQueueStatus();
    this.listenMessageCall(this.handleGetQueueStatus);
    this.listenMessageCallPatient();
    // this.listenMessageCheck(() => {
    //   console.log('jalan ters');
    //   this.emitMessageCheck(true);
    // });
  },
  created() {
    window.onbeforeunload = async e => {
      await this.handleCheck(false);
      e.preventDefault();
      e.stopImmediatePropagation();
    };
  },
  beforeDestroy() {
    this.handleCheck(false);
  },
};
</script>

<style scoped lang="scss">
.header-text {
  color: #404040;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
  padding: 1rem;
}
.container-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
}
.containter-border {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}
.counter-text {
  padding-top: 2rem;
  color: #137bc0;
  font-size: 5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.15px;
}

.container-item-skip {
  position: relative;
  height: 80%;
  overflow-y: hidden;
  span {
    color: #137bc0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.045px;
  }

  .container-skip {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }
  .marquee {
    position: absolute;
    white-space: wrap;
    will-change: transform;
    animation: marquee 10s linear infinite;
  }
  @keyframes marquee {
    from {
      transform: translate3d(0, 65vh, 0);
    }
    to {
      transform: translate3d(0, -110%, 0);
    }
  }
}
</style>
