<template>
  <v-form ref="form">
    <v-card
      elevation="0"
      :class="
        `mb-4 px-7 pb-4 pt-6 text-start grid-card-${
          !isEditable || isDefault ? '3' : '4'
        }${isEditable && isActive ? '' : ' disabled'}`
      "
    >
      <v-card
        elevation="0"
        rounded="lg"
        :color="isActive ? '#e7f8ec' : '#ffebee'"
        :class="`status-card ${isActive ? 'active' : ''}`"
        v-if="!isEditable"
      >
        <span>{{ isActive ? 'Aktif' : 'Tidak Aktif' }}</span>
        <v-icon class="ml-1" small :color="isActive ? '#2b9c4d' : '#d32f2f'">{{
          isActive ? 'mdi-check' : 'mdi-close'
        }}</v-icon>
      </v-card>
      <v-checkbox
        dense
        single-line
        hide-details
        class="ma-0"
        v-model="isActive"
        @change="$emit('on-change-input', 'checkbox')"
        :value="true"
        v-if="isEditable && !isDefault"
      />
      <div>
        <label
          for="margin"
          :class="`${isActive ? 'required ' : ''}black--text font-weight-bold`"
        >
          Margin
        </label>
        <v-text-field
          type="number"
          @input="$emit('on-change-input', 'margin')"
          :rules="
            rulesByCondition
              ? [
                  rulesByCondition.noEmptyRule,
                  rulesByCondition.max,
                  rulesByCondition.noIdenticalMargin[index],
                ].concat(...additionalRules)
              : []
          "
          name="margin"
          v-model="margin"
          placeholder="0"
          :disabled="!isEditable || !isActive"
          suffix="%"
          single-line
          dense
        />
      </div>
      <div>
        <label
          for="margin"
          :class="`${isActive ? 'required ' : ''}black--text font-weight-bold`"
          >PPN</label
        >
        <v-text-field
          :rules="
            rulesByCondition
              ? [rulesByCondition.noEmptyRule, rulesByCondition.max].concat(
                  ...additionalRules,
                )
              : []
          "
          :disabled="!isEditable || !isActive"
          type="number"
          v-model="ppn"
          name="margin"
          placeholder="0"
          @input="$emit('on-change-input', 'ppn')"
          suffix="%"
          single-line
          dense
        />
      </div>
      <div>
        <label
          for="margin"
          :class="`${isActive ? 'required ' : ''}black--text font-weight-bold`"
          >{{ isEditable ? 'Deskripsi ' : '' }}Harga
          {{ isDefault ? 'Default' : index + 1 }}</label
        >
        <v-text-field
          :disabled="!isEditable || !isActive"
          v-model="description"
          :rules="rulesByCondition ? [rulesByCondition.noEmptyRule] : []"
          name="margin"
          placeholder="contoh: Harga Retail"
          single-line
          @input="$emit('on-change-input', 'description')"
          dense
        />
      </div>
    </v-card>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

export default {
  name: 'InputPriceCard',
  props: {
    isDefault: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    additionalRules: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    margin() {
      if (!this.isDefault) return;
      this.$refs.form.validate();
    },
    ppn() {
      if (!this.isDefault) return;
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapGetters(['getPriceSetting', 'getPriceSettingRules']),
    rulesByCondition: {
      get() {
        if (this.isDefault) {
          return this.getPriceSettingRules;
        } else if (this.getPriceSetting[this.index].isActive) {
          return this.getPriceSettingRules;
        } else {
          return null;
        }
      },
    },
    margin: {
      get() {
        return this.getPriceSetting[this.index].margin;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'margin', value });
      },
    },
    ppn: {
      get() {
        return this.getPriceSetting[this.index].ppn;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'ppn', value });
      },
    },
    description: {
      get() {
        return this.getPriceSetting[this.index].description;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'description', value });
      },
    },
    isActive: {
      get() {
        return this.getPriceSetting[this.index].isActive;
      },
      set(value) {
        this.setPriceSetting({ index: this.index, key: 'isActive', value });
      },
    },
  },
  methods: {
    ...mapMutations(['setPriceSetting']),
  },
};
</script>

<style lang="scss" scoped>
.grid-card-3 {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(2, 20%) auto;
  background-color: #f0f8fe;
}

.grid-card-4 {
  display: grid;
  column-gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 3% 17% 20% auto;
  background-color: #f0f8fe;

  &.disabled {
    background-color: #fafafa;

    label {
      color: #9e9e9e !important;
    }
  }
}

.status-card {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  span {
    color: #d32f2f;
  }

  &.active {
    span {
      color: #2b9c4d;
    }
  }
}

label {
  font-size: 0.9vw;
}
</style>
