<template>
  <div class="main-div">
    <main class="main">
      <v-container class="px-3" fluid>
        <v-layout align-start justify-start column fill-width>
          <PageJumbotron
            title="Billing"
            subtitle="Cek informasi mengenai billing disini."
            image="illustration_card_payment.svg"
          />
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Kategori</h3>
          </v-flex>
          <SubMenu :submenus="submenus" /> </v-layout
      ></v-container>
    </main>
  </div>
</template>

<script>
import PageJumbotron from '@/components/SharedComponent/PageJumbotron.vue';
import SubMenu from '@/components/SharedComponent/SubMenu.vue';

export default {
  data() {
    return {
      submenus: [
        {
          title: 'Tagihan Pasien',
          icon: 'billing_patient_bill',
          route: '/keuangan/billing/tagihan-pasien',
        },
        {
          title: 'Tagihan Pasien BPJS',
          icon: 'billing_report_bill',
          route: '/keuangan/billing/tagihan-pasien-bpjs',
        },
        {
          title: 'Laporan Pendapatan',
          icon: 'finance_verification',
          route: '/keuangan/billing/laporan-pendapatan',
        },
        // {
        //   title: 'Laporan Jasa Medis',
        //   icon: 'finance_income',
        //   route: '/keuangan/laporan-keuangan/laporan-jasa-medis',
        // },
        // {
        //   title: 'Verifikasi Setor Kas',
        //   icon: 'billing_setor_kas',
        //   route: '/keuangan/billing/verifikasi-setor-kas',
        // },
      ],
    };
  },
  components: {
    PageJumbotron,
    SubMenu,
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;
  }
  .text-submenu {
    .text-head {
      color: #000000;
      font-weight: bold;
    }
  }
}
</style>
