var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('v-card',{class:`mb-4 px-7 pb-4 pt-6 text-start grid-card-${
        !_vm.isEditable || _vm.isDefault ? '3' : '4'
      }${_vm.isEditable && _vm.isActive ? '' : ' disabled'}`,attrs:{"elevation":"0"}},[(!_vm.isEditable)?_c('v-card',{class:`status-card ${_vm.isActive ? 'active' : ''}`,attrs:{"elevation":"0","rounded":"lg","color":_vm.isActive ? '#e7f8ec' : '#ffebee'}},[_c('span',[_vm._v(_vm._s(_vm.isActive ? 'Aktif' : 'Tidak Aktif'))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":_vm.isActive ? '#2b9c4d' : '#d32f2f'}},[_vm._v(_vm._s(_vm.isActive ? 'mdi-check' : 'mdi-close'))])],1):_vm._e(),(_vm.isEditable && !_vm.isDefault)?_c('v-checkbox',{staticClass:"ma-0",attrs:{"dense":"","single-line":"","hide-details":"","value":true},on:{"change":function($event){return _vm.$emit('on-change-input', 'checkbox')}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}):_vm._e(),_c('div',[_c('label',{class:`${_vm.isActive ? 'required ' : ''}black--text font-weight-bold`,attrs:{"for":"margin"}},[_vm._v(" Margin ")]),_c('v-text-field',{attrs:{"type":"number","rules":_vm.rulesByCondition
            ? [
                _vm.rulesByCondition.noEmptyRule,
                _vm.rulesByCondition.max,
                _vm.rulesByCondition.noIdenticalMargin[_vm.index],
              ].concat(..._vm.additionalRules)
            : [],"name":"margin","placeholder":"0","disabled":!_vm.isEditable || !_vm.isActive,"suffix":"%","single-line":"","dense":""},on:{"input":function($event){return _vm.$emit('on-change-input', 'margin')}},model:{value:(_vm.margin),callback:function ($$v) {_vm.margin=$$v},expression:"margin"}})],1),_c('div',[_c('label',{class:`${_vm.isActive ? 'required ' : ''}black--text font-weight-bold`,attrs:{"for":"margin"}},[_vm._v("PPN")]),_c('v-text-field',{attrs:{"rules":_vm.rulesByCondition
            ? [_vm.rulesByCondition.noEmptyRule, _vm.rulesByCondition.max].concat(
                ..._vm.additionalRules,
              )
            : [],"disabled":!_vm.isEditable || !_vm.isActive,"type":"number","name":"margin","placeholder":"0","suffix":"%","single-line":"","dense":""},on:{"input":function($event){return _vm.$emit('on-change-input', 'ppn')}},model:{value:(_vm.ppn),callback:function ($$v) {_vm.ppn=$$v},expression:"ppn"}})],1),_c('div',[_c('label',{class:`${_vm.isActive ? 'required ' : ''}black--text font-weight-bold`,attrs:{"for":"margin"}},[_vm._v(_vm._s(_vm.isEditable ? 'Deskripsi ' : '')+"Harga "+_vm._s(_vm.isDefault ? 'Default' : _vm.index + 1))]),_c('v-text-field',{attrs:{"disabled":!_vm.isEditable || !_vm.isActive,"rules":_vm.rulesByCondition ? [_vm.rulesByCondition.noEmptyRule] : [],"name":"margin","placeholder":"contoh: Harga Retail","single-line":"","dense":""},on:{"input":function($event){return _vm.$emit('on-change-input', 'description')}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }