import Constant from '@/const';
import axios from 'axios';

const postCallPatient = async payload => {
  try {
    const { id_counter, ...restPayload } = payload;

    const response = await axios.post(
      Constant.apiUrl.concat(`/pharmacy-queue/call/${id_counter}`),
      restPayload,
    );

    return response;
  } catch (error) {
    const variableError = error;
    throw variableError;
  }
};

export default postCallPatient;
