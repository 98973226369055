<template>
  <div
    :class="
      `text-start ${index === 0 && !isVertical ? 'mr-7' : ''}${
        isVertical ? ' mb-6' : ''
      }`
    "
  >
    <p class="pa-0 ma-0 grey-dark-text">{{ index === 2 ? ' ' : label }}</p>
    <v-card
      elevation="0"
      :class="`main-card ${isActive ? 'active' : ''}`"
      rounded="lg"
      :color="isActive ? '#f0f8fe' : '#fafafa'"
    >
      <v-card
        elevation="0"
        rounded="lg"
        :color="isActive ? '#e7f8ec' : '#ffebee'"
        :class="`status-card ${isActive ? 'active' : ''}`"
      >
        <span>{{ isActive ? 'Aktif' : 'Tidak Aktif' }}</span>
        <v-icon class="ml-1" small :color="isActive ? '#2b9c4d' : '#d32f2f'">{{
          isActive ? 'mdi-check' : 'mdi-close'
        }}</v-icon>
      </v-card>
      <h3 class="mb-3">{{ description || `Harga ${index + 1}` }}</h3>
      <div class="d-flex align-baseline">
        <span>Margin</span>
        <v-text-field
          disabled
          class="mx-5"
          suffix="%"
          dense
          single-line
          hide-details
          :value="margin || 0"
        />
        <span class="mx-5">PPN</span>
        <v-text-field
          disabled
          suffix="%"
          dense
          single-line
          hide-details
          :value="ppn || 0"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DisplayPriceCard',
  props: {
    index: {
      type: Number,
      required: true,
    },
    margin: {
      type: Number,
    },
    ppn: {
      type: Number,
    },
    description: {
      type: String,
    },
    isVertical: {
      type: Boolean,
    },
    isActive: {
      type: Boolean,
    },
  },
  computed: {
    label: {
      get() {
        return ['HARGA DEFAULT', 'MULTIPLE HARGA', ''][this.index];
      },
    },
    isDefault: {
      get() {
        return this.index === 0;
      },
    },
  },
};
</script>

<style scoped lang="scss">
p {
  color: #616161;
}

.main-card {
  padding: 1.2rem 2rem 1.5rem 2rem;
  position: relative;

  span {
    color: #9e9e9e;
    font-size: 0.95vw;
  }

  h3 {
    color: #404040;
    font-size: 0.95vw;
  }

  &.active {
    span {
      color: #404040;
    }
  }

  .status-card {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5vw 0.8vw;
    display: flex;
    align-items: center;

    span {
      color: #d32f2f;
    }

    &.active {
      span {
        color: #2b9c4d;
      }
    }
  }
}

.grey-dark-text {
  color: #616161;
  font-size: 0.8vw;
}
</style>
