import axiosClient from '../../apiClient';

const getDrugsMargin = async ({
  category = '',
  group = '',
  isGeneric = null,
} = {}) => {
  return await axiosClient.get(
    `master/drugs/margin?category=${category}&group=${group}&isGeneric=${isGeneric}`,
  );
};

export default getDrugsMargin;
