const EVENTS = {
  PHARMACY_QUEUE: {
    EMIT_QUEUE_PHARMACY: 'message:queue-pharmacy',
    LISTEN_QUEUE_PHARMACY: 'message:update-pharmacy-handover',
    EMIT_CALL_QUEUE_PHARMACY: 'message:call-sound-pharmacy',
    LISTEN_CALL_QUEUE_PHARMACY: 'message:update-sound-pharmacy',
    EMIT_LOADING_CALL_QUEUE_PHARMACY: 'message:loading-call-sound-pharmacy',
    LISTEN_LOADING_CALL_QUEUE_PHARMACY: 'message:update-loading-sound-pharmacy',
    EMIT_CHECK_DETAIL_PHARMACY: 'message:check-detail-pharmacy',
    LISTEN_CHECK_DETAIL_PHARMACY: 'message:update-check-detail-pharmacy',
  },
};

export default EVENTS;
