import Constant from '@/const';
const axios = require('axios');

const getDrugMargin = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/setting/drug-margin'),
      { params },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getDrugMargin;
