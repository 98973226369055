<template>
  <v-card class="pa-4" style="overflow: hidden;">
    <header-form
      title="Setting Margin dan Harga"
      chip="Global Setting"
      description="Atur harga dan margin untuk semua item."
      :confirmationButtonText="
        getInputState === 'add' ? 'Selesai' : 'Simpan Perubahan'
      "
      confirmationButtonIcon="mdi-check"
      @back="back"
      @confirm="finish"
    />
    <div class="text-start mt-5 mb-2">
      <span class="grey-dark-text font-weight-medium">PREVIEW PERHITUNGAN</span>
    </div>
    <!-- TABLE -->
    <div class="grid-display py-3">
      <span
        v-for="(item, index) in tableHeaders"
        :key="'header-' + index"
        :class="item.class + ' font-weight-medium'"
      >
        {{ item.text }}
      </span>
    </div>
    <v-progress-linear
      indeterminate
      v-if="getLoadingState.previewData"
    ></v-progress-linear>
    <div class="data-table-container py-2 px-3">
      <div
        v-for="(item, index) in table.data"
        :key="'data-' + index"
        class="grid-display row py-3"
      >
        <span class="text-start">{{ item.name }}</span>
        <span class="text-start">{{ item.category }}</span>
        <span class="text-end">{{ currency(item.hna) }}</span>
        <span class="text-end">{{ currency(item.hpp) }}</span>
        <span class="text-start">{{ item.margin }}%</span>
        <span class="text-start">{{ item.ppn }}%</span>
        <span class="text-end">{{ currency(item.price1) }}</span>
        <span class="text-end">{{ currency(item.price2) }}</span>
        <span class="text-end pr-3">{{ currency(item.price3) }}</span>
      </div>
      <div v-if="!table.data.length" class="grey--text">Tidak ada data</div>
    </div>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

import HeaderForm from '../HeaderForm.vue';
import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'GlobalMarginAndPriceCalculationDialog',
  mixins: [formatMixin],
  components: { HeaderForm },
  data() {
    return {
      table: { data: [] },
    };
  },
  mounted() {
    this.getDataPreview();
  },
  methods: {
    ...mapMutations(['setSelectedForm']),
    ...mapActions([
      'resolvePostAddDrugMargin',
      'resolvePutAddDrugMargin',
      'resolveGetPreviewCalculation',
    ]),

    async getDataPreview() {
      // try {
      await this.resolveGetPreviewCalculation();

      const calculatePrice = (hna, index) => {
        if (!this.getPriceSetting?.[index]?.isActive) return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice * this.getPriceSetting?.[index]?.margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * this.getPriceSetting?.[index]?.ppn) / 100;
        return priceAfterMargin + taxAmount;
      };

      this.table.data = this.getPreviewData.map(item => {
        return {
          name: item.name,
          category: item.category,
          hna: item.hna,
          hpp: item.hpp,
          margin: this.getPriceSetting[0].margin,
          ppn: this.getPriceSetting[0].ppn,
          price1: calculatePrice(item.hna, 0),
          price2: calculatePrice(item.hna, 1),
          price3: calculatePrice(item.hna, 2),
        };
      });
      // }
      //  catch (e) {
      //   console.log(e);
      // }
    },
    back() {
      this.setSelectedForm(1);
    },
    finish() {
      this.$emit('finish-setting', {
        origin: 2,
        snackbarText: 'Setting Margin dan Harga berhasil dibuat!',
        confirmationFunction: async () => {
          if (this.getIsEdit) {
            await this.resolvePutAddDrugMargin({
              type: 'global',
              additionalData: [
                {
                  margins: this.getPriceSetting.map(i => {
                    return {
                      ppn: +i.ppn,
                      margin: +i.margin,
                      description: i.description,
                      isActive: i.isActive,
                    };
                  }),
                },
              ],
            });
          } else {
            await this.resolvePostAddDrugMargin({
              type: 'global',
              additionalData: [
                {
                  margins: this.getPriceSetting.map(i => {
                    return {
                      ppn: +i.ppn,
                      margin: +i.margin,
                      description: i.description,
                      isActive: i.isActive,
                    };
                  }),
                },
              ],
            });
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      'getPriceSetting',
      'getInputState',
      'getIsEdit',
      'getLoadingState',
      'getPreviewData',
    ]),
    tableHeaders: {
      get() {
        return [
          {
            text: 'Nama Barang',
            class: 'text-start',
          },
          {
            text: 'Golongan',
            class: 'text-start',
          },
          {
            text: 'HNA',
            class: 'text-end',
          },
          {
            text: 'HPP',
            class: 'text-end',
          },
          {
            text: 'Margin',
            class: 'text-start',
          },
          {
            text: 'PPN',
            class: 'text-start',
          },
        ].concat(
          this.getPriceSetting.map((item, index) => ({
            text: item.description || `Harga ${index + 1}`,
            class: `text-end ${index === 2 ? ' pr-4' : ''}`,
          })),
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-display {
  display: grid;
  grid-template-columns: 25% 12% 8% 8% 4% 4% repeat(3, calc(29% / 3));
  gap: 1rem;
  font-size: 1rem;
  color: #9e9e9e;
  border-bottom: 2px solid #e0e0e0;
  font-size: 0.9rem;

  &.row {
    border-bottom: 2px solid #ededed;
    color: #404040;
  }
}

.data-table-container {
  overflow-y: auto;
  height: 60vh;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.grey-dark-text {
  color: #616161;
}
</style>
