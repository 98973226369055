<template>
  <section class="mb-10 d-flex justify-space-between align-center">
    <div class="d-flex text-start align-center">
      <v-btn
        style="height: 2.5rem !important; border-width: 2px;"
        color="primary"
        class="mr-4"
        v-if="isUsingBackButton"
        depressed
        single-line
        outlined
        @click="back"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div>
        <h2 class="mb-2">{{ title }}</h2>
        <template v-if="isUsingDescription">
          <span class="chip">{{ chip }}</span>
          <span class="grey-light-text">
            {{ description }}
          </span>
        </template>
      </div>
    </div>
    <div class="d-flex justify-end align-center">
      <v-btn
        v-if="isUsingCancelButton"
        depressed
        class="mr-3 text-capitalize"
        style="letter-spacing: 0.001px;"
        :color="cancelButtonColor"
        outlined
        @click="cancel"
      >
        {{ cancelButtonText }}
      </v-btn>
      <v-stepper
        v-if="getSelectedSetting === 'global-category-setting' && !getIsEdit"
        flat
        elevation="0"
        light
        tile
        v-model="stepper"
      >
        <v-stepper-header class="d-flex align-center">
          <v-stepper-step
            style="cursor: pointer"
            @click="back"
            :step="1"
            :complete="stepper > 1"
            >Global Setting</v-stepper-step
          >
          <div style="height: 2px; background-color: #c2c2c2; width: 2vw"></div>
          <v-stepper-step
            style="cursor: pointer"
            @click="
              () => {
                if (!isEnableConfirmButton || stepper === 2) return;
                confirm();
              }
            "
            :step="2"
            :complete="stepper > 2"
            >Kategori</v-stepper-step
          >
        </v-stepper-header>
      </v-stepper>
      <v-btn
        depressed
        class="text-capitalize"
        style="letter-spacing: 0.001px;"
        :color="isEnableConfirmButton ? 'primary' : '#9e9e9e'"
        :dark="!isEnableConfirmButton"
        @click="confirm"
        :loading="getLoadingState.saving"
      >
        {{ confirmationButtonText }}
        <v-icon class="ml-1">{{ confirmationButtonIcon }}</v-icon>
      </v-btn>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('PriceAndMarginDashboardStore');

export default {
  name: 'HeaderForm',
  props: {
    title: {
      type: String,
      default: '',
    },
    chip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    confirmationButtonText: {
      type: String,
      default: '',
    },
    isEnableConfirmButton: {
      type: Boolean,
      default: true,
    },
    confirmationButtonIcon: {
      type: String,
      default: 'mdi-arrow-right',
    },
    isUsingCancelButton: {
      type: Boolean,
      default: false,
    },
    isUsingBackButton: {
      type: Boolean,
      default: true,
    },
    isUsingDescription: {
      type: Boolean,
      default: true,
    },
    cancelButtonColor: {
      type: String,
      default: 'primary',
    },
    cancelButtonText: {
      type: String,
      default: 'Batal',
    },
  },
  computed: {
    ...mapGetters([
      'getSelectedSetting',
      'getSelectedForm',
      'getLoadingState',
      'getIsEdit',
    ]),
    stepper: {
      get() {
        return this.getSelectedForm === 4 ? 1 : 2;
      },
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  background-color: #f0f8fe;
  color: #1976d2;
  padding: 0.5rem 0.7rem;
  font-size: 0.85vw;
}

.grey-light-text {
  color: #9e9e9e;
  font-size: 0.9vw;
}

h2 {
  font-size: 1.3vw;
}
</style>
