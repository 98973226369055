<template>
  <v-card class="pa-3 main-card">
    <div v-if="isHasUnprintedLetter" class="alert-chip">
      <v-icon small color="#d32f2f" class="mr-1">mdi-alert</v-icon>
      <span>Surat ini belum diprint</span>
    </div>
    <v-form ref="form">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Tanggal Lahir</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.birthDate }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Usia</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.age }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Kelamin</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.gender }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.address }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Dokter</label>
            </v-col>
            <v-col cols="7">
              <v-autocomplete
                append-icon="mdi-chevron-down"
                dense
                class="pt-0 mt-0"
                placeholder="Nama Dokter"
                :rules="rules"
                v-model="input.doctor"
                :loading="doctorLoading"
                :items="resource.doctor"
                item-text="doctorName"
                item-value="doctorId"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Diagnosa</label>
            </v-col>
            <v-col cols="7">
              <v-combobox
                append-icon="mdi-chevron-down"
                dense
                class="pt-0 mt-0"
                :rules="rules"
                placeholder="Diagnosa"
                item-text="displayText"
                :items="resource.diagnose"
                :loading="diagnoseLoading"
                clearable
                v-model="input.diagnose"
                :search-input.sync="searchDiagnose"
                return-object
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-combobox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7">
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold required">Tujuan Rujukan</label>
            </v-col>
            <v-col cols="7" class="px-0">
              <v-radio-group
                row
                class="mt-0 pt-0"
                v-model="input.reference_type"
                :rules="rules"
              >
                <v-radio
                  label="Spesialis"
                  @click="radioSelected = 'specialist'"
                  value="specialist"
                ></v-radio>
                <v-radio
                  label="Khusus"
                  value="khusus"
                  @click="radioSelected = 'particular'"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold required"
                >Tujuan Rujukan
                {{
                  input.reference_type == 'specialist' ? 'Spesialis' : 'Khusus'
                }}</label
              >
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-if="input.reference_type === 'specialist'"
                :rules="rules"
                placeholder="Tujuan Rujukan"
                dense
                v-model="input.referral_specialist"
                :loading="specialistLoading"
                :items="resource.referral_specialist"
                item-value="name"
                item-text="name"
                append-icon="mdi-chevron-down"
                return-object
              ></v-autocomplete>
              <v-autocomplete
                v-else
                :rules="rules"
                placeholder="Tujuan Rujukan"
                dense
                v-model="input.referral_specific"
                :loading="specialistLoading"
                :items="resource.referral_specific"
                item-value="name"
                item-text="name"
                append-icon="mdi-chevron-down"
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- <v-row
            v-show="
              input.referral_specialist.code == '' &&
                radioSelected == 'specialist'
            "
            align="center"
          >
            <v-col cols="5" class="text-left"> </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Tujuan Rujukan"
                dense
                hide-details="false"
                v-model="input.referral_specialist_other"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row align="center">
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold required"
                >Nama Instansi Rujukan</label
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Nama Instansi"
                :rules="rules"
                dense
                v-model="input.instansi_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold"
                >Terapi yang telah diberikan</label
              >
            </v-col>
            <v-col cols="7">
              <v-textarea
                outlined
                placeholder="Masukkan Terapi"
                hide-details="false"
                dense
                rows="5"
                :maxLength="3000"
                v-model="input.therapy"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="text-left">
              <label class="font-weight-bold">Saran/Anjuran</label>
            </v-col>
            <v-col cols="7">
              <v-textarea
                outlined
                placeholder="Masukkan Saran"
                hide-details="false"
                dense
                rows="5"
                :maxLength="3000"
                v-model="input.suggestions"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-card-actions class="pa-0">
        <v-btn class="text-none" plain @click="$emit('close-form')">
          Kembali
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          tile
          outlined
          color="primary"
          class="text-none"
          width="116"
          @click="printNonBPJSReferralLetter()"
          :disabled="!isPrintable"
        >
          <!-- @click="printReferralLetter()" -->
          <font-awesome-icon icon="print" />
          Print
        </v-btn>
        <v-btn
          color="primary"
          tile
          elevation="0"
          width="108"
          class="text-none"
          @click="addReferralLetter()"
          >Simpan</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import printMixin from '@/mixin/printMixin';
import Letter from '@/mixin/Letter';
import 'jspdf-autotable';
import jwtMixin from '@/mixin/jwtMixin';
import { scrollToEmptyInput } from '@/helper';

import { postUpdateLetterStatus } from '@/fetchApi/Letter';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'Hospital',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter],
  props: {
    isHasUnprintedLetter: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      doctorSip: '',
      isPrintable: false,
      menu: false,
      radioSelected: 'specialist',
      diagnoseLoading: false,
      specialistLoading: false,
      doctorLoading: false,
      referralData: '',
      resource: {
        diagnose: [],
        doctor: [],
        referral_specialist: [],
        referral_specific: [],
      },
      input: {
        reference_type: 'specialist',
        doctor: '',
        diagnose: '',
        referral_specialist: '',
        referral_specialist_other: '',
        referral_sub_purpose: '',
        referral_plan_date: null,
        schedule_check: '',
        sarana: '',
        instansi_name: '',
        therapy: '',
        suggestions: '',
      },
      numberLetter: '',
      disabled: false,
      searchDiagnose: '',
      rules: [v => !!v || 'Wajib diisi'],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(async () => {
        await this.getDoctor();
        this.getDiagnose('');
        this.getDataAssessment();
        this.getSpecialist();
        this.getSpecific();
      });
    });
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    isPhysiotherapy() {
      return (
        this.patientData.meta.polyName.toLowerCase() === 'poli fisioterapi'
      );
    },
  },
  watch: {
    searchDiagnose(value) {
      if (!value) return;
      this.searchData(this);
    },
  },
  methods: {
    async getDataAssessment() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        );
        const { data } = response.data;
        if (data.assesment?.main_diagnose) {
          this.input = {
            ...this.input,
            diagnose: {
              code: data.assesment?.main_diagnose?.diagnose.code,
              id: data.assesment?.main_diagnose?.diagnose._id,
              text: data.assesment?.main_diagnose?.diagnose.description,
              displayText: `${data.assesment?.main_diagnose?.diagnose.code} - ${data.assesment?.main_diagnose?.diagnose.description}`,
            },
          };
        }
        if (data.timestamps?.doctor_by) {
          this.input = {
            ...this.input,
            therapy: data.planning.therapy.filter(i => i).join(', '),
          };
        }
        this.input.doctor = {
          doctorId: this.patientData.meta.doctorId,
          doctorName: this.patientData.meta.doctor,
        };
        this.getReferralLetter(this.patientData.id_registration);
      } catch {
        this.input = {
          ...this.input,
        };
      }
    },
    searchData: _.debounce(v => v.getDiagnose(v.searchDiagnose), 650),
    // getSpecialistByReferenceType() {
    //   if (this.input.reference_type == 'specialist') {
    //     this.getSpecialist();
    //   } else {
    //     this.getSpecific();
    //   }
    // },
    getSpecialist() {
      this.specialistLoading = true;
      axios
        .get(Constant.apiUrl.concat('/master/specialist'))
        .then(response => {
          let data = response.data.data;
          this.resource.referral_specialist = data;
          this.resource.referral_specialist.push({
            code: '',
            name: 'Lain-lain',
          });
        })
        .catch(() => {
          this.resource.referral_specialist = [];
        })
        .finally(() => {
          this.specialistLoading = false;
        });
    },
    getSpecific() {
      this.specialistLoading = true;
      axios
        .get(Constant.apiUrl.concat('/master/specific'))
        .then(response => {
          let data = response.data.data;
          this.resource.referral_specific = data;
        })
        .catch(() => {
          this.resource.referral_specific = [];
        })
        .finally(() => {
          this.specialistLoading = false;
        });
    },
    getDiagnose(keyword) {
      this.diagnoseLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose?page=1&itemCount=20&search=${
              keyword === null ? '' : keyword
            }`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.resource.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.display_name,
              displayText: diagnose.display_name,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    async addReferralLetter() {
      if (await this.$refs.form.validate()) {
        const payload = {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            reference_type: this.input.reference_type,
            diagnose: this.input.diagnose.id,
            doctor:
              typeof this.input.doctor === 'object'
                ? this.input.doctor.doctorId
                : this.input.doctor,
            instansi_name: this.input.instansi_name,
            therapy: this.input.therapy,
            referral_specialist: this.input.referral_specialist?.name,
            referral_specific: this.input.referral_specific?.name,
            suggestions: this.input.suggestions,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };

        axios
          .post(
            Constant.apiUrl.concat('/letter/referral-patient-letter'),
            payload,
          )
          .then(response => {
            Swal.fire(
              'Surat Rujukan ke Rumah Sakit Berhasil Dibuat',
              '',
              'success',
            );
            this.getReferralLetter(response.data.data.id_registration);
          })
          .catch(err => {
            this.showErrorAxios(err);
          })
          .finally(() => {
            this.$refs.form.resetValidation();
            this.$emit('get-letter-status');
          });
      } else {
        scrollToEmptyInput();
      }
    },
    getDoctor() {
      this.doctorLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan${
              this.isPhysiotherapy
                ? '&role[]=fisioterapis&role[]=Fisioterapis'
                : ''
            }`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        })
        .finally(() => {
          this.doctorLoading = false;
        });
    },
    async printNonBPJSReferralLetter() {
      const doc = this.generateReferralLetterNonBpjs(this.referralData);
      this.printDocument(doc, {
        docName: 'Surat Rujukan Umum Asuransi',
        rmNumber: this.patientData.rmNumber,
      });
      const payload = {
        letter: 'ReferralLetter',
        id_registration: this.patientData.id_registration,
      };
      await postUpdateLetterStatus(payload);
      this.$emit('get-letter-status');
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/referral-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    getReferralLetter(id_registration) {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/referral-patient-letter/registration/${id_registration}`,
          ),
        )
        .then(response => {
          const referral = response.data.data;
          this.referralData = { ...referral };

          this.numberLetter = referral.letter_number;
          this.input = {
            reference_type: referral.detail.reference_type,
            doctor: {
              doctorId: referral.detail.doctor.id,
              doctorName: referral.detail.doctor.name,
            },
            diagnose: {
              ...referral.detail.diagnose,
              displayText: `${referral.detail.diagnose.code} - ${referral.detail.diagnose.text}`,
            },
            referral_specialist: referral.detail?.referral_specialist
              ? {
                  code: referral.detail?.referral_specialist?.code,
                  name: referral.detail?.referral_specialist?.name,
                }
              : null,
            referral_specific: referral.detail?.referral_specific
              ? {
                  code: referral.detail?.referral_specific.code,
                  name: referral.detail?.referral_specific.name,
                }
              : null,
            therapy: referral.detail.therapy,
            suggestions: referral.detail.suggestions,
            instansi_name: referral.detail.instansi_name,
          };
          this.isPrintable = true;
        })
        .catch(() => {
          this.isPrintable = false;
          this.generateNumber();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-chip {
  background-color: #ffebee;
  border: 1px solid #ededed;
  color: #616161;
  font-size: 85%;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.7vh 0.8vw;
}

.main-card {
  font-family: 'Roboto', sans-serif;
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}

.required::after {
  content: '*';
  color: #eb4747;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
