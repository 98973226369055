import EVENTS from '@/socket';

export default {
  methods: {
    emitMessageCall() {
      this.$socket.client.emit(EVENTS.PHARMACY_QUEUE.EMIT_QUEUE_PHARMACY);
    },
    listenMessageCall(refresh) {
      this.$socket.client.on(
        EVENTS.PHARMACY_QUEUE.LISTEN_QUEUE_PHARMACY,
        refresh,
      );
    },
    emitMessageCallPatient(data) {
      this.$socket.client.emit(
        EVENTS.PHARMACY_QUEUE.EMIT_CALL_QUEUE_PHARMACY,
        data,
      );
    },
    listenMessageCallPatient() {
      this.$socket.client.on(
        EVENTS.PHARMACY_QUEUE.LISTEN_CALL_QUEUE_PHARMACY,
        data => {
          this.callPatient(data);
        },
      );
    },
    emitMessageLoadingCallPatient(data) {
      this.$socket.client.emit(
        EVENTS.PHARMACY_QUEUE.EMIT_LOADING_CALL_QUEUE_PHARMACY,
        data,
      );
    },
    listenMessageLoadingCallPatient() {
      this.$socket.client.on(
        EVENTS.PHARMACY_QUEUE.LISTEN_LOADING_CALL_QUEUE_PHARMACY,
        data => {
          this.selectQueue = data.queue;
          this.speakLoading = data.loading;
        },
      );
    },
    emitMessageCheck(data) {
      this.$socket.client.emit(
        EVENTS.PHARMACY_QUEUE.EMIT_CHECK_DETAIL_PHARMACY,
        data,
      );
    },
    listenMessageCheck(func) {
      this.$socket.client.on(
        EVENTS.PHARMACY_QUEUE.LISTEN_CHECK_DETAIL_PHARMACY,
        func,
      );
      return func;
    },
    async callPatient(data) {
      const methods = this;
      const statusCall = status => {
        const trimmedStatus = status.trim().toUpperCase();
        const statusMapping = {
          'TN.': 'Tuan',
          'NN.': 'Nona',
          'NY.': 'Nyonya',
          'BY.': 'Bayi',
          'AN.': 'Anak',
          'SDR.': 'Saudara',
        };
        return statusMapping[trimmedStatus] || '';
      };
      const word1 = 'Nomor antrean';
      const word2 = data.queue
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('');
      const word3 = data.name
        .split(',')
        .reverse()
        .join(',');
      const word4 = 'menuju';
      const word5 = data.counter;
      let speak = [word1];
      let speak3 = [
        statusCall(word3.split(',')[0]),
        word3.split(',')[1],
        word4,
        word5,
      ];
      const speech = new SpeechSynthesisUtterance(speak);
      const speech3 = new SpeechSynthesisUtterance(speak3);
      speech.rate = 1;
      speech.lang = 'id-ID';
      speech3.rate = 1;
      speech3.lang = 'id-ID';
      window.speechSynthesis.speak(speech);
      for (let index = 0; index < word2.length; index++) {
        const speech2 = new SpeechSynthesisUtterance(word2[index]);
        speech2.rate = 1;
        speech2.lang = 'id-ID';
        window.speechSynthesis.speak(speech2);
      }
      window.speechSynthesis.speak(speech3);
      speech.onstart = function() {
        methods.emitMessageLoadingCallPatient({
          queue: data.queue,
          loading: true,
        });
      };
      speech3.onend = function() {
        methods.emitMessageLoadingCallPatient({
          queue: data.queue,
          loading: false,
        });
      };
    },
  },
};
