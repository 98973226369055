const moment = require('moment-timezone');

export default {
  methods: {
    mapGeneralConsentPayload(id_emr, id_inpatient, input) {
      return {
        id_emr: id_emr,
        id_inpatient: id_inpatient,
        detail: {
          signer: input.signer,
          signer_name: input.name,
          address: input.address,
          staff: input.staff.id,
          no_telp: input.telp,
          no_identitas: input.identityNumber,
          privacy: input.privacy,
          patient_consent_service: input.patientAgreement.patientConsentService,
          patient_consent_payment: input.patientAgreement.patientConsentPayment,
          patient_rights_obligation:
            input.patientAgreement.patientRightsObligation,
          patient_consent_satusehat: input.patientAgreement.satuSehatAgreement,
          patient_term_condition: input.patientAgreement.patientTermCondition,
          patient_translator_bahasa:
            input.patientAgreement.patientTranslatorBahasa,
          patient_rohaniawan_needs:
            input.patientAgreement.patientRohaniawanNeeds,
          patient_consent_release: input.patientAgreement.patientConsentRelease,
          patient_consent_inspection_guarantor:
            input.patientAgreement.patientConsentInspectionGuarantor,
          patient_consent_inspection_learner:
            input.patientAgreement.patientConsentInspectionLearner,
          patient_consent_information_fasyankes:
            input.patientAgreement.patientConsentInformationFasyankes,
          visitor_restricted: input.person.filter(x => x !== ''),
          restricted_information: input.restrictedInfo,
          visitor_allowed: input.visitorAllowed.filter(x => x.name !== ''),
          patient_status: 'rawat jalan',
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        },
      };
    },
    mapToFormGeneralConsent(source) {
      return {
        timestamps: source.detail.timestamps,
        patientAgreement: {
          patientConsentService: source.detail.patient_consent_service,
          patientConsentPayment: source.detail.patient_consent_payment,
          patientRightsObligation: source.detail.patient_rights_obligation,
          satuSehatAgreement: source.detail.patient_consent_satusehat,
          patientTermCondition: source.detail.patient_term_condition,
          patientTranslatorBahasa: source.detail.patient_translator_bahasa,
          patientRohaniawanNeeds: source.detail.patient_rohaniawan_needs,
          patientConsentRelease: source.detail.patient_consent_release,
          patientConsentInspectionGuarantor:
            source.detail.patient_consent_inspection_guarantor,
          patientConsentInspectionLearner:
            source.detail.patient_consent_inspection_learner,
          patientConsentInformationFasyankes:
            source.detail.patient_consent_information_fasyankes,
        },
        signer: source.detail.signer,
        staff: {
          text: source.detail.staff.name,
          id: source.detail.staff.id,
          role: source.detail.staff.role,
        },
        status: {
          text: source.detail.generalConsentSigned
            ? 'Sudah ditandatangani'
            : 'Sudah diproses',
          class: source.detail.generalConsentSigned ? 'signed' : 'created',
        },
        name: source.detail.signer_name,
        patientName: source.detail.signer_name,
        address: source.detail.address,
        telp: source.detail.no_telp,
        age: source.detail.age,
        rmNumber: source.detail.rmNumber,
        gender: source.detail.gender,
        birthDate: moment(source.detail.birth.date, 'YYYY-MM-DD').format(
          'DD/MM/YYYY',
        ),
        identityNumber: source.detail.no_identitas,
        person: source.detail.privacy ? source.detail.visitor_restricted : [''],
        privacy: source.detail.privacy,
        restrictedInfo: source.detail.restricted_information,
        visitorAllowed: source.detail.restricted_information
          ? source.detail.visitor_allowed.map(arr => {
              return {
                name: arr.name,
                relation: arr.relation,
              };
            })
          : [
              {
                name: '',
                relation: '',
              },
            ],
      };
    },
  },
};
