<template>
  <v-card class="pa-5 text-start">
    <h2>Setting Margin dan Harga</h2>
    <p class="pa-0">
      Pilih salah satu level setting yang akan menjadi aturan margin dan harga
      farmasi pada sistem
    </p>
    <v-radio-group v-model="selectedSetting">
      <v-radio
        v-for="(item, index) in options"
        :key="'option-' + index"
        :class="item.class + 'radio-container d-flex align-start'"
        :value="item.value"
      >
        <template v-slot:label>
          <div>
            <h3 class="mb-3">{{ item.option }}</h3>
            <p class="pa-0">{{ item.description }}</p>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <footer class="d-flex justify-end">
      <v-btn
        @click="closeDialog"
        color="primary"
        outlined
        style="letter-spacing: 0.001px;"
        class="text-capitalize mr-3"
      >
        Batal
      </v-btn>
      <v-btn
        depressed
        :class="`text-capitalize ${!selectedSetting ? 'disabled' : ''}`"
        style="letter-spacing: 0.001px;"
        :color="!selectedSetting ? '#9e9e9e' : 'primary'"
        :dark="!selectedSetting"
        @click="start"
      >
        Mulai
        <v-icon class="ml-1">mdi-arrow-right</v-icon>
      </v-btn>
    </footer>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);
export default {
  name: 'SettingOptionDialog',
  computed: {
    ...mapGetters(['getSelectedSetting']),
    selectedSetting: {
      get() {
        return this.getSelectedSetting;
      },
      set(value) {
        this.setSelectedSetting(value);
      },
    },
    options: {
      get() {
        return [
          {
            option: 'Global Setting',
            description: 'Atur harga dan margin untuk semua item.',
            value: 'global-setting',
            class: this.selectedSetting === 'global-setting' ? 'active ' : '',
          },
          {
            option: 'Kategori Setting',
            description: 'Atur harga dan margin sesuai kategori barang.',
            value: 'category-setting',
            class: this.selectedSetting === 'category-setting' ? 'active ' : '',
          },
          {
            option: 'Global + Kategori Setting',
            description:
              'Atur harga dan margin secara global untuk semua item, atau sesuaikan langsung berdasarkan kategori barang.',
            value: 'global-category-setting',
            class:
              this.selectedSetting === 'global-category-setting'
                ? 'active '
                : '',
          },
          {
            option: 'Global + Spesifik Setting',
            description:
              'Atur harga dan margin secara global untuk semua item, atau sesuaikan langsung untuk item tertentu.',
            value: 'global-specific-setting',
            class:
              this.selectedSetting === 'global-specific-setting'
                ? 'active '
                : '',
          },
        ];
      },
    },
  },
  methods: {
    ...mapMutations([
      'setDialog',
      'setSelectedSetting',
      'clearSelectedSetting',
    ]),
    closeDialog() {
      this.setDialog(false);
      this.setSelectedSetting('');
      this.clearSelectedSetting();
    },
    start() {
      if (!this.selectedSetting) return;
      this.$emit('start-setting');
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: #9e9e9e;
}

h3 {
  font-weight: 500;
  color: #404040;
}

.radio-container {
  border: 2px solid #ededed;
  border-radius: 8px;
  padding: 1rem 1rem 0 1rem;

  &.active {
    border: 2px solid #1976d2;
    background-color: #f0f8fe;
  }
}
</style>
