<template>
  <v-card class="pa-5">
    <!-- header section -->
    <header-form
      title="Setting Margin dan Harga"
      chip="Global Setting"
      description="Atur harga dan margin untuk semua item."
      confirmationButtonText="Lanjut"
      :isUsingCancelButton="getIsEdit"
      :isUsingBackButton="!getIsEdit"
      :isEnableConfirmButton="isEnableNext"
      @back="back"
      @cancel="closeDialog"
      @confirm="next"
    />
    <!-- end of header section  -->
    <section>
      <div class="d-flex justify-space-between">
        <span class="grey-dark-text">HARGA DEFAULT</span>
        <span class="grey-light-text">
          Base Harga: <strong class="black--text">HNA</strong>
        </span>
      </div>
      <input-price-card ref="card1" is-default :index="0" />
      <div class="text-start mt-5">
        <span class="grey-dark-text">MULTIPLE HARGA (OPSIONAL)</span>
      </div>
      <input-price-card ref="card2" :index="1" />
      <input-price-card ref="card3" :index="2" />
    </section>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import Swal from 'sweetalert2';

const { mapMutations, mapGetters } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

import HeaderForm from '../HeaderForm.vue';
import InputPriceCard from './InputPriceCard.vue';

export default {
  name: 'SettingGlobalMarginAndPriceDialog',
  components: { InputPriceCard, HeaderForm },
  data() {
    return {
      // Your component data here
    };
  },
  mounted() {
    if (this.getIsEdit) {
      this.mapExistingData();
    }
  },
  methods: {
    ...mapMutations([
      'setSelectedForm',
      'setDialog',
      'setPriceSettingDirectly',
    ]),
    closeDialog() {
      this.setSelectedForm('');
      this.setDialog(false);
    },
    mapExistingData() {
      this.setPriceSettingDirectly(
        JSON.parse(JSON.stringify(this.getDataFromServer.nondrugs[0].margins)),
      );
    },
    back() {
      if (this.hasFormChanged) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Konfirmasi Tindakan</p></div>`,
          iconHtml:
            '<span sstyle="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda belum menyelesaikan pengisian form. Perubahan yang</p><p>belum disimpan akan hilang. Apakah Anda yakin ingin</p><p>membatalkan perubahan?</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Tetap Mengisi Form',
          cancelButtonText: 'Batalkan Perubahan',
        }).then(result => {
          if (result.isConfirmed) {
            return;
          }
          this.setSelectedForm(0);
        });
      } else {
        this.setSelectedForm(0);
      }
    },
    next() {
      if (!this.isEnableNext) return;
      if (
        !this.$refs.card1.$refs.form.validate() ||
        !this.$refs.card2.$refs.form.validate() ||
        !this.$refs.card3.$refs.form.validate()
      ) {
        return;
      } else {
        this.setSelectedForm(2);
      }
    },
  },
  computed: {
    ...mapGetters([
      'getPriceSetting',
      'getIsEnableConfirmationBtn',
      'getDataFromServer',
      'getIsEdit',
    ]),
    hasFormChanged: {
      get() {
        return (
          this.getPriceSetting[0].margin !== '' ||
          this.getPriceSetting[0].ppn !== '' ||
          this.getPriceSetting[0].description !== '' ||
          this.getPriceSetting[1].isActive ||
          this.getPriceSetting[2].isActive
        );
      },
    },
    isEnableNext: {
      get() {
        return this.getIsEnableConfirmationBtn;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.grey-light-text {
  color: #9e9e9e;
}

.grey-dark-text {
  color: #616161;
}
</style>
