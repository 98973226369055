import {
  putDrugs,
  postDrugs,
  getAllDrugs,
  deleteDrugs,
  getDrugsById,
  getCheckDrugs,
  putStatusDrugs,
} from '@/fetchApi/MasterData/Drugs';
import {
  getRoa,
  getGroup,
  getKfaData,
  getFactory,
  getPreparation,
  getDrugsKfaData,
  getCategoryByMenu,
  getPackagesByMenu,
  getPharmacotherapy,
  addFactory,
  getDrugsMargin,
} from '@/fetchApi/Resource/Drugs';

const state = {
  dashboard: {
    dialog: false,
    snackbar: {
      text: '',
      color: '',
      show: false,
    },
  },
  kfaSwitch: {
    drug: false,
    product: false,
  },
  form: {
    id: null,
    name: '',
    barcode: '',
    hna: 0,
    hpp: 0,
    min_stock: 0,
    category: '',
    drug_kfa: '',
    product_kfa: '',
    inputManual: {
      drug_kfa: {
        code: '',
        name: '',
      },
      product_kfa: {
        code: '',
        name: '',
      },
    },
    shelf: '',
    factory: '',
    strength: '',
    strength_unit: '',
    preparation: '',
    roa: '',
    indication: '',
    c_indication: '',
    ingredients: '',
    side_effects: '',
    isFormulary: null,
    isFornas: null,
    isHighAlert: null,
    isGeneric: null,
    isCatastrophic: null,
    isActive: true,
    isVEN: null,
    group: '',
    pharmacotherapy: '',
    packaging: [],
    sellingPrice: 0,
  },
  items: {
    dataDrugs: {
      isLoading: false,
      data: [],
    },
    factory: {
      isLoading: false,
      data: [],
    },
    categoryByMenu: {
      isLoading: false,
      data: [],
    },
    packagesByMenu: {
      isLoading: false,
      data: [],
    },
    preparation: {
      isLoading: false,
      data: [],
    },
    roa: {
      isLoading: false,
      data: [],
    },
    group: {
      isLoading: false,
      data: [],
    },
    pharmacotherapy: {
      isLoading: false,
      data: [],
    },
    kfaDrugs: {
      isLoading: false,
      data: [],
    },
    kfaProducts: {
      isLoading: false,
      data: [],
    },
  },
  marginData: {
    margin: null,
    ppn: null,
  },
};

const getters = {
  // Get State Dialog
  getDialog: state => state.dashboard.dialog,

  // Get State Items
  getItems: state => state.items,

  // Get State Form
  getForm: state => state.form,

  // Get Snackbar
  getSnackbar: state => state.dashboard.snackbar,

  // Get Kfa Switch
  getKfaSwitch: state => state.kfaSwitch,

  // Get selling price
  getSellingPrice: state => {
    const basePrice = state.form.hna;
    const marginAmount = (basePrice * state.marginData.margin) / 100;
    const priceAfterMargin = basePrice + marginAmount;
    const taxAmount = (priceAfterMargin * state.marginData.ppn) / 100;
    const finalPrice = priceAfterMargin + taxAmount;
    return finalPrice;
  },
};

const mutations = {
  // Set State Dialog
  setDialog(state, payload) {
    state.dashboard.dialog = payload;
  },

  // Set State Items
  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  // Set State Form
  setForm(state, payload) {
    state.form = payload;
  },

  // Set KFA Switch
  setKfaSwitch(state, { type, value }) {
    state.kfaSwitch[type] = value;
  },

  // Set State Margin
  setMargin(state, { data }) {
    state.marginData = data;
  },

  // Clear Form
  clearForm(state) {
    state.kfaSwitch = {
      drug: false,
      product: false,
    };
    state.form = {
      id: null,
      name: '',
      barcode: '',
      hna: 0,
      hpp: 0,
      min_stock: 0,
      category: '',
      shelf: '',
      factory: '',
      drug_kfa: '',
      product_kfa: '',
      inputManual: {
        drug_kfa: {
          code: '',
          name: '',
        },
        product_kfa: {
          code: '',
          name: '',
        },
      },
      strength: '',
      strength_unit: '',
      preparation: '',
      roa: '',
      indication: '',
      c_indication: '',
      ingredients: '',
      side_effects: '',
      isFormulary: null,
      isFornas: null,
      isHighAlert: null,
      isGeneric: null,
      isCatastrophic: null,
      isActive: true,
      isVEN: null,
      group: '',
      pharmacotherapy: '',
      packaging: [],
      sellingPrice: 0,
    };
    state.marginData = {
      margin: 0,
      ppn: 0,
    };
  },

  // Set snackbar
  setSnackbar(state, payload) {
    state.dashboard.snackbar = payload;
  },

  // Clear Snackbar
  clearSnackbar(state) {
    state.dashboard.snackbar = {
      show: false,
      color: 'success',
      text: '',
    };
  },
};

const actions = {
  // Get Data
  // Gett All Data Drugs
  async resolveGetAllDrugs(
    { commit, state },
    { halaman, itemCount, sortBy, keyword },
  ) {
    commit('setItems', {
      label: 'dataDrugs',
      value: [...state.items.dataDrugs.data],
      isLoading: true,
    });
    try {
      const drugsResponse = await getAllDrugs({
        halaman,
        itemCount,
        sortBy,
        keyword,
      });

      const drugsMapper = drugsResponse?.data?.data?.map(item => ({
        id: item._id.toString(),
        name: item.detail.name,
        barcode: item.detail.barcode,
        hpp: item.detail.price,
        hna: item.detail.hna,
        category: item.detail.category,
        strength: item.detail.strength,
        strength_unit: item.detail.strength_unit,
        preparation: item.detail.preparation,
        indication: item.detail.indication,
        c_indication: item.detail.c_indication,
        side_effects: item.detail.side_effects,
        shelf: item.detail.shelf,
        ingredients: item.detail.ingredients,
        roa: item.detail.roa,
        isFormulary: item.detail.isFormulary,
        isFornas: item.detail.isFornas,
        isHighAlert: item.detail.isHighAlert,
        isPotent: item.detail.isPotent,
        isGeneric: item.detail.isGeneric,
        isCatastrophic: item.detail.isCatastrophic,
        isActive: item.detail.isActive,
        isVEN: item.detail.isVEN,
        factory: item.detail.factory,
        group: item.detail.group,
        min_stock: item.detail.min_stock,
        price: item.detail.price,
        pharmacotherapy: item.detail.pharmacotherapy,
        packaging: item.detail.packaging.map(p => {
          return {
            isDefault: p.isDefault,
            package: p.package,
            package_unit: p.package_unit,
            quantity: p.quantity,
          };
        }),
      }));
      commit('setItems', {
        label: 'dataDrugs',
        value: drugsMapper,
        isLoading: false,
      });
      return drugsResponse?.data?.length;
    } catch {
      commit('setItems', {
        label: 'dataDrugs',
        value: [],
        isLoading: false,
      });
    }
  },

  // Get Data Drugs By Id
  async resolveGetDrugsById({ state, commit }) {
    const response = await getDrugsById(state.form.id);
    const { detail } = response.data.data;
    try {
      // set switch type for KFA stuffs
      commit('setKfaSwitch', {
        type: 'drug',
        value: detail?.drug_kfa?.isManual || false,
      });
      commit('setKfaSwitch', {
        type: 'product',
        value: detail?.product_kfa?.isManual || false,
      });
      commit('setForm', {
        ...state.form,
        ...detail,
        // if previous user input KFA data using manual type, then map the data below
        inputManual: {
          drug_kfa: detail?.drug_kfa?.isManual
            ? {
                name: detail?.drug_kfa?.name || '',
                code: detail?.drug_kfa?.code || '',
              }
            : { name: '', code: '' },
          product_kfa: detail?.product_kfa?.isManual
            ? {
                name: detail?.product_kfa?.name || '',
                code: detail?.product_kfa?.code || '',
              }
            : { name: '', code: '' },
        },
        // end of mapping manual input KFA
        drug_kfa: detail?.drug_kfa?.name
          ? {
              name: detail?.drug_kfa?.name || '',
              code: detail?.drug_kfa?.code || '',
              // set display name for KFA's data because server doesn't provide the data
              displayName: detail.drug_kfa?.name
                ? `${detail.drug_kfa?.code} - ${detail.drug_kfa?.name}`
                : '',
            }
          : '',
        product_kfa: detail?.product_kfa?.name
          ? {
              name: detail?.product_kfa?.name || '',
              code: detail?.product_kfa?.code || '',
              displayName: detail.product_kfa?.name
                ? `${detail.product_kfa?.code} - ${detail.product_kfa?.name}`
                : '',
            }
          : '',
      });
      const { margin, ppn } = response.data.data.detail;
      state.marginData = { margin, ppn };
      return response.data.data.detail;
    } catch (error) {
      return error;
    }
  },

  // Get Check Drugs
  async resolveGetCheckDrugs(_, idDrugs) {
    const resoponse = await getCheckDrugs(idDrugs);
    try {
      return resoponse;
    } catch (error) {
      return error;
    }
  },

  // Get Data Resource
  async fetchDataResource(
    { commit },
    { label, apiFunction, params, mapperFunction = item => item.name },
  ) {
    commit('setItems', {
      label,
      value: [],
      isLoading: true,
    });
    try {
      const response = await apiFunction(params);
      const mapper = response?.data?.data?.map(mapperFunction);
      label === 'pharmacotherapy' ?? mapper?.sort((a, b) => a - b);
      commit('setItems', {
        label,
        value: mapper,
        isLoading: false,
      });
    } catch (error) {
      commit('setItems', {
        label,
        value: [],
        isLoading: false,
      });
      return error;
    }
  },
  // Get Data Factory
  async resolveGetFactory({ dispatch }, search = '') {
    await dispatch('fetchDataResource', {
      label: 'factory',
      apiFunction: getFactory,
      params: { search },
    });
  },
  // Get Data CategoryByMenu
  async resolveGetCategoryByMenu({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'categoryByMenu',
      apiFunction: getCategoryByMenu,
    });
  },
  // Get Data PackagesByMenu
  async resolveGetPackagesByMenu({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'packagesByMenu',
      apiFunction: getPackagesByMenu,
    });
  },
  // Get Data Preparation
  async resolveGetPreparation({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'preparation',
      apiFunction: getPreparation,
    });
  },
  // Get Data Roa
  async resolveGetRoa({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'roa',
      apiFunction: getRoa,
    });
  },
  // Get Data Group
  async resolveGetGroup({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'group',
      apiFunction: getGroup,
    });
  },
  // Get Data Pharmacotherapy
  async resolveGetPharmacotherapy({ dispatch }) {
    await dispatch('fetchDataResource', {
      label: 'pharmacotherapy',
      apiFunction: getPharmacotherapy,
    });
  },
  // Get Data Product KFA
  async resolveGetKfaData({ dispatch }, search = '') {
    await dispatch('fetchDataResource', {
      label: 'kfaProducts',
      apiFunction: getKfaData,
      params: {
        search,
        itemCount: 20,
        page: 1,
        productType: 'farmasi',
      },
      mapperFunction: item => ({
        name: item.name,
        code: item.code,
        displayName: `${item.code} - ${item.name}`,
      }),
    });
  },
  // Get Data Drugs KFA
  async resolveGetDrugsKfaData({ dispatch }, search = '') {
    await dispatch('fetchDataResource', {
      label: 'kfaDrugs',
      apiFunction: getDrugsKfaData,
      params: {
        search,
        itemCount: 20,
        page: 1,
      },
      mapperFunction: item => ({
        name: item.name,
        code: item.code,
        displayName: `${item.code} - ${item.name}`,
      }),
    });
  },
  // End Get Data Resource
  // End Get Data

  // Post Data
  async resolvePostDrugs({ state, commit }, packaging) {
    const payload = {
      ...state.form,
      drug_kfa: state.kfaSwitch.drug
        ? {
            code: state.form.inputManual.drug_kfa.code,
            name: state.form.inputManual.drug_kfa.name,
            isManual: state.kfaSwitch.drug,
          }
        : state.form.drug_kfa,
      product_kfa: state.kfaSwitch.product
        ? {
            code: state.form.inputManual.product_kfa.code,
            name: state.form.inputManual.product_kfa.name,
            isManual: state.kfaSwitch.product,
          }
        : state.form.product_kfa,
      packaging,
    };

    Object.keys(payload).forEach(key => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });
    delete payload.id;
    delete payload.inputManual;

    try {
      const postDrugsRes = await postDrugs(payload);
      if (postDrugsRes.status === 200) {
        commit('setDialog', false);
      }
      return postDrugsRes.status;
    } catch (error) {
      return error;
    }
  },
  // End Post Data

  // Put Data
  async resolvePutDrugs({ state, commit }, packaging) {
    const payload = {
      ...state.form,
      packaging,
      drug_kfa: state.kfaSwitch.drug
        ? {
            code: state.form.inputManual.drug_kfa.code,
            name: state.form.inputManual.drug_kfa.name,
            isManual: state.kfaSwitch.drug,
          }
        : state.form.drug_kfa,
      product_kfa: state.kfaSwitch.product
        ? {
            code: state.form.inputManual.product_kfa.code,
            name: state.form.inputManual.product_kfa.name,
            isManual: state.kfaSwitch.product,
          }
        : state.form.product_kfa,
    };

    Object.keys(payload).forEach(key => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });
    delete payload.id;
    delete payload.inputManual;

    try {
      const putDrugsRes = await putDrugs(state.form.id, payload);
      if (putDrugsRes.status === 200) {
        commit('setDialog', false);
      }
      return putDrugsRes.status;
    } catch (error) {
      return error;
    }
  },

  async resolvePutStatusDrugs({ commit }, { id_drug, isActive }) {
    commit('clearSnackbar');
    let snackbarTimeoutId = null;

    try {
      const res = await putStatusDrugs({ id_drug, isActive });

      let message =
        'Status berhasil ' + (isActive ? 'diaktifkan' : 'dinonaktifkan');
      let color = 'success';

      if (res.status !== 200) {
        message = 'Status gagal ' + (isActive ? 'diaktifkan' : 'dinonaktifkan');
        color = 'error';
      }

      commit('setSnackbar', {
        show: true,
        text: message,
        color: color,
      });

      if (snackbarTimeoutId) {
        clearTimeout(snackbarTimeoutId);
      }

      snackbarTimeoutId = setTimeout(() => {
        commit('clearSnackbar');
        snackbarTimeoutId = null;
      }, 4000);
    } catch (error) {
      return error;
    }
  },

  // Delete Data
  async resolveDeleteDrugs({ commit }, id) {
    try {
      const responseDeleteDrugs = await deleteDrugs(id);
      if (responseDeleteDrugs.status === 200) {
        commit('setSnackbar', {
          show: true,
          text: 'Data berhasil dihapus',
          color: 'success',
        });
        setTimeout(() => {
          commit('clearSnackbar');
        }, 4000);
      }
      return responseDeleteDrugs;
    } catch (error) {
      return error;
    }
  },

  //  Add new factory
  async resolveAddFactory(_, payload) {
    try {
      await addFactory(payload);
    } catch (error) {
      throw new Error(error);
    }
  },

  // Generate Margin data
  async resolveMarginData({ state }) {
    try {
      if (
        state.form.category !== '' &&
        state.form.group !== '' &&
        state.form.isGeneric !== null
      ) {
        const responseGetMargin = await getDrugsMargin({
          category: state.form.category,
          group: state.form.group,
          isGeneric: state.form.isGeneric,
        });

        state.marginData = responseGetMargin.data.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
