<template>
  <div>
    <v-layout pa-4 align-center>
      <div
        style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
      >
        <div class="text-left">
          <h2>Penyerahan Obat</h2>
        </div>
        <div style="display: flex; justify-content: end; align-items: center; ">
          <div class="mr-2">
            <Datepicker
              :show="datePicker"
              :date="date"
              @date-change="handleDateChange"
            />
          </div>
          <div>
            <router-link :to="routeBack" active>
              <font-awesome-icon
                class="mr-3"
                style="color: #3f484a"
                icon="home"
              />
            </router-link>
            <v-btn @click="refresh()" icon>
              <font-awesome-icon
                :class="{ refresh: isRefresh }"
                icon="sync-alt"
              />
            </v-btn>
          </div>
          <div>
            <v-text-field
              solo
              dense
              flat
              label="Cari Pasien"
              prepend-inner-icon="mdi-magnify"
              background-color="grey lighten-3"
              v-model="search"
              hide-details="false"
            ></v-text-field>
          </div>
        </div>
      </div>
    </v-layout>

    <div
      v-if="today === date"
      class="d-flex justify-space-between align-center px-6  py-4"
    >
      <v-alert
        v-if="counter === '' || !detailState"
        dense
        outlined
        text
        type="info"
        class="m-0"
        style="margin: 0 !important;"
      >
        <span>
          Pilih loket kemudian buka detail antrean untuk memanggil pasien via
          sistem antrean
        </span></v-alert
      >
      <div
        v-if="counter !== '' && detailState"
        style="display: flex; justify-content: center; align-items: center; gap: 1rem;"
      >
        <div
          v-if="queueStatus.queue.length !== 0"
          style="display: flex; justify-content: center; align-items: center; gap: 1rem;"
        >
          <div v-for="(item, index) in queueStatus.queue" :key="index">
            <v-chip color="#F5F5F5" label>
              {{ item.counter }} :
              <span class="ml-1" style="color: #137BC0;">{{
                item.calling
              }}</span>
            </v-chip>
          </div>
        </div>
        <div v-else>
          <v-chip color="#F5F5F5" label>
            Loket 1 :
            <span class="ml-1" style="color: #137BC0;">-</span>
          </v-chip>
        </div>
        <v-chip color="#F5F5F5" label
          >Dilewati :
          <span class="ml-1" style="color: #137BC0;">{{
            queueStatus.totalMissed
          }}</span></v-chip
        >
      </div>
      <div
        style="width: 320px !important;"
        class="d-flex align-center justify-space-between"
      >
        <v-select
          class="mr-4"
          :items="counterItems"
          outlined
          placeholder="Pilih Loket"
          append-icon="mdi-chevron-down"
          dense
          hide-details
          v-model="counter"
          item-text="name"
          :item-value="val => val"
        />
        <v-btn
          :disabled="counter === ''"
          color="primary"
          depressed
          outlined
          @click="handleToDetail"
          class="text-none"
        >
          Detail Antrian
          <v-icon right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </div>

    <div class="header">
      <v-tabs v-model="tab" grow slider-color="blue">
        <v-tab key="service">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Pelayanan ({{ count.service }})</label
          >
        </v-tab>
        <v-tab key="takeHomeDrug">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Obat dibawa pulang ({{ count.takeHome }})</label
          >
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item key="service">
        <v-data-table
          :headers="headers"
          :items="data.service"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :page.sync="servicePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ item.no }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip label class="chip" :class="statusColor(item.status)">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Dokumen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item key="takeHomeDrug">
        <v-data-table
          :headers="headers"
          :items="data.takeHome"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :page.sync="takeHomePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ item.no }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip label class="chip" :class="statusColor(item.status)">
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <button
              v-if="item.status !== 'Sudah diserahkan'"
              @click="onClick(item, 2, $event)"
              :disabled="disableCall(item) || dialog"
            >
              <span
                :style="
                  `display: flex; gap: 0.5rem; justify-content: center; align-items: center; color: ${
                    disableCall(item) ? '#9CA2A5' : '#137BC0'
                  }`
                "
              >
                <v-icon
                  :style="`color: ${disableCall(item) ? '#9CA2A5' : '#137BC0'}`"
                  >mdi-volume-high</v-icon
                >
                {{
                  disableCall(item)
                    ? counter === '' || !detailState || date !== today
                      ? 'Panggil'
                      : 'Sedang Memanggil'
                    : 'Panggil'
                }}
              </span>
            </button>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Dokumen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-dialog>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="icon"
                        slot="activator"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </template>
                  </v-dialog>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="servicePage"
            :length="serviceLengthPage"
            :total-visible="10"
            v-if="tab === 0"
            @input="getAllPatient()"
          ></v-pagination>
          <v-pagination
            v-model="takeHomePage"
            :length="takeHomeLengthPage"
            :total-visible="10"
            v-if="tab === 1"
            @input="getAllPatient()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-footer>
    <v-dialog
      v-model="dialog"
      :max-width="1200"
      persistent
      :fullscreen="selectedDialog === 2"
    >
      <SocialData
        v-if="selectedDialog === 1 && dialog"
        :medical-record="selectedPatient.rm_number"
        @close-form="dialog = false"
      ></SocialData>
      <DrugHandoverEdit
        v-else-if="selectedDialog === 2 && dialog"
        :patient="selectedPatient"
        @close-form="
          dialog = false;
          getAllPatient();
        "
        @show-snackbar-skip="showSnackbarSkip"
        @show-snackbar="showSnackbar()"
      />
    </v-dialog>
    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          snackbar.show = false;
        }
      "
    />
  </div>
</template>

<script>
import DrugHandoverEdit from './DrugHandover/DrugHandoverEdit';
import jwtMixin from '@/mixin/jwtMixin';
import SocialData from '../../SharedComponent/SocialData.vue';
import alertMixin from '@/mixin/alertMixin';
import PharmacyQueueMixin from '@/mixin/SocketMixin/PharmacyQueueMixin';
import {
  getQueueStatus,
  getPharmacyHandover,
  getPharmacyPatientCount,
  getPharmacyCounter,
  postCallPatient,
} from '@/fetchApi/Pharmacy';
import { Snackbar, Datepicker } from '@/components/SharedComponent';

const _ = require('lodash');
const moment = require('moment');

export default {
  name: 'DrugHandover',
  mixins: [jwtMixin, alertMixin, PharmacyQueueMixin],
  components: {
    DrugHandoverEdit,
    SocialData,
    Snackbar,
    Datepicker,
  },
  data() {
    return {
      detailState: false,
      isDetailOpened: false,
      speakLoading: false,
      selectQueue: '',
      queueStatus: {
        queue: [],
        totalMissed: '-',
      },
      counter: '',
      counterItems: [],
      tab: 1,
      expanded: [],
      isExpanded: false,
      isRefresh: false,
      date: moment().format('YYYY-MM-DD'),
      datePicker: false,
      selectedDate: '',
      headers: [
        { text: 'No', align: 'start', value: 'no', sortable: false },
        {
          text: 'No Antrean',
          align: 'start',
          value: 'queue_number',
          sortable: false,
        },
        { text: 'Nama Pasien', value: 'name', width: '17.5%', sortable: false },
        { text: 'No. RM', value: 'rm_number', sortable: false },
        { text: 'Tipe Penjaminan', value: 'assurance', sortable: false },
        { text: 'Unit', value: 'unit', sortable: false },
        { text: 'Dokter', value: 'doctor', width: '17.5%', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: '', value: 'action', sortable: false },
      ],
      data: {
        service: [],
        takeHome: [],
      },
      count: {
        service: 0,
        takeHome: 0,
      },
      dialog: false,
      isLoading: false,
      servicePage: 1,
      takeHomePage: 1,
      rowsPerPage: 5,
      serviceLengthPage: 0,
      takeHomeLengthPage: 0,
      selectedDialog: null,
      selectedPatient: null,
      search: '',
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
    };
  },
  watch: {
    tab() {
      this.getAllPatient();
    },
    search() {
      this.searchData(this);
    },
    selectedDate() {
      this.date = moment(this.selectedDate).format('DD-MM-YYYY');
      this.servicePage = 1;
      this.takeHomePage = 1;
      this.getAllPatient();
    },
    date() {
      if (!this.date) {
        this.date = '';
        this.servicePage = 1;
        this.takeHomePage = 1;
        this.getAllPatient();
      }
    },
    isDetailOpened: _.debounce(function(val) {
      this.detailState = val;
    }, 1000),
  },
  mounted() {
    this.$nextTick(() => {
      this.getCounter();
      this.refreshAndAutoUpdate();
    });
    this.emitMessageCall();
    this.listenMessageCall(this.getAllPatient);
    this.listenMessageCall(this.handleGetQueueStatus);
    this.listenMessageLoadingCallPatient();
    this.listenMessageCheck(data => {
      if (data === false) {
        this.emitMessageCheck(true);
        this.isDetailOpened = false;
      } else {
        this.isDetailOpened = data;
      }
    });
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD');
    },
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '48vh';
          break;
        case 'lg':
          height = '53vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  methods: {
    handleToDetail() {
      window.open('/antrean-farmasi', '_blank');
    },
    disableCall(item) {
      return (
        this.counter === '' ||
        !this.detailState ||
        item.status === 'Sudah diserahkan' ||
        this.date !== this.today ||
        (this.speakLoading && item.queue_number === this.selectQueue)
      );
    },
    statusColor(text) {
      switch (text?.split(' ')[0]) {
        case 'Belum':
          return 'undelivered';
        case 'Panggilan':
          return 'call';
        case 'Antrean':
          return 'skip';
        case 'Sudah':
          return 'delivered';
      }
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.page = 1;
      v.getAllPatient();
    }, 650),
    refresh() {
      this.isRefresh = true;
      this.getAllPatient();
    },
    showSnackbar() {
      this.snackbar = {
        show: true,
        text: 'Penyerahan obat dan alkes berhasil disimpan',
        color: 'success',
      };
      setTimeout(() => {
        this.snackbar = false;
      }, 3000);
    },
    showSnackbarSkip(payload) {
      this.snackbar = {
        show: true,
        text: `Antrean ${payload} dilewati`,
        color: 'error',
      };
      setTimeout(() => {
        this.snackbar = false;
      }, 3000);
    },
    async getCounter() {
      try {
        const response = await getPharmacyCounter();
        this.counterItems = response.data.data;
      } catch (error) {
        this.counterItems = [];
        return error;
      }
    },
    async getPatientCount() {
      try {
        const params = {
          category: 'handover',
          date: this.date || '',
          type: this.routeName.toLowerCase(),
          search: this.search,
        };
        const response = await getPharmacyPatientCount(params);
        this.count = {
          service: response.pharmacyNotTakeHomeCount,
          takeHome: response.pharmacyTakeHomeCount,
        };
      } catch (error) {
        this.count = {
          service: 0,
          takeHome: 0,
        };
      }
    },
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.servicePage = 1;
      this.getAllPatient();
    },
    async getAllPatient() {
      try {
        this.getPatientCount(); // get amount of patient first
        this.handleGetQueueStatus();
        this.isLoading = true;
        const params = {
          date: this.date || '',
          category: 'handover',
          type: this.routeName.toLowerCase(),
          page: this.tab ? this.takeHomePage : this.servicePage,
          itemCount: this.itemsPerRow,
          search: this.search || '',
          takeHome: this.tab,
        };
        const response = await getPharmacyHandover(params);
        const patient = response.data.data;
        const dataLength = response.data.length;
        if (this.tab) {
          this.takeHomeLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.takeHome = patient.map((data, index) => {
            return {
              no: (this.takeHomePage - 1) * this.itemsPerRow + index + 1,
              queue_number: data.queue,
              id: data._id.toString(),
              name: data.name,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.social.gender ? 'Laki-laki' : 'Perempuan',
              age: data.social.age,
              doctor: data.staff,
              status: data.status,
              id_registration: data?.id_registration,
              id_emr: data?.id_emr,
            };
          });
        } else {
          this.serviceLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.service = patient.map((data, index) => {
            return {
              no: (this.servicePage - 1) * this.itemsPerRow + index + 1,
              queue_number: data.queue,
              id: data._id.toString(),
              name: data.name,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.social.gender ? 'Laki-laki' : 'Perempuan',
              age: data.social.age,
              doctor: data.staff,
              status: data.status,
              id_registration: data?.id_registration,
              id_emr: data?.id_emr,
            };
          });
        }
      } catch {
        this.data.takeHome = [];
        this.takeHomeLengthPage = 1;
      } finally {
        this.isRefresh = false;
        this.isLoading = false;
      }
    },
    async handleGetQueueStatus() {
      try {
        const response = await getQueueStatus();
        const queueStatus = response.data.data;
        this.queueStatus = {
          totalMissed: queueStatus.totalMissed || '-',
          queue: queueStatus.queue || [],
        };
      } catch (error) {
        this.queueStatus = {
          totalMissed: '-',
          queue: [],
        };
        return error;
      }
    },

    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllPatient();
      this.handleGetQueueStatus();
      this.interval = setInterval(this.getAllPatient, 60000);
    },

    async onClick(item, val, e) {
      this.dialog = true;
      e?.stopPropagation();
      let payload = {
        id_counter: this.counter._id,
        counter: this.counter.name,
        id_pharmacy: item.id,
        queue: item.queue_number,
        status: item.status,
      };
      if (e !== undefined) {
        this.speakLoading = true;
        this.emitMessageCall();
        await postCallPatient(payload);
        payload.name = item.name;
        this.emitMessageCallPatient(payload);
        this.selectedDialog = val;
        this.selectedPatient = {
          ...item,
          status: 'Panggilan',
          date: this.date,
          id_counter: this.counter._id,
          counter: this.counter.name,
          isCalled: this.disableCall(item),
        };
      } else {
        this.selectedDialog = val;
        this.selectedPatient = {
          ...item,
          date: this.date,
          id_counter: this.counter._id,
          counter: this.counter.name,
          isCalled: this.disableCall(item),
        };
      }
    },
    beforeDestroy() {
      this.interval = clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  padding: 10px 25px 25px 25px;
}
.header {
  border-bottom: 1px solid #e7e7e7;
}

.icon {
  float: right;
  padding-left: 20px;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.expand {
  border: none;
  background-color: white;
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

.chip {
  &.delivered {
    border: 1px solid #2d965a;
    color: #2d965a;
    background-color: #e5fff0;
  }
  &.undelivered {
    border: 1px solid #2f80ed;
    color: #2f80ed;
    background-color: #f0f9ff;
  }
  &.call {
    border: 1px solid #e5a82e;
    color: #e5a82e;
    background-color: #fff9e5;
  }
  &.skip {
    border: 1px solid #eb4747;
    color: #eb4747;
    background-color: #fff2f2;
  }
}

::v-deep .v-alert {
  background-color: #ebf3f9;
  &__content {
    color: #404040;
  }
}
</style>
