<template>
  <v-card class="pa-5 main-wrapper">
    <header-form
      title="Setting Margin dan Harga"
      :chip="
        isGlobalCategorySetting
          ? 'Global + Kategori Setting'
          : 'Kategori Setting'
      "
      description="Atur harga dan margin sesuai kategori barang."
      confirmationButtonIcon="mdi-check"
      :confirmationButtonText="
        getInputState === 'add' ? 'Selesai' : 'Simpan Perubahan'
      "
      :isUsingCancelButton="getIsEdit"
      :isUsingBackButton="!getIsEdit"
      :isUsingDescription="!getIsEdit"
      cancelButtonText="Batalkan Perubahan"
      cancelButtonColor="error"
      @confirm="finish"
      @cancel="closeEditForm"
      @back="back"
    />
    <section class="main-container">
      <div class="card-container">
        <template v-if="isGlobalCategorySetting && getIsEdit">
          <p class="pa-0 ma-0 grey-dark-text mb-3">GLOBAL SETTING</p>
          <ul>
            <li
              :class="
                `list-item grey-dark-text${
                  globalSettingButton.isActive ? ' active' : ''
                }`
              "
              @click="onClickList()"
            >
              <v-tooltip bottom>
                <template #activator="{ on: tooltip }">
                  <v-icon
                    v-on="{ ...tooltip }"
                    size="1vw"
                    color="success"
                    class="mr-2"
                    >mdi-check-circle</v-icon
                  >
                  <span v-on="{ ...tooltip }"> Global</span>
                </template>
                <span>Global Setting telah diatur</span>
              </v-tooltip>
            </li>
          </ul>
        </template>
        <p class="pa-0 ma-0 grey-dark-text mb-3">KATEGORI</p>
        <ul>
          <li
            v-for="(item, index) in computedListOptions"
            :key="'option-' + index"
            @click="onClickList(index)"
            :class="`list-item grey-dark-text${item.isActive ? ' active' : ''}`"
          >
            <v-tooltip bottom>
              <template #activator="{ on: tooltip }">
                <v-icon
                  v-on="{ ...tooltip }"
                  size="1vw"
                  :color="checkIconColor(index)"
                  class="mr-2"
                  >mdi-check-circle</v-icon
                >
                <span v-on="{ ...tooltip }"> {{ item.name }} </span>
              </template>
              <span v-if="item.isSaved"
                >Harga kategori "{{ item.name }}" telah diatur manual</span
              >
              <span v-else>{{
                `Harga kategori "${item?.name}" mengikuti ${
                  getSelectedSetting === 'global-category-setting'
                    ? 'setting global'
                    : 'default sistem; Margin dan PPN 0%'
                }`
              }}</span>
            </v-tooltip>
          </li>
        </ul>
      </div>
      <div>
        <div v-if="isDrug" class="card-container mb-3">
          <h4 class="required">
            Rule Kategori
          </h4>
          <p :class="`pa-0 ma-0 ${rules.alertText().class}`">
            {{ rules.alertText().text }}
          </p>
          <div class="grid-rule">
            <div class="d-flex flex-column justify-space-between">
              <v-checkbox
                @input="userTyping"
                label="Jenis Obat"
                v-model="ruleOptions.drugType"
                :value="true"
                class="ma-0"
              />
              <v-checkbox
                @input="userTyping"
                label="Kategori Obat"
                v-model="ruleOptions.drugCategory"
                :value="true"
                class="ma-0"
              />
            </div>
            <div>
              <v-radio-group
                row
                @input="userTyping"
                ref="drug-type"
                class="ma-0"
                v-model="selectedRule.drugType"
                :disabled="!ruleOptions.drugType"
                :rules="[rules.drugType]"
              >
                <v-radio label="Generik" value="Generik" />
                <v-radio label="Non Generik" value="Non Generik" />
              </v-radio-group>
              <v-autocomplete
                class="ma-0"
                ref="drug-category"
                placeholder="Pilih Golongan Obat"
                append-icon="mdi-chevron-down"
                @input="userTyping"
                :disabled="!ruleOptions.drugCategory"
                :rules="[rules.drugCategory]"
                v-model="selectedRule.drugCategory"
                :items="roa"
                return-object
                dense
                single-line
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <span class="grey-dark-text">HARGA DEFAULT</span>
          <span v-if="isDrug" class="grey-light-text">
            Base Harga: <strong class="black--text">HNA</strong>
          </span>
        </div>
        <input-price-card
          :additionalRules="
            isDrug
              ? [additionalRules.identicalPrice]
              : [additionalRules.identicalNonDrugPrice]
          "
          :isEditable="!isHasSetting || isLocalEdit"
          ref="card1"
          @on-change-input="userTyping"
          is-default
          :index="0"
        />
        <div class="text-start mt-5">
          <span class="grey-dark-text">MULTIPLE HARGA (OPSIONAL)</span>
        </div>
        <input-price-card
          @on-change-input="userTyping"
          :isEditable="!isHasSetting || isLocalEdit"
          ref="card2"
          :index="1"
        />
        <input-price-card
          @on-change-input="userTyping"
          :isEditable="!isHasSetting || isLocalEdit"
          ref="card3"
          :index="2"
        />
        <div class="d-flex justify-end pt-5">
          <v-btn
            style="letter-spacing: 0.001px; font-weight: 500;"
            color="error"
            class="text-capitalize"
            v-if="isLocalEdit || !isHasSetting"
            text
            dense
            depressed
            @click="
              () => {
                isLocalEdit && !isDrug ? cancelEdit() : resetForm();
              }
            "
            >{{ isLocalEdit ? 'Batalkan Perubahan' : 'Batal' }}</v-btn
          >
          <v-btn
            style="letter-spacing: 0.001px; font-weight: 500; border-width: 2px;"
            color="primary"
            class="ml-3 text-capitalize"
            outlined
            dense
            depressed
            :disabled="!isEnableCreateCalculation"
            @click="onClickCreateCalculationBtn"
          >
            {{ createButtonText }}
          </v-btn>
        </div>
      </div>
      <div>
        <div class="d-flex justify-space-between">
          <span class="grey-dark-text">PREVIEW PERHITUNGAN</span>
          <span class="grey-light-text caption">
            Simulasi perhitungan kategori akan ditampilkan di sini, namun belum
            tersimpan.
          </span>
        </div>
        <div class="card-container mb-4">
          <div class="d-flex justify-start align-center mb-2">
            <p class="ma-0 pa-0 caption">Parameter yang diatur:</p>
            <template
              v-if="
                listOptions.filter(item => item.isActive)[0]?.name === 'Obat'
              "
            >
              <div :class="`chip ${chip.drugType !== '-' ? ' active' : ''}`">
                {{ chip.drugType }}
              </div>
              <div
                :class="`chip ${chip.drugCategory !== '-' ? ' active' : ''}`"
              >
                {{ chip.drugCategory }}
              </div>
              <div class="chip">-</div>
            </template>
            <div
              class="chip active"
              v-if="
                chip.category &&
                  listOptions.filter(item => item.isActive)[0]?.name !== 'Obat'
              "
            >
              {{
                listOptions.filter(item => item.isActive)[0]?.name || 'Global'
              }}
            </div>
            <div :class="`chip ${chip.margin !== '' ? ' active' : ''}`">
              Margin: {{ chip.margin }}%
            </div>
            <div :class="`chip ${chip.ppn !== '' ? ' active' : ''}`">
              PPN: {{ chip.ppn }}%
            </div>
          </div>

          <!-- preview table -->
          <div class="grid-preview-table">
            <p
              v-for="(item, index) in table.preview.header"
              :key="'table-header-preview-' + index"
              :class="
                `ma-0 pa-0 font-weight-medium grey-light-text${
                  index === 0 ? ' text-start' : ' text-end'
                }`
              "
            >
              {{ item || `Harga ${index}` }}
            </p>
          </div>
          <v-progress-linear
            v-if="getLoadingState.previewTable"
            indeterminate
            rounded
          />
          <div :class="`preview-table-wrapper${isDrug ? ' ' : ' full '}mb-3`">
            <div
              v-for="(item, index) in table.preview.data"
              class="grid-preview-table row"
              :key="'table-row-preview-' + index"
            >
              <p class="text-start ma-0 pa-0">{{ item.name }}</p>
              <p class="text-end ma-0 pa-0">{{ currency(item.price1 || 0) }}</p>
              <p class="text-end ma-0 pa-0">{{ currency(item.price2 || 0) }}</p>
              <p class="text-end ma-0 pa-0">{{ currency(item.price3 || 0) }}</p>
            </div>
          </div>
          <div class="d-flex justify-end">
            <!-- <v-btn color="success" @click="deletesoon">hapus pengaturan</v-btn> -->
            <v-btn
              style="letter-spacing: 0.001px;"
              dense
              single-line
              depressed
              :color="chip.category ? '#2b9c4d' : '#9e9e9e'"
              dark
              class="text-capitalize"
              @click="addSettingToTable"
            >
              Simpan Pengaturan
            </v-btn>
          </div>
        </div>
        <template v-if="isDrug">
          <div class="d-flex justify-space-between mb-3">
            <span class="grey-dark-text"
              >PENGATURAN KATEGORI OBAT TERSIMPAN</span
            >
            <span class="grey-light-text caption">
              Kategori obat yang telah Anda atur dan simpan akan tampil di sini.
            </span>
          </div>
          <div class="grid-result-table">
            <div
              v-for="(item, index) in table.result.header"
              :key="'table-header-result-' + index"
            >
              <p
                :class="
                  `ma-0 pa-0 font-weight-medium grey-light-text${
                    index < 3 ? ' text-start' : ' text-end'
                  }`
                "
              >
                {{ item }}
              </p>
            </div>
          </div>
          <div class="result-table-wrapper pt-4">
            <div
              v-for="(item, index) in table.result.data.filter(
                i => i.category === 'medicine',
              )"
              class="grid-result-table row"
              :key="'table-row-result-' + index"
            >
              <v-tooltip bottom>
                <template #activator="{ on: tooltip }">
                  <p v-on="{ ...tooltip }" class="ma-0 pa-0 text-start">
                    {{ item.displayName }}
                  </p>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
              <p class="ma-0 pa-0 text-start">{{ item.price[0].margin }}%</p>
              <p class="ma-0 pa-0 text-start">{{ item.price[0].ppn }}%</p>
              <div class="d-flex">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      v-on="{ ...tooltip }"
                      @click="editDrugSetting(index)"
                      :class="`${isLocalEdit ? 'btn-disabled' : ''}`"
                      small
                      text
                      fab
                      dense
                      single-line
                    >
                      <v-icon small :color="isLocalEdit ? '#c2c2c2' : '#757575'"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{
                    isLocalEdit
                      ? 'Kategori tidak dapat diedit karena ada data yang sedang aktif di form'
                      : 'Edit Kategori'
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      v-on="{ ...tooltip }"
                      @click="deleteDrugSetting(index)"
                      :class="`${isLocalEdit ? 'btn-disabled' : ''}`"
                      small
                      text
                      fab
                      dense
                      single-line
                    >
                      <v-icon small :color="isLocalEdit ? '#c2c2c2' : '#757575'"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{
                    isLocalEdit
                      ? 'Kategori tidak dapat dihapus karena ada data yang sedang aktif di form'
                      : 'Hapus Kategori'
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
    <v-snackbar
      style="position: absolute"
      :timeout="4000"
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      <div class="d-flex justify-space-between">
        <span class="mr-auto">{{ snackbar.text }}</span>
        <v-icon class="ml-5" dark small @click="() => (snackbar.show = false)">
          mdi-close
        </v-icon>
      </div>
    </v-snackbar>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
const _ = require('lodash');
import formatMixin from '@/mixin/formatMixin';

import { createNamespacedHelpers } from 'vuex';

const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

import { deleteDrugMargin } from '../../../../../fetchApi/Setting/MarginPrice';

import HeaderForm from '../HeaderForm.vue';
import InputPriceCard from '../GlobalSetting/InputPriceCard.vue';

// api
import { getGroup } from '@/fetchApi/Resource/Drugs';

export default {
  components: { HeaderForm, InputPriceCard },
  mixins: [formatMixin],
  name: 'SettingCategoryMarginAndPriceDialog',
  data() {
    return {
      isLocalEdit: false, // edit state in this local component
      editedForm: null,
      isEditing: false,
      editedDrugSettingIndex: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      ruleOptions: {
        drugType: false,
        drugCategory: false,
      },
      selectedRule: {
        drugType: '',
        drugCategory: '',
      },
      chip: {
        drugType: '-',
        drugCategory: '-',
        margin: 0,
        ppn: 0,
      },
      table: {
        preview: {
          header: ['Nama Barang', '', '', ''],
          data: [],
        },
        result: {
          header: ['Nama Kategori', 'Margin', 'PPN', ''],
          data: [],
        },
      },
      roa: [],
      globalSettingButton: {
        isActive: false,
      },
      listOptions: [
        { name: 'Alat Kesehatan', value: 'medical-equipment', isActive: false },
        { name: 'Alat Fisioterapi', value: 'fisio-equipment', isActive: false },
        { name: 'Obat', value: 'medicine', isActive: true },
        { name: 'Gas Medis', value: 'medical-gas', isActive: false },
        {
          name: 'Bahan Baku Obat',
          value: 'raw-materials-for-medicine',
          isActive: false,
        },
        { name: 'Reagen Lab.', value: 'lab-reagents', isActive: false },
        {
          name: 'Bahan Radiologi',
          value: 'radiology-materials',
          isActive: false,
        },
        { name: 'Embalase', value: 'packaging', isActive: false },
        { name: 'BHP', value: 'medical-consumables', isActive: false },
      ],
    };
  },
  watch: {
    getSelectedCategory(val) {
      this.resetForm();
      this.resetPreviewTable();
      if (this.isLocalEdit) {
        this.cancelEdit();
      }
      this.isLocalEdit = false;
      if (!this.isDrug || val === undefined) this.mapSavedValueToForm();
    },
  },
  computed: {
    ...mapGetters([
      'getDataFromServer',
      'getInputState',
      'getIsEnableConfirmationBtn',
      'getPreviewData',
      'getPriceSetting',
      'getSelectedCategory',
      'getSelectedSetting',
      'getLoadingState',
      'getTemporaryStore',
      'getIsEdit',
    ]),
    delsoon: {
      get() {
        return typeof this.additionalRules.identicalNonDrugPrice();
      },
    },
    isInputOutlined: {
      get() {
        return (
          !this.drugOnlyData.length &&
          this.isDrug &&
          this.getIsEdit &&
          (this.getPriceSetting[0].margin !== '' ||
            this.getPriceSetting[0].ppn !== '' ||
            this.getPriceSetting[0].description !== '' ||
            this.getPriceSetting[1].isActive ||
            this.getPriceSetting[2].isActive ||
            this.ruleOptions.drugType ||
            this.ruleOptions.drugCategory)
        );
      },
    },
    drugOnlyData: {
      get() {
        return this.table.result.data.filter(i => i.category === 'medicine');
      },
    },
    isGlobalCategorySetting: {
      get() {
        return this.getSelectedSetting === 'global-category-setting';
      },
    },
    computedListOptions: {
      get() {
        return this.listOptions.map(item => {
          return {
            ...item,
            isSaved: !!this.table.result.data.find(
              i => i.category === item.value,
            ),
          };
        });
      },
    },
    createButtonText: {
      get() {
        return this.isLocalEdit || !this.isHasSetting
          ? 'Buat Perhitungan'
          : 'Edit Pengaturan';
      },
    },
    isHasSetting: {
      get() {
        return (
          !this.getSelectedCategory ||
          (!this.isDrug &&
            this.table.result.data.some(
              item => item.category === this.getSelectedCategory,
            ))
        );
      },
    },
    isDrug: {
      get() {
        return this.getSelectedCategory === 'medicine';
      },
    },
    hasFormChanged: {
      get() {
        return (
          this.getPriceSetting[0].margin !== '' ||
          this.getPriceSetting[0].ppn !== '' ||
          this.getPriceSetting[0].description !== '' ||
          this.getPriceSetting[1].isActive ||
          this.getPriceSetting[2].isActive ||
          this.ruleOptions.drugType ||
          this.ruleOptions.drugCategory ||
          this.table.result.data.length
        );
      },
    },
    isEnableCreateCalculation() {
      if (this.isLocalEdit) {
        if (this.isDrug) {
          return (
            this.isEditing &&
            this.getIsEnableConfirmationBtn &&
            (this.ruleOptions.drugType || this.ruleOptions.drugCategory) &&
            typeof this.additionalRules.identicalPrice() !== 'string' &&
            typeof this.rules.drugType() !== 'string' &&
            typeof this.rules.drugCategory() !== 'string'
          );
        }
        return (
          this.isEditing &&
          this.getIsEnableConfirmationBtn &&
          typeof this.additionalRules.identicalNonDrugPrice() !== 'string'
        );
      }
      if (!this.isDrug) {
        return (
          this.getIsEnableConfirmationBtn &&
          typeof this.additionalRules.identicalNonDrugPrice() !== 'string'
        );
      }
      return (
        this.getIsEnableConfirmationBtn &&
        (this.ruleOptions.drugType || this.ruleOptions.drugCategory) &&
        typeof this.rules.drugType() !== 'string' &&
        typeof this.rules.drugCategory() !== 'string' &&
        typeof this.additionalRules.identicalPrice() !== 'string'
      );
    },
    additionalRules: {
      get() {
        return {
          identicalPrice: () => {
            return (
              !this.drugOnlyData
                .filter((item, index) => {
                  if (this.isLocalEdit) {
                    return index !== this.editedDrugSettingIndex;
                  } else {
                    return item;
                  }
                })
                .concat(
                  this.getIsEdit
                    ? {
                        price: this.table.result.data
                          .filter(i => !i.category)
                          .map(i => ({
                            margin: i.price[0].margin,
                            ppn: i.price[0].ppn,
                          })),
                      }
                    : {
                        price: [
                          {
                            margin: this.getTemporaryStore[0].margin,
                            ppn: this.getTemporaryStore[0].ppn,
                          },
                        ],
                      },
                )
                .some(item => {
                  return ['margin', 'ppn'].every(
                    key =>
                      +item.price[0][key] === +this.getPriceSetting[0][key],
                  );
                }) || 'Sudah ada!'
            );
          },
          identicalNonDrugPrice: () => {
            if (this.globalSettingButton.isActive) return true;
            let comparedValue;
            if (this.isLocalEdit) {
              comparedValue = this.table.result.data.filter(
                item =>
                  item.categoryName ===
                  this.listOptions.find(i => i.isActive).name,
              )[0];
            } else {
              comparedValue = this.getPriceSetting[0];
            }
            if (!comparedValue.category && this.isLocalEdit) return true;
            if (this.getIsEdit) {
              return (
                !['margin', 'ppn'].every(
                  key =>
                    (+comparedValue.price?.[0]?.[key] ||
                      +comparedValue[key]) ===
                    +this.table.result.data.filter(i => !i.category)[0]
                      .price[0][key],
                ) || 'Sudah ada'
              );
            }
            return (
              !['margin', 'ppn'].every(
                key =>
                  (+comparedValue.price?.[0]?.[key] || +comparedValue[key]) ===
                  +this.getTemporaryStore[0][key],
              ) || 'Sudah ada'
            );
          },
        };
      },
    },
    rules: {
      get() {
        return {
          drugType: () => {
            if (!this.ruleOptions.drugType) return true;
            return (
              !this.drugOnlyData.find(
                (item, index) =>
                  this.selectedRule.drugType === item.drugType &&
                  (this.selectedRule.drugCategory?.text ||
                    this.selectedRule.drugCategory) ===
                    (item.drugCategory || undefined) &&
                  (!this.isLocalEdit || this.editedDrugSettingIndex !== index),
              ) || ''
            );
          },
          drugCategory: () => {
            if (!this.ruleOptions.drugCategory) return true;
            return (
              !this.drugOnlyData.find(
                (item, index) =>
                  this.selectedRule.drugType === item.drugType &&
                  (this.selectedRule.drugCategory?.text ||
                    this.selectedRule.drugCategory) === item.drugCategory &&
                  (!this.isLocalEdit || this.editedDrugSettingIndex !== index),
              ) || 'Ganti dengan Golongan Obat yang lain'
            );
          },
          alertText: () => {
            if (
              typeof this.rules.drugType() === 'string' &&
              this.ruleOptions.drugType &&
              this.selectedRule.drugType !== ''
            ) {
              return {
                text: 'Jenis Obat yang Anda pilih sudah diatur!',
                class: 'error--text',
              };
            } else if (
              typeof this.rules.drugCategory() === 'string' &&
              this.ruleOptions.drugCategory &&
              this.selectedRule.drugCategory?.text
            ) {
              return {
                text: 'Kategori Obat yang Anda pilih sudah diatur!',
                class: 'error--text',
              };
            }
            return {
              text: 'Pilih minimal satu rule dan maksimal dua rule',
              class: 'grey-light-text',
            };
          },
        };
      },
    },
  },
  mounted() {
    this.resolveGetGroup();
    if (this.getIsEdit) {
      this.mapExistingData();
    }
  },
  beforeDestroy() {
    this.setSelectedCategory('medicine');
  },
  methods: {
    ...mapActions([
      'resolvePostAddDrugMargin',
      'resolveGetPreviewCalculation',
      'resolvePutAddDrugMargin',
    ]),
    ...mapMutations([
      'setSelectedForm',
      'clearPriceState',
      'setSelectedCategory',
      'resetPriceSetting',
      'setPriceSettingDirectly',
      'setIsEdit',
      'setDialog',
    ]),
    async deletesoon() {
      await deleteDrugMargin();
    },
    translateCategoryFromDashboard(name) {
      switch (name) {
        case 'Alat Kesehatan':
          return 'medical-equipment';
        case 'Alat Fisioterapi':
          return 'fisio-equipment';
        case 'Obat':
          return 'medicine';
        case 'Gas Medis':
          return 'medical-gas';
        case 'Bahan Baku Obat':
          return 'raw-materials-for-medicine';
        case 'Reagen Lab.':
          return 'lab-reagents';
        case 'Bahan Radiologi':
          return 'radiology-materials';
        case 'Embalase':
          return 'packaging';
        case 'BHP':
          return 'medical-consumables';
        default:
          return null; // Return null if no match is found
      }
    },
    mapExistingData() {
      this.table.result.data = JSON.parse(
        JSON.stringify(
          this.getDataFromServer.nondrugs.map(item => {
            while (item.margins.length < 3) {
              item.margins.push({
                margin: 0,
                ppn: 0,
                description: '',
                isActive: false,
              });
            }
            return {
              name: '',
              price: item.margins,
              isNew: false,
              categoryName: item.category,
              category: this.translateCategoryFromDashboard(item.category),
            };
          }),
        ),
      ).concat(
        JSON.parse(
          JSON.stringify(
            this.getDataFromServer.drugs
              .filter(item => item.name !== 'Obat Belum Diatur')
              .map(item => {
                while (item.margins.length < 3) {
                  item.margins.push({
                    margin: 0,
                    ppn: 0,
                    description: '',
                    isActive: false,
                  });
                }
                return {
                  name: `Obat - ${item.name}`.toUpperCase(),
                  price: item.margins.map(v => ({
                    ...v,
                    isActive: !!v.margin,
                  })),
                  isNew: false,
                  displayName: `Obat - ${item.name}`.toUpperCase(),
                  category: 'medicine',
                  categoryName: 'Obat',
                  drugCategory: item.rule.groupName,
                  drugType:
                    typeof item.rule.isGeneric === 'boolean'
                      ? item.rule.isGeneric
                        ? 'Generik'
                        : 'Non Generik'
                      : null,
                };
              }),
          ),
        ),
      );
    },
    closeEditForm() {
      this.setIsEdit(false);
      this.setDialog(false);
    },
    finish() {
      const additionalData = this.table.result.data.map(item => {
        return {
          category:
            item.categoryName === 'Global Setting' ? '' : item.categoryName,
          rule: {
            isGeneric: item.drugType
              ? item.drugType.toLowerCase() === 'generik'
              : null,
            groupName: item.drugCategory || null,
          },
          margins: item.price.map(i => {
            return {
              ppn: +i.ppn,
              margin: +i.margin,
              description: i.description,
              isActive: !!i.isActive,
            };
          }),
        };
      });
      // console.log(additionalData);

      this.$emit('finish-setting', {
        origin: 3,
        snackbarText: 'Setting Margin dan Harga Farmasi berhasil dibuat!',
        confirmationFunction: async () => {
          if (this.getIsEdit) {
            await this.resolvePutAddDrugMargin({
              type:
                this.getSelectedSetting === 'global-category-setting'
                  ? 'global_category'
                  : 'category',
              additionalData,
            });
          } else {
            await this.resolvePostAddDrugMargin({
              type:
                this.getSelectedSetting === 'global-category-setting'
                  ? 'global_category'
                  : 'category',
              additionalData,
            });
          }
        },
      });
    },
    deleteDrugSetting(index) {
      if (this.isLocalEdit) return;
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Anda yakin ingin menghapus kategori</p><p>"${this.drugOnlyData[index].name}"?</p></div>`,
        iconHtml:
          '<span sstyle="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
        html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Jika dihapus, harga obat akan mengikuti${
          this.isGlobalCategorySetting
            ? ' global setting</p>'
            : ' default sistem</p><p>dengan margin dan PPN 0%</p>'
        }</div>`,
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-error-filled',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          const actualIndex = this.table.result.data
            .map((item, idx) => ({ ...item, originalIndex: idx })) // Add the original index
            .filter(item => item.category === 'medicine')[index]?.originalIndex;

          this.table.result.data = this.table.result.data.filter(
            (_, idx) => idx !== actualIndex,
          );
          this.snackbar = {
            show: true,
            text: 'Setting margin dan harga pada kategori berhasil dihapus',
            color: '#2b9c4d',
          };
        }
      });
    },
    onClickCreateCalculationBtn() {
      if (this.isDrug) {
        this.createCalculation();
      } else {
        !this.isLocalEdit && this.isHasSetting
          ? this.editSetting()
          : this.createCalculation();
      }
    },
    editDrugSetting(index) {
      if (this.isLocalEdit) return;

      const editedData = this.drugOnlyData[index];

      this.editedDrugSettingIndex = index;
      this.isLocalEdit = true;
      this.ruleOptions = {
        drugType: !!editedData.drugType,
        drugCategory: !!editedData.drugCategory,
      };
      this.selectedRule = {
        drugType: editedData.drugType,
        drugCategory: editedData.drugCategory,
      };
      this.setPriceSettingDirectly(editedData.price);
    },
    checkIconColor(index) {
      if (this.computedListOptions[index].isSaved) return 'success';
      if (this.computedListOptions[index].isActive) return 'primary';
      return '';
    },
    userTyping() {
      this.$refs['drug-category']?.validate();
      this.$refs['drug-type']?.validate();
      if (this.isLocalEdit) this.isEditing = true;
    },
    back() {
      if (this.hasFormChanged) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Konfirmasi Tindakan</p></div>`,
          iconHtml:
            '<span sstyle="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda belum menyelesaikan pengisian form. Perubahan yang</p><p>belum disimpan akan hilang. Apakah Anda yakin ingin</p><p>membatalkan perubahan?</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Tetap Mengisi Form',
          cancelButtonText: 'Batalkan Perubahan',
        }).then(result => {
          if (result.isConfirmed) {
            return;
          }
          this.clearPriceState();
          this.setPriceSettingDirectly(this.getTemporaryStore);
          this.setSelectedForm(this.isGlobalCategorySetting ? 4 : 0);
        });
      } else {
        this.setPriceSettingDirectly(this.getTemporaryStore);
        this.setSelectedForm(this.isGlobalCategorySetting ? 4 : 0);
      }
    },
    mapSavedValueToForm() {
      if (!this.getSelectedCategory) {
        this.setPriceSettingDirectly(
          this.table.result.data.find(item => !item.category).price,
        );
        return;
      }
      this.setPriceSettingDirectly(
        this.table.result.data.find(
          item => item.category === this.getSelectedCategory,
        )?.price || this.getPriceSetting,
      );
    },
    resetPreviewTable() {
      this.table.preview.data = [];
      this.chip = {
        category: '',
        drugType: '-',
        drugCategory: '-',
        margin: 0,
        ppn: 0,
      };
      this.table.preview.header = ['Nama Barang', '', '', ''];
    },
    cancelEdit() {
      this.setPriceSettingDirectly(this.editedForm);
      this.resetPreviewTable();
      this.isLocalEdit = false;
    },
    resetForm() {
      this.isLocalEdit = false;
      this.editedDrugSettingIndex = null;
      this.resetPriceSetting();
      this.ruleOptions = {
        drugType: false,
        drugCategory: false,
      };
      this.selectedRule = {
        drugType: '',
        drugCategory: '',
      };
      this.$refs.card1.$refs.form.resetValidation();
      this.$refs.card2.$refs.form.resetValidation();
      this.$refs.card3.$refs.form.resetValidation();
    },
    onClickEditButton() {
      // try {
      if (this.isDrug) {
        const actualIndex = this.table.result.data
          .map((item, index) => ({ ...item, originalIndex: index })) // Add the original index
          .filter(item => item.category === 'medicine')[
          this.editedDrugSettingIndex
        ]?.originalIndex;

        if (actualIndex !== undefined) {
          const name =
            'OBAT' +
            `${
              this.selectedRule.drugType && this.ruleOptions.drugType
                ? ` - ${this.selectedRule.drugType}`
                : ''
            }${
              this.selectedRule.drugCategory && this.ruleOptions.drugCategory
                ? ` - ${this.selectedRule.drugCategory.text ||
                    this.selectedRule.drugCategory}`
                : ''
            }`.toUpperCase();

          this.table.result.data = this.table.result.data.map((item, idx) => {
            return actualIndex === idx
              ? {
                  ...item,
                  name,
                  drugType: this.ruleOptions.drugType
                    ? this.selectedRule.drugType
                    : '',
                  drugCategory: this.ruleOptions.drugCategory
                    ? this.selectedRule.drugCategory?.text ||
                      this.selectedRule.drugCategory
                    : '',
                  displayName: name,
                  price: this.getPriceSetting,
                  isNew: true,
                  category: this.getSelectedCategory,
                }
              : item;
          });
        }
        return;
      } else if (!this.getSelectedCategory) {
        this.table.result.data = this.table.result.data.map(item => {
          return !item.category
            ? {
                ...item,
                price: this.getPriceSetting,
              }
            : item;
        });
      } else {
        this.table.result.data = this.table.result.data.map(item => {
          return this.getSelectedCategory === item.category
            ? {
                ...item,
                price: this.getPriceSetting,
              }
            : item;
        });
      }
      // }
      // catch (e) {
      //   console.log(e);
      // }
    },
    addSettingToTable() {
      // try {
      if (!this.chip.margin) return;
      if (this.isLocalEdit) {
        this.onClickEditButton();
      } else {
        const name =
          'OBAT' +
          `${
            this.selectedRule.drugType && this.ruleOptions.drugType
              ? ` - ${this.selectedRule.drugType}`
              : ''
          }${
            this.selectedRule.drugCategory?.text &&
            this.ruleOptions.drugCategory
              ? ` - ${this.selectedRule.drugCategory.text}`
              : ''
          }`.toUpperCase();
        this.table.result.data.push({
          name: this.isDrug ? name : '',
          drugType: this.ruleOptions.drugType ? this.selectedRule.drugType : '',
          drugCategory: this.ruleOptions.drugCategory
            ? this.selectedRule.drugCategory?.text ||
              this.selectedRule.drugCategory
            : null,
          displayName: name,
          price: this.getPriceSetting,
          isNew: true,
          category: this.getSelectedCategory,
          categoryName: this.listOptions.filter(item => item.isActive)[0].name,
        });
      }

      if (this.isDrug) this.resetForm();

      if (this.isDrug && this.isLocalEdit) {
        this.snackbar = {
          show: true,
          text: 'Kategori berhasil diubah',
          color: '#2b9c4d',
        };
      } else {
        this.snackbar = {
          show: true,
          text: !this.getSelectedCategory
            ? 'Setting Global berhasil diubah'
            : `Kategori ${
                this.isDrug
                  ? this.table.result.data[this.table.result.data.length - 1]
                      .name
                  : this.listOptions
                      .filter(i => i.isActive)[0]
                      .name.toUpperCase()
              } berhasil${this.isLocalEdit ? ' diubah' : ' ditambahkan'}`,
          color: '#2b9c4d',
        };
      }

      this.isLocalEdit = false;
      this.resetPreviewTable();
      // }
      // catch (error) {
      //   console.log(error);
      // }
    },
    editSetting() {
      this.editedForm = _.cloneDeep(this.getPriceSetting);
      this.isLocalEdit = true;
      this.setFormBeforeEdit(this.getPriceSetting);
    },
    async createCalculation() {
      // map data to the chip above the preview table
      // try {
      // console.log('masuk');
      this.chip = {
        category:
          this.listOptions.filter(item => item.isActive)?.[0]?.name || 'Global',
        drugType: this.ruleOptions.drugType ? this.selectedRule.drugType : '-',
        drugCategory: this.ruleOptions.drugCategory
          ? this.selectedRule.drugCategory.text ||
            this.selectedRule.drugCategory
          : '-',
        margin: this.getPriceSetting[0].margin || 0,
        ppn: this.getPriceSetting[0].ppn || 0,
      };
      // mapping header name
      this.table.preview.header = this.table.preview.header.map((_, index) => {
        if (index === 0) return 'Nama Barang';
        return this.getPriceSetting[index - 1].description || `Harga ${index}`;
      });

      // create formula to calculate the final price
      const calculatePrice = (hna, index) => {
        if (!this.getPriceSetting[index]?.isActive) return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice * this.getPriceSetting[index].margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * this.getPriceSetting[index].ppn) / 100;
        return priceAfterMargin + taxAmount;
      };

      // make an api call to get data preview
      await this.resolveGetPreviewCalculation({
        isGeneric: this.ruleOptions.drugType
          ? this.selectedRule.drugType === 'Generik'
          : null,
        group: this.ruleOptions.drugCategory
          ? this.selectedRule.drugCategory.text
          : null,
        category: this.listOptions.filter(item => item.isActive)?.[0]?.name,
      });

      // mapping data from api
      this.table.preview.data = this.getPreviewData.map(item => {
        return {
          ...item,
          price1: calculatePrice(item.hna, 0),
          price2: calculatePrice(item.hna, 1),
          price3: calculatePrice(item.hna, 2),
        };
      });
      // console.log(
      //   'selesai',
      //   this.getPreviewData,
      //   this.getPreviewData.map(item => {
      //     return {
      //       ...item,
      //       price1: calculatePrice(item.hna, 0),
      //       price2: calculatePrice(item.hna, 1),
      //       price3: calculatePrice(item.hna, 2),
      //     };
      //   }),
      // );
      // }

      //  catch (e) {
      //   console.log(e);
      // }
    },
    async resolveGetGroup() {
      try {
        const response = await getGroup();
        this.roa = response.data.data.map(item => ({
          text: item.name,
          id: item._id,
        }));
      } catch {
        this.roa = [];
      }
    },
    onClickList(index) {
      // try {
      if (index === undefined) {
        this.globalSettingButton.isActive = true;
        this.listOptions = this.listOptions.map(item => ({
          ...item,
          isActive: false,
        }));
        this.setSelectedCategory();
        return;
      }
      this.listOptions = this.listOptions.map((item, i) => {
        this.globalSettingButton.isActive = false;
        if (i === index) {
          this.setSelectedCategory(item.value);
          return { ...item, isActive: true };
        }
        return { ...item, isActive: false };
      });
      // }
      // catch (e) {
      //   console.log(e);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  position: relative;
  overflow: auto;
  height: 90vh;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.main-container {
  display: grid;
  grid-template-columns: 17% 33% 46%;
  column-gap: 2%;

  .result-table-wrapper {
    height: 10vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }

  .grid-result-table {
    display: grid;
    grid-template-columns: 69% repeat(2, 7.5%) 8%;
    column-gap: 2%;
    padding: 0.5rem 0.3rem;
    border-bottom: 2px solid #e0e0e0;
    align-items: center;

    p {
      font-size: 0.85vw;
    }

    &.row {
      padding: 0.1rem 1rem;
      border-bottom: 1px solid #e0e0e0;

      .btn-disabled {
        cursor: not-allowed;
      }

      p {
        font-size: 0.8vw;
      }
    }
  }

  .card-container {
    text-align: start;
    height: fit-content;
    background-color: #f0f8fe;
    border-radius: 8px;
    padding: 1rem;

    h4 {
      font-size: 1vw;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    .grid-preview-table {
      display: grid;
      grid-template-columns: 50% repeat(3, calc(44% / 3));
      column-gap: 2%;
      border-bottom: 2px solid #e0e0e0;
      padding: 0.4rem 0.3rem;

      &.row {
        padding: 0.6rem 1rem;
        border-bottom: 1px solid #ededed;
        font-size: 0.75vw;
      }
    }

    .preview-table-wrapper {
      height: 30vh;
      padding-top: 1rem;
      overflow-y: auto;
      overflow-x: hidden;

      &.full {
        height: 50vh;
      }

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #757575;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
    }

    .list-item {
      padding: 0.6vw 0.8vw;
      font-size: 1vw;
      border-radius: 8px;
      margin-bottom: 0.4vw;
      display: flex;
      align-items: center;

      &.active {
        background-color: #fff;
        color: #1976d2;
      }

      &.active:hover {
        background-color: #fff;
      }
    }

    .list-item:hover {
      background-color: #e3f1fc;
      cursor: pointer;
    }

    .grid-rule {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 0.5rem;
    }

    .chip {
      background-color: #f5f5f5;
      padding: 0.2rem 0.8rem;
      border-radius: 20px;
      margin: 0px 0.4rem;
      font-size: 0.75vw;

      &.active {
        background-color: transparent;
        border: 2px solid #1976d2;
        color: #1976d2;
      }
    }
  }
}

.grey-light-text {
  color: #9e9e9e;
  font-size: 0.8vw;
}

.error--text {
  font-size: 0.8vw !important;
}

.grey-dark-text {
  font-size: 0.85vw;
  color: #616161;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
