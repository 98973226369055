<template>
  <v-card>
    <v-container fluid>
      <div :class="`sticky ${!isEnable ? 'sticky-up' : ''}`">
        <div class="d-flex justify-space-between center-width">
          <div class="text-sm-left mb-2 pl-3">
            <h2 class="heading_big mt-3">
              Penyerahan Obat dan Alkes
            </h2>
          </div>
          <v-btn @click="handleCloseForm" text fab>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <pharmacy-header />

        <div
          v-if="isEnable"
          class="d-flex justify-space-between px-6 py-2 align-center"
        >
          <v-alert
            v-if="patient.counter === undefined"
            dense
            outlined
            text
            type="info"
          >
            <span>
              Pilih loket terlebih dahulu untuk memanggil pasien via sistem
              antrean
            </span></v-alert
          >
          <div
            v-else
            style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
          >
            <div
              style="display: flex; justify-content: center; align-items: center; gap: 1.5rem; margin-left: 1rem;"
            >
              <v-chip color="#F5F5F5" label>{{ patient.counter }}</v-chip>
              <button
                @click="handleCallPatient"
                :disabled="handleDisable() || !detailState"
              >
                <span
                  :style="
                    `display: flex; gap: 0.5rem; justify-content: center; align-items: center; color: ${
                      handleDisable() || !detailState ? '#9CA2A5' : '#137BC0'
                    }`
                  "
                >
                  <v-icon
                    :style="
                      `color: ${
                        handleDisable() || !detailState ? '#9CA2A5' : '#137BC0'
                      }`
                    "
                    >mdi-volume-high</v-icon
                  >
                  {{ handleDisable() ? 'Sedang Memanggil' : 'Panggil Pasien' }}
                </span>
              </button>
              <button :disabled="!detailState" @click="handleSkipPatient">
                <span
                  :style="
                    `display: flex; gap: 0.5rem; justify-content: center; align-items: center; color: ${
                      !detailState ? '#9CA2A5' : '#EB4747'
                    }`
                  "
                >
                  <v-icon
                    :style="`color: ${!detailState ? '#9CA2A5' : '#EB4747'}`"
                    >mdi-skip-next</v-icon
                  >
                  Lewati Antrean
                </span>
              </button>
            </div>
            <v-btn
              color="primary"
              depressed
              outlined
              @click="handleToDetail"
              class="text-none"
            >
              Detail Antrian
              <v-icon right>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>

        <v-tabs class="center-width" v-model="tab" grow slider-color="blue">
          <v-tab
            key="DrugToolReq"
            class="text-none font-weight-bold"
            @click="menuSelected = 1"
          >
            Permintaan Obat dan Alkes
          </v-tab>
          <v-tab
            key="drugReview"
            class="text-none font-weight-bold"
            @click="menuSelected = 2"
            :disabled="!isRecipeReady"
          >
            Telaah Obat dan Resep
          </v-tab>
        </v-tabs>
      </div>
      <v-tabs-items
        :class="`after-sticky ${!isEnable ? 'after-sticky-up' : ''}`"
        v-model="tab"
      >
        <v-tab-item key="DrugToolReq" class="mb-16">
          <v-card flat class="body-container">
            <span class="title pb-2 center-width"
              >Permintaan Obat Non Racik</span
            >
            <skeleton :isLoading="isLoading">
              <v-data-table
                :headers="nonRacikTable.headers"
                :items="nonmix"
                :items-per-page="nonmix?.length"
                hide-default-footer
                class="center-width"
              >
                <template slot="no-data">
                  <span style="color: black">Tidak ada obat yang dipesan</span>
                </template>
                <template v-slot:[`item.no`]="{ item }">
                  <tr class="text-left">
                    <td>
                      {{ nonmix.indexOf(item) + 1 }}
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <tr class="text-left">
                    <td>{{ item.name }}</td>
                    <div
                      v-if="item.isAddOn"
                      class="addon-chip d-flex flex-col align-center ml-2"
                    >
                      <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                        >mdi-cash</v-icon
                      >
                      Tambahan
                    </div>
                  </tr>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="item.status"
                      :readonly="isHandovered"
                      color="success"
                    ></v-checkbox>
                    <v-tooltip bottom v-if="!isHandovered">
                      <template #activator="{on: tooltip}">
                        <v-btn v-on="{ ...tooltip }" icon>
                          <v-icon @click="openDialog('nonmix', item)">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Ubah obat</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </skeleton>

            <span class="title pb-2 center-width mt-4"
              >Permintaan Obat Racik</span
            >
            <skeleton :isLoading="isLoading">
              <v-data-table
                :headers="racikTable.headers"
                :items="mix"
                :items-per-page="mix?.length"
                hide-default-footer
                class="center-width"
                ><template slot="no-data">
                  <span style="color: black">Tidak ada obat yang dipesan</span>
                </template>
                <template v-slot:[`item.no`]="{ item }">
                  <tr class="text-left">
                    <td>
                      {{ mix.indexOf(item) + 1 }}
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <tr class="text-left">
                    <td>
                      {{ item.name }}
                    </td>
                    <div
                      v-if="item.isAddOn"
                      class="addon-chip d-flex flex-col align-center ml-2"
                    >
                      <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                        >mdi-cash</v-icon
                      >
                      Tambahan
                    </div>
                  </tr>
                </template>
                <template v-slot:[`item.drugs`]="{ item }">
                  <tr
                    v-for="(drug, index) in item.drugs"
                    :key="'drug-' + index"
                  >
                    <td class="py-1">{{ drug.id_drugs.name }}</td>
                  </tr>
                </template>
                <template v-slot:[`item.mix_quantity`]="{ item }">
                  <tr>
                    <td class="py-1">{{ item.quantity }}</td>
                  </tr>
                </template>
                <template v-slot:[`item.preparation`]="{ item }">
                  <tr
                    v-for="(preparation, index) in item.drugs"
                    :key="'drug-' + index"
                  >
                    <td class="py-1">{{ preparation.preparation }}</td>
                  </tr>
                </template>
                <template v-slot:[`item.dosage`]="{ item }">
                  <tr
                    v-for="(dosage, index) in item.drugs"
                    :key="'dosage-' + index"
                  >
                    <td class="py-1">
                      {{ dosage.dosage_needed + ' ' + dosage.unit_dosage }}
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.requested_quantity`]="{ item }">
                  <tr
                    v-for="(drug, index) in item.drugs"
                    :key="'drug-' + index"
                  >
                    <td class="py-1">{{ drug.requested_quantity }}</td>
                  </tr>
                </template>
                <template v-slot:[`item.quantity`]="{ item }">
                  <tr
                    v-for="(drug, index) in item.drugs"
                    :key="'drug-' + index"
                  >
                    <td class="py-1">{{ drug.quantity }}</td>
                  </tr>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="item.status"
                      :readonly="isHandovered"
                      color="success"
                    ></v-checkbox>
                    <v-tooltip bottom v-if="!isHandovered">
                      <template #activator="{on: tooltip}">
                        <v-btn v-on="{ ...tooltip }" icon>
                          <v-icon @click="openDialog('mix', item)">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Ubah obat</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </skeleton>

            <span class="title pb-2 center-width mt-4"
              >Penyiapan Alkes/BHP</span
            >
            <skeleton :isLoading="isLoading">
              <v-data-table
                :headers="consumableTable.headers"
                :items="medtools"
                :items-per-page="medtools?.length"
                hide-default-footer
                class="center-width"
                ><template slot="no-data">
                  <span style="color: black">Tidak ada alkes yang dipesan</span>
                </template>
                <template v-slot:[`item.no`]="{ item }">
                  <tr class="text-left">
                    <td>
                      {{ medtools.indexOf(item) + 1 }}
                    </td>
                  </tr>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <tr class="text-left">
                    <td>
                      {{ item.name }}
                    </td>
                    <div
                      v-if="item.isAddOn"
                      class="addon-chip d-flex flex-col align-center ml-2"
                    >
                      <v-icon small :style="{ color: '#BB4802' }" class="mr-1"
                        >mdi-cash</v-icon
                      >
                      Tambahan
                    </div>
                  </tr>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="item.status"
                      :readonly="isHandovered"
                      color="success"
                    ></v-checkbox>
                    <v-tooltip bottom v-if="!isHandovered">
                      <template #activator="{on: tooltip}">
                        <v-btn v-on="{ ...tooltip }" icon>
                          <v-icon @click="openDialog('medtool', item)">
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Ubah alkes</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </skeleton>
          </v-card>
        </v-tab-item>
        <v-tab-item key="drugReview" class="mb-10">
          <DrugReview
            :nonmix="nonmix"
            :mix_recipe="mix"
            :medtool="medtools"
            :patient="patient.status"
            :is_request="false"
            @reload-recipe="getPatientPharmacyData()"
            @close-form="$emit('close-form')"
            @show-snackbar="$emit('show-snackbar')"
            @finish="handleFinish"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-card-actions
      v-if="tab === 0"
      class="action-layout pt-3 mt-10 footer-sticky center-width"
    >
      <v-spacer />
      <div class="action-footer">
        <v-btn
          @click="isRecipeReady ? next() : ''"
          v-if="tab !== 1"
          :color="isRecipeReady ? 'primary' : '#9e9e9e'"
          class="text-capitalize font-weight-bold white--text"
          depressed
        >
          Selanjutnya
        </v-btn>
      </div>
    </v-card-actions>
    <v-dialog persistent v-model="dialog" :max-width="dialogWidth" presistent>
      <DrugEdit
        v-if="dialogType === 'nonmix'"
        :id_pharmacy="patient.id"
        :id_recipe="recipe.id"
        @close-form="closeDialog()"
      />
      <DrugEditMix
        v-if="dialogType === 'mix'"
        :id_pharmacy="patient.id"
        :id_recipe="recipe.id"
        @close-form="closeDialog()"
      />
      <MedToolEdit
        v-if="dialogType === 'medtool'"
        :id_pharmacy="patient.id"
        :id_recipe="recipe.id"
        :item="selectedDrug"
        @close-form="closeDialog()"
      />
    </v-dialog>
    <v-dialog v-model="dialogSummary" :max-width="900" persistent>
      <recipe-summary />
    </v-dialog>
    <v-btn
      depressed
      class="text-none absolute-e-ticket"
      color="primary"
      outlined
      v-if="tab === 1"
      @click="printTicket()"
      >Cetak E-Tiket Obat
    </v-btn>
  </v-card>
</template>

<script>
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
import DrugReview from '../DrugToolsRequest/component/DrugReview';
import printMixin from '@/mixin/printMixin';
import alertMixin from '@/mixin/alertMixin';
import PharmacyQueueMixin from '@/mixin/SocketMixin/PharmacyQueueMixin';
import printDrugsEticket from '@/mixin/printDrugsEticket';
import Skeleton from '../../../SharedComponent/Skeleton.vue';
import { postCallPatient, postSkipPatient } from '@/fetchApi/Pharmacy';
import PharmacyHeader from '../HeaderInfo.vue';
import DrugEdit from '../DrugEdit.vue';
import DrugEditMix from '../DrugEditMix.vue';
import MedToolEdit from '../MedToolEdit.vue';
import RecipeSummary from '../DrugToolsRequest/component/RecipeSummary.vue';
const {
  mapActions: mapActionsHeader,
  mapMutations: mapMutationsHeader,
} = createNamespacedHelpers('HeaderPharmacyStore');

const _ = require('lodash');
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  mixins: [printMixin, printDrugsEticket, alertMixin, PharmacyQueueMixin],
  components: {
    DrugReview,
    Skeleton,
    PharmacyHeader,
    DrugEdit,
    DrugEditMix,
    MedToolEdit,
    RecipeSummary,
  },
  props: {
    patient: Object,
  },
  data() {
    return {
      detailState: false,
      isDetailOpened: false,
      calledByClick: '',
      dataSkip: {},
      speakLoading: false,
      selectQueue: '',
      dialog: false,
      dialogType: '',
      drugList: [],
      tab: null,
      isLoading: false,
      show: false,
      totalPrice: 0,
      selectedDrug: null,
      racikTable: {
        headers: [
          {
            sortable: false,
            text: 'No',
            align: 'start',
            value: 'no',
          },
          { sortable: false, text: 'Nama Obat Racik', value: 'name' },
          { sortable: false, text: 'Jumlah', value: 'mix_quantity' },
          { sortable: false, text: 'Satuan', value: 'packaging' },
          { sortable: false, text: 'Aturan Pakai', value: 'usage' },
          { sortable: false, text: 'Aturan Umum', value: 'general_rule' },
          { sortable: false, text: 'Nama Obat', value: 'drugs' },
          { sortable: false, text: 'Jenis Sediaan', value: 'preparation' },
          { sortable: false, text: 'Dosis', value: 'dosage' },
          {
            sortable: false,
            text: 'Jumlah Digunakan',
            value: 'requested_quantity',
          },
          {
            sortable: false,
            text: 'Jumlah Ditagihkan',
            value: 'quantity',
          },
          { sortable: false, text: 'Diserahkan', value: 'status', width: '6%' },
        ],
        item: [],
      },
      nonRacikTable: {
        headers: [
          { sortable: false, text: 'No', align: 'start', value: 'no' },
          { sortable: false, text: 'Nama Obat', value: 'name' },
          { sortable: false, text: 'Jenis Sediaan', value: 'preparation' },
          { sortable: false, text: 'Dosis', value: 'dosage' },
          { sortable: false, text: 'Jumlah', value: 'quantity' },
          { sortable: false, text: 'Satuan', value: 'unit_dosage' },
          { sortable: false, text: 'Aturan Pakai', value: 'usage' },
          { sortable: false, text: 'Aturan Umum', value: 'general_rule' },
          { sortable: false, text: 'Jumlah Diberikan', value: 'quantity' },
          { sortable: false, text: 'Diserahkan', value: 'status', width: '6%' },
        ],
        item: [],
      },
      consumableTable: {
        headers: [
          {
            sortable: false,
            text: 'No',
            align: 'start',
            value: 'no',
            width: '4%',
          },
          { sortable: false, text: 'Nama Alkes/BHP', value: 'name' },
          {
            sortable: false,
            text: 'Jumlah diberikan',
            value: 'amountGiven',
            width: '10%',
          },
          { sortable: false, text: 'Satuan', value: 'unit', width: '10%' },
          { sortable: false, text: 'Diserahkan', value: 'status', width: '6%' },
        ],
        item: [],
      },
      menuSelected: 1,
      data: [],
    };
  },
  beforeDestroy() {
    this.clearState('recipe');
    window.onbeforeunload = null;
    this.clearStateHeader();
  },
  watch: {
    isDetailOpened: _.debounce(function(val) {
      this.detailState = val;
    }, 1000),
  },
  methods: {
    ...mapMutations(['clearState', 'mapDrugDataToForm']),
    ...mapActions(['resolveGetPharmacyPatientData', 'resolveGetDrugs']),
    ...mapActionsHeader([
      'resolveGetAllergiesPatient',
      'resolveGetPatientData',
    ]),
    ...mapMutationsHeader(['clearStateHeader']),
    async getAllergyPatient() {
      try {
        await this.resolveGetAllergiesPatient(this.patient.id_emr);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async getHeaderInformation() {
      try {
        await this.resolveGetPatientData(this.patient.id_registration);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    openDialog(type, item) {
      this.dialog = true;
      this.dialogType = type;
      if (type === 'medtool') {
        this.selectedDrug = item;
      } else {
        this.mapDrugDataToForm(item.idx);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.dialogType = '';
      this.getPatientPharmacyData();
    },
    async printTicket() {
      const patient = {
        name: this.patientData.name,
        rmNumber: this.patientData.rmNumber,
        birthDate: this.patientData.birthDate,
      };
      const doc = this.printEticket(patient, this.getRecipe.list);
      this.printDocument(doc, {
        docName: 'E-Tiket Obat',
        rmNumber: this.patient.rm_number,
      });
    },
    next() {
      this.tab += 1;
    },
    async getPatientPharmacyData() {
      if (this.patient.id_counter && this.patient.isCalled) {
        this.speakLoading = true;
      }
      this.isLoading = true;
      try {
        await this.resolveGetPharmacyPatientData(this.patient.id).finally(
          () => (this.isLoading = false),
        );
        await this.resolveGetDrugs('handover');
      } catch (error) {
        this.isLoading = false;
        this.showErrorAxios(error);
      }
    },
    async handleCallPatient() {
      this.speakLoading = true;
      let payload = {
        id_counter: this.patient.id_counter,
        counter: this.patient.counter,
        id_pharmacy: this.patient.id,
        queue: this.patient.queue_number,
        status: this.patient.status,
      };
      const res = await postCallPatient(payload);
      this.calledByClick = res.data.data.status;
      this.emitMessageCall();
      payload.name = this.patient.name;
      this.emitMessageCallPatient(payload);
      this.patient.status = 'Panggilan';
    },
    handleToDetail() {
      window.open('/antrean-farmasi', '_blank');
    },
    async handleSkipPatient() {
      const payload = {
        id_counter: this?.patient?.id_counter,
        queue: this?.patient?.queue_number,
        id_pharmacy: this?.patient?.id,
      };
      await postSkipPatient(payload);
      this.emitMessageCall();
      this.$emit('close-form');
      this.$emit('show-snackbar-skip', this.patient.queue_number);
    },

    handleCloseForm() {
      if (
        this.patient.status !== 'Sudah diserahkan' &&
        this.patient.status !== 'Belum diserahkan' &&
        this.patient.id_counter !== undefined &&
        this.patient.date === moment().format('YYYY-MM-DD')
      ) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px; font-style: normal; text-transform: none !important;">Antrean Akan Dilewati</div>`,
          iconHtml: '<span class="mdi mdi-alert-circle-outline"></span>',
          html: `<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;">Jika anda keluar, No antrean ${this.patient.queue_number} atas nama pasien ${this.patient.name} akan dilewati</div>`,
          showCancelButton: true,
          reverseButtons: false,
          customClass: {
            confirmButton: 'button-cancel-red-outline',
            cancelButton: 'button-confirm',
          },
          confirmButtonText: `Keluar`,
          cancelButtonText: `Kembali`,
        }).then(result => {
          if (result.isConfirmed) {
            this.handleSkipPatient();
          }
        });
      } else {
        this.$emit('close-form');
      }
    },
    handleDisable() {
      return this.speakLoading;
    },

    handleFinish() {
      window.onpopstate = null;
    },
  },
  created() {
    window.onbeforeunload = null;
    const dataSkip = {
      id_counter: this?.patient?.id_counter,
      queue: this?.patient?.queue_number,
      id_pharmacy: this?.patient?.id,
    };
    if (this.patient.isCalled) {
      window.onpopstate = () => {
        const handleSkipPatient = () => {
          const payload = dataSkip;
          postSkipPatient(payload);
          this.emitMessageCall();
        };
        handleSkipPatient();
      };
      window.onbeforeunload = async e => {
        e.preventDefault();
        const handleSkipPatient = async () => {
          const payload = dataSkip;
          await postSkipPatient(payload);
          this.emitMessageCall();
        };
        return await handleSkipPatient();
      };
    }
  },
  mounted() {
    this.calledByClick = this.patient.status;
    this.$nextTick(() => {
      this.getPatientPharmacyData();
    });
    this.listenMessageLoadingCallPatient();
    this.listenMessageCheck(data => {
      if (data === false) {
        this.emitMessageCheck(true);
        this.isDetailOpened = false;
      } else {
        this.isDetailOpened = data;
      }
    });
    if (this.patient.status !== 'Panggilan') {
      this.isDetailOpened = !this.speakLoading;
    }
    this.getAllergyPatient();
    this.getHeaderInformation();
  },
  computed: {
    ...mapGetters(['getPatientData', 'getRecipe', 'getMedTools', 'getDialog']),
    medtools: {
      get() {
        return this.getMedTools.item;
      },
    },
    dialogSummary: {
      get() {
        return this.getDialog.recipeSummary;
      },
    },
    dialogWidth() {
      if (this.dialogType !== 'medtool') {
        return '60%';
      } else {
        return '30%';
      }
    },
    nonmix: {
      get() {
        return this.getRecipe?.list?.filter(drug => !drug.isRacik);
      },
    },
    mix: {
      get() {
        return this.getRecipe?.list?.filter(drug => drug.isRacik);
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    recipe: {
      get() {
        return this.getRecipe;
      },
    },
    isRecipeReady() {
      return !this.isHandovered
        ? this.nonmix
            .concat(this.mix)
            .concat(this.medtools)
            .every(item => item?.status)
        : true;
    },
    isHandovered() {
      return this.patient.status === 'Sudah diserahkan';
    },
    isEnable: {
      get() {
        return (
          this.patient.status !== 'Sudah diserahkan' &&
          this.patient.unit.toLowerCase() !== 'inpatient' &&
          this.patient.unit.toLowerCase() !== 'igd' &&
          this.patient.date === moment().format('YYYY-MM-DD')
        );
      },
    },
  },
};
</script>

<style>
.button-cancel-red-outline {
  background-color: white !important;
  border: 1px solid #eb4747 !important;
  border-radius: 4px !important;
  color: #eb4747 !important;
}
</style>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  position: fixed;
  top: 150px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.sticky-up {
  top: 110px !important;
}

.after-sticky-up {
  margin-top: 220px !important;
}

.footer-sticky {
  width: 100%;
  position: fixed;
  bottom: -30px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #ffffff !important;
}

.after-sticky {
  margin-top: 300px;
}

.before-sticky {
  margin-bottom: 70px;
}

.absolute-e-ticket {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  left: 4%;
}

.header,
.header-2 {
  display: flex;
  list-style-type: none;
}

.detail {
  width: 100%;
  height: 100%;

  .container {
    max-width: 1280px;
  }
}

.allergy {
  background-color: #fff2f2 !important;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  border: 0.5px solid #eb4747 !important;
  color: #eb4747;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    justify-content: flex-start;
    flex: 1;
    display: flex;
    color: #222;
  }
}

.body-container {
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.header-layout {
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: auto;
    color: #9aa0a4;
    cursor: pointer;

    &:hover {
      color: rgb(207, 15, 15);
    }
  }
  .row-info {
    height: 100%;

    .header-info-widget {
      .info-head {
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 0.9rem;
        // padding-right: 50px;
      }

      .label-head {
        text-align: left;
        font-size: 0.9rem;
        font-weight: normal;
        font-family: Roboto;
        color: #949a9d;
        font-style: normal;

        &.special-label {
          max-width: 400px;
          white-space: normal;
          padding-left: 10px;
          padding-right: 15px;
        }

        &.red-color {
          font-weight: normal;
          color: red;
        }
      }
    }
  }
}

::v-deep {
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f4f5f5;
  }

  .v-data-table-header {
    background-color: #f4f5f5;
  }
}

.total-price {
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  min-width: 30%;
  padding: 0.8rem;
  color: #3f484a;
  display: flex;
  align-items: center;
  background-color: #f0f9ff;
}

.title {
  text-align: left;
  font-size: 16px !important;
  color: #137bc0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.center-width {
  padding: 0 1vw;
}

.action-layout {
  .action-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    .btn-create {
      margin: 0 20px;
      min-width: 145px;
      height: 40px;
      font-weight: 700;
      background-color: #3498db !important;
      color: white;
      outline: 2px solid #349cdc;
      border: 2px solid #349cdc;
    }
  }
}

.addon-chip {
  background-color: #fffbea;
  padding: 5px;
  color: #bb4802;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: black;
}
</style>
