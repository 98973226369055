import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSocketIO from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import moment from 'moment-timezone';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import VueKonva from 'vue-konva';
import './sass/main.scss';
import './sass/variables.scss';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faSyncAlt,
  faUser,
  faNotesMedical,
  faSyringe,
  faPrescriptionBottle,
  faFlask,
  faPrint,
  faCalendarAlt,
  faClipboardCheck,
  faGlasses,
  faFileSignature,
  faLongArrowAltUp,
  faLongArrowAltDown,
} from '@fortawesome/free-solid-svg-icons';
import VueMask from 'v-mask';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
const apiUrl = window.env?.VUE_APP_API_URL || process.env.VUE_APP_API_URL;

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.config.productionTip = false;
library.add(
  faHome,
  faSyncAlt,
  faUser,
  faNotesMedical,
  faSyringe,
  faPrescriptionBottle,
  faFlask,
  faPrint,
  faClipboardCheck,
  faCalendarAlt,
  faGlasses,
  faFileSignature,
  faLongArrowAltUp,
  faLongArrowAltDown,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueKonva);
Vue.use(VueMask);
Vue.use(
  VueSocketIO,
  io(apiUrl, {
    auth: { token: localStorage.getItem('token') },
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    maxReconnectionAttempts: Infinity,
    closeOnBeforeunload: false,
  }),
  {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_',
  },
);
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'id',
  decimalLength: 0,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

moment.tz.setDefault('Asia/Jakarta');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
