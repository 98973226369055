import Constant from '@/const';
const axios = require('axios');

const postAddDrugMargin = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/setting/drug-margin'),
      payload,
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postAddDrugMargin;
