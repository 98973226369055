<template>
  <v-container class="px-4 py-2" fluid>
    <div class="mb-7 d-flex justify-space-between pr-8">
      <div class="d-flex text-start align-center">
        <h1>Margin dan Harga</h1>
        <span class="chip mx-3">Global Setting</span>
        <span class="grey-light-text">
          Harga dan margin diatur untuk semua item sekaligus.
        </span>
      </div>
      <v-btn
        color="primary"
        class="btn-edit text-capitalize"
        outlined
        depressed
        dense
        @click="openEditSetting"
      >
        Ubah Pengaturan <v-icon class="ml-2">mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div class="display-price-container mb-8">
      <display-price-card :index="0" v-bind="{ ...displayPrice?.[0] }" />
      <display-price-card :index="1" v-bind="{ ...displayPrice?.[1] }" />
      <display-price-card :index="2" v-bind="{ ...displayPrice?.[2] }" />
    </div>
    <div class="d-flex justify-space-between">
      <span class="grey-dark-text">PREVIEW PERHITUNGAN</span>
      <span class="grey-light-text pr-6">
        Base Harga: <strong class="black--text">HNA</strong>
      </span>
    </div>
    <!-- TABLE -->
    <div class="grid-display py-3">
      <span
        v-for="(item, index) in tableHeaders"
        :key="'header-' + index"
        :class="item.class + ' font-weight-medium'"
      >
        {{ item.text }}
      </span>
    </div>
    <v-progress-linear
      indeterminate
      v-if="getLoadingState.previewTable"
    ></v-progress-linear>
    <div class="data-table-container mb-4 py-2 px-3">
      <div
        v-for="(item, index) in table.data"
        :key="'data-' + index"
        class="grid-display row py-3"
      >
        <span class="text-start">{{ item.name }}</span>
        <span class="text-start">{{ item.category }}</span>
        <span class="text-end">{{ currency(item.hna) }}</span>
        <span class="text-end">{{ currency(item.hpp) }}</span>
        <span class="text-start">{{ item.margin }}%</span>
        <span class="text-start">{{ item.ppn }}%</span>
        <span class="text-end">{{ currency(item.price1) }}</span>
        <span class="text-end">{{ currency(item.price2) }}</span>
        <span class="text-end pr-3">{{ currency(item.price3) }}</span>
      </div>
      <div v-if="!table.data.length" class="grey--text">Tidak ada data</div>
    </div>
    <footer class="d-flex justify-start">
      <v-pagination
        light
        v-if="table.data.length"
        @input="getDataPreview"
        v-model="pagination.page"
        :length="pagination.pageLength"
        :total-visible="pagination.limit"
      ></v-pagination>
    </footer>
  </v-container>
</template>

<script>
import DisplayPriceCard from '../DisplayPriceCard.vue';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

import formatMixin from '@/mixin/formatMixin';

export default {
  name: 'GlobalSettingDashboard',
  mixins: [formatMixin],
  components: { DisplayPriceCard },
  data() {
    return {
      pagination: {
        page: 1,
        limit: 10,
        pageLength: 10,
      },
      table: {
        data: [],
      },
    };
  },
  mounted() {
    this.getDataPreview();
  },
  methods: {
    ...mapMutations([
      'setSelectedForm',
      'setDialog',
      'setIsEdit',
      'setEditedDataFromServer',
    ]),
    ...mapActions(['resolveGetPreviewCalculation']),
    openEditSetting() {
      this.setSelectedForm(1);
      this.setIsEdit(true);
      this.setDialog(true);
    },
    async getDataPreview() {
      // try {
      const itemCount = 9;
      const params = {
        page: this.pagination.page,
        itemCount,
      };

      const { dataLength } = await this.resolveGetPreviewCalculation(params);
      this.pagination.pageLength = Math.ceil(dataLength / itemCount);

      const calculatePrice = (hna, index) => {
        if (!this.getDataFromServer.nondrugs[0].margins?.[index]?.isActive)
          return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice *
            this.getDataFromServer.nondrugs[0].margins?.[index]?.margin) /
          100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin *
            this.getDataFromServer.nondrugs[0].margins?.[index]?.ppn) /
          100;
        return priceAfterMargin + taxAmount;
      };

      this.table.data = this.getPreviewData.map(item => {
        return {
          name: item.name,
          category: item.category,
          hna: item.hna,
          hpp: item.hpp,
          margin: this.getDataFromServer.nondrugs[0].margins[0].margin,
          ppn: this.getDataFromServer.nondrugs[0].margins[0].ppn,
          price1: calculatePrice(item.hna, 0),
          price2: calculatePrice(item.hna, 1),
          price3: calculatePrice(item.hna, 2),
        };
      });
      // }
      //  catch (e) {
      //   console.log(e);
      // }
    },
  },
  computed: {
    ...mapGetters([
      'getPriceSetting',
      'getDataFromServer',
      'getPreviewData',
      'getLoadingState',
    ]),
    tableHeaders: {
      get() {
        return [
          {
            text: 'Nama Barang',
            class: 'text-start',
          },
          {
            text: 'Golongan',
            class: 'text-start',
          },
          {
            text: 'HNA',
            class: 'text-end',
          },
          {
            text: 'HPP',
            class: 'text-end',
          },
          {
            text: 'Margin',
            class: 'text-start',
          },
          {
            text: 'PPN',
            class: 'text-start',
          },
        ].concat(
          this.getDataFromServer.nondrugs[0].margins.map((item, index) => ({
            text: item.description || `Harga ${index + 1}`,
            class: `text-end ${index === 2 ? ' pr-4' : ''}`,
          })),
        );
      },
    },
    displayPrice: {
      get() {
        return this.getDataFromServer.nondrugs[0].margins;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  background-color: #f0f8fe;
  color: #1976d2;
  padding: 0.5rem 0.7rem;
  font-size: 0.8vw;
}

h1 {
  font-size: 1.4vw;
}

.btn-edit {
  border-width: 2px;
  letter-spacing: 0.001px;
  padding: 1.1rem 0.5rem !important;
  font-size: 0.9vw;
}

.display-price-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 80%;
  column-gap: 3rem;
  align-items: end;
}

.grid-display {
  display: grid;
  grid-template-columns: 25% 12% 8% 8% 4% 4% repeat(3, calc(29% / 3));
  gap: 1rem;
  font-size: 1rem;
  color: #9e9e9e;
  border-bottom: 2px solid #e0e0e0;
  font-size: 0.9rem;

  &.row {
    border-bottom: 2px solid #ededed;
    color: #404040;
  }
}

.data-table-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 45vh;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
}

.grey-dark-text {
  color: #616161;
}

.grey-light-text {
  color: #9e9e9e;
}
</style>
