<template>
  <v-card class="pa-5 main-wrapper">
    <header-form
      title="Setting Margin dan Harga"
      chip="Global + Kategori Setting"
      description="Atur harga dan margin secara global untuk item, atau sesuaikan langsung berdasarkan kategori."
      confirmationButtonIcon="mdi-arrow-right"
      confirmationButtonText="Lanjut"
      :isEnableConfirmButton="getIsEnableConfirmationBtn"
      @back="back"
      @confirm="next"
    />
    <div class="input-container">
      <div>
        <div class="d-flex justify-space-between">
          <span class="grey-text-light">HARGA DEFAULT</span>
          <span class="grey-text-light" style="color: #9e9e9e"
            >Base Harga: <strong style="color: black">HNA</strong></span
          >
        </div>
        <input-price-card
          ref="card1"
          @on-change-input="type => onUserType(type, 0)"
          :index="0"
          isDefault
        />
      </div>
      <div class="text-start">
        <span class="grey-light-text">
          MULTIPLE HARGA (OPSIONAL)
        </span>
        <input-price-card
          ref="card2"
          :index="1"
          @on-change-input="type => onUserType(type, 1)"
        />
      </div>
      <input-price-card
        ref="card3"
        :index="2"
        @on-change-input="type => onUserType(type, 2)"
      />
    </div>
    <v-text-field
      placeholder="Cari barang"
      v-if="isGlobalSpecific"
      outlined
      dense
      single-line
      v-model="search"
      append-icon="mdi-magnify"
    />
    <div class="text-start">
      <span class="grey-light-text">
        PREVIEW PERHITUNGAN
      </span>
      <div :class="`${isGlobalSpecific ? 'specific ' : ''}grid-display row`">
        <span
          v-for="(item, index) in displayedHeaderTable.filter(i => i)"
          :key="'table-header-' + index"
          :class="item.class"
          >{{ item.text }}</span
        >
      </div>
      <v-progress-linear
        indeterminate
        v-if="getLoadingState.previewTable"
      ></v-progress-linear>
      <div
        class="table-container"
        id="scroll-target"
        v-scroll:#scroll-target="onScroll"
      >
        <div
          v-for="(item, index) in table.data"
          :key="'row-' + index"
          :class="`${isGlobalSpecific ? 'specific ' : ''}grid-display row`"
        >
          <span class="text-start">{{ item.name }}</span>
          <span class="text-start chip" v-if="isGlobalSpecific">{{
            item.setting
          }}</span>
          <span class="text-start">{{ item.category }}</span>
          <span class="text-end">{{ currency(item.hna) }}</span>
          <span class="text-end">{{ currency(item.hpp) }}</span>
          <span class="text-start">{{ item.margin }}%</span>
          <span class="text-start">{{ item.ppn }}%</span>
          <span class="text-end">{{ currency(item.price1) }}</span>
          <span class="text-end">{{ currency(item.price2) }}</span>
          <span class="text-end">{{ currency(item.price3) }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import HeaderForm from '../HeaderForm.vue';
import InputPriceCard from '../GlobalSetting/InputPriceCard.vue';
import { createNamespacedHelpers } from 'vuex';
import Swal from 'sweetalert2';

import formatMixin from '@/mixin/formatMixin';

const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);
const _ = require('lodash');
export default {
  components: { HeaderForm, InputPriceCard },
  mixins: [formatMixin],
  name: 'GlobalCategorySettingDialog',
  data() {
    return {
      search: '',
      table: {
        data: [],
        pagination: { page: 1 },
      },
    };
  },
  mounted() {
    this.getDataPreview();
  },
  beforeDestroy() {
    this.clearPriceState();
  },
  watch: {
    search() {
      this.searchData(this);
    },
  },
  computed: {
    ...mapGetters([
      'getIsEnableConfirmationBtn',
      'getPriceSetting',
      'getPreviewData',
      'getSelectedSetting',
      'getLoadingState',
    ]),
    hasFormChanged: {
      get() {
        return (
          this.getPriceSetting[0].margin !== '' ||
          this.getPriceSetting[0].ppn !== '' ||
          this.getPriceSetting[0].description !== '' ||
          this.getPriceSetting[1].isActive ||
          this.getPriceSetting[2].isActive
        );
      },
    },
    displayedHeaderTable: {
      get() {
        return this.tableHeader.concat(
          ...this.getPriceSetting.map((item, index) => ({
            text: item.description || `Harga ${index + 1}`,
            class: 'text-end',
          })),
          this.isGlobalSpecific ? { text: '', value: 'text-start' } : null,
        );
      },
    },
    tableHeader: {
      get() {
        return this.isGlobalSpecific
          ? [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Pengaturan Harga',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ]
          : [
              {
                text: 'Nama Barang',
                class: 'text-start',
              },
              {
                text: 'Golongan',
                class: 'text-start',
              },
              {
                text: 'HNA',
                class: 'text-end',
              },
              {
                text: 'HPP',
                class: 'text-end',
              },
              {
                text: 'Margin',
                class: 'text-start',
              },
              {
                text: 'PPN',
                class: 'text-start',
              },
            ];
      },
    },
    isGlobalSpecific: {
      get() {
        return this.getSelectedSetting === 'global-specific-setting';
      },
    },
  },
  methods: {
    ...mapMutations([
      'setSelectedForm',
      'setTemporaryStore',
      'clearTemporaryStore',
      'clearPriceState',
      'setPriceSettingDirectly',
    ]),
    onScroll() {
      if (this.isGlobalSpecific) return;
      const el = document.getElementById('scroll-target');
      if (el) {
        if (el.scrollTop + el.clientHeight + 3 >= el.scrollHeight) {
          this.table.pagination.page += 1;
          this.getDataPreview(true); // 'true' value stands for isScrollEvent parameter (see function declaration)
        }
      }
    },
    searchData: _.debounce(v => {
      v.getDataPreview();
    }, 600),
    ...mapActions(['resolveGetPreviewCalculation']),
    async getDataPreview(isScrollEvent) {
      const params = {
        page: isScrollEvent ? this.table.pagination.page : 1,
        itemCount: this.isGlobalSpecific ? 12 : 10,
        search: this.search || '',
      };
      const { mappedData } = await this.resolveGetPreviewCalculation(params);
      if (isScrollEvent) {
        this.table.data = this.table.data.concat(
          ...mappedData.map(item => ({ ...item, setting: 'Global' })),
        );
      } else {
        this.table.pagination.page = 1;
        this.table.data = this.getPreviewData.map(item => ({
          ...item,
          setting: 'Global',
        }));
      }
    },
    onUserType(type, idx) {
      const calculatePrice = (hna, index) => {
        if (!this.getPriceSetting[index]?.isActive) return 0;
        const basePrice = hna;
        const marginAmount =
          (basePrice * +this.getPriceSetting[index].margin) / 100;
        const priceAfterMargin = basePrice + marginAmount;
        const taxAmount =
          (priceAfterMargin * +this.getPriceSetting[index].ppn) / 100;
        return priceAfterMargin + taxAmount;
      };

      switch (type) {
        case 'margin':
        case 'ppn':
          this.table.data = this.table.data.map(item => {
            return {
              ...item,
              [type]: this.getPriceSetting[0][type],
              [`price${idx + 1}`]: calculatePrice(item.hna, idx),
            };
          });
          break;
        case 'description':
          this.table.header = this.table.header.map(item => {
            return {
              ...item,
            };
          });
      }
    },
    back() {
      if (this.hasFormChanged) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Konfirmasi Tindakan</p></div>`,
          iconHtml:
            '<span sstyle="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda belum menyelesaikan pengisian form. Perubahan yang</p><p>belum disimpan akan hilang. Apakah Anda yakin ingin</p><p>membatalkan perubahan?</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Tetap Mengisi Form',
          cancelButtonText: 'Batalkan Perubahan',
        }).then(result => {
          if (result.isConfirmed) {
            return;
          }
          this.setSelectedForm(0);
          this.clearTemporaryStore();
        });
      } else {
        this.setSelectedForm(0);
        this.clearTemporaryStore();
      }
    },
    next() {
      if (!this.getIsEnableConfirmationBtn) return;
      if (
        !this.$refs.card1.$refs.form.validate() ||
        !this.$refs.card2.$refs.form.validate() ||
        !this.$refs.card3.$refs.form.validate()
      ) {
        return;
      } else {
        this.setTemporaryStore(this.getPriceSetting);
        // reset price input
        this.setPriceSettingDirectly([
          {
            isDefault: true,
            margin: '',
            ppn: '',
            description: '',
            isActive: true,
          },
          {
            isDefault: false,
            isActive: false,
            margin: '',
            ppn: '',
            description: '',
          },
          {
            isDefault: false,
            isActive: false,
            margin: '',
            ppn: '',
            description: '',
          },
        ]);
        this.setSelectedForm(3);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  position: relative;
  overflow: auto;
  height: 90vh;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .input-container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 2vw;
    align-items: end;
  }

  .grid-display {
    display: grid;
    grid-template-columns: 28% 6% repeat(2, 7%) 5% 8% repeat(3, 10%);
    column-gap: 1%;
    padding: 0.5vw 0;
    border-bottom: 2px solid #e0e0e0;
    color: #9e9e9e;
    font-size: 0.85vw;

    &.specific {
      grid-template-columns: 24% 10% 4% repeat(2, 7%) 5% 4% repeat(3, 7%) 6%;
    }

    &.row {
      border-bottom: 1px solid #e0e0e0;
      color: #404040;
      padding: 0.5vw 0.8vw;
      font-size: 0.8vw;

      .chip {
        background-color: #f0f8fe;
      }
    }
  }

  .table-container {
    height: 45vh;
    padding-top: 1.1vw;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #757575;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
}
</style>
