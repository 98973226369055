<template>
  <v-card flat style="position: relative;">
    <div
      v-if="isInpatientEmr && disabled"
      style="height: 77vh;"
      class="d-flex align-center justify-center"
    >
      <h4 style="color: #757575" class="font-weight-regular">
        General Consent atas nama pasien {{ socialData.name }} <br />
        belum dibuat di bagian pendaftaran rawat inap
      </h4>
    </div>
    <template v-else>
      <v-card-title v-if="!isInpatientEmr">
        <v-row v-if="!isFromServiceDashboard" class="mb-4 pl-10">
          <v-col cols="2" class="text-left d-flex">
            <skeleton :isLoading="loading.social" class="mr-3">
              <label class="body-2 grey--text mr-2">No. RM</label>
            </skeleton>
            <skeleton :isLoading="loading.social">
              <label class="body-2 black--text font-weight-bold">{{
                rmNumber
              }}</label>
            </skeleton>
          </v-col>
          <v-col cols="1" class="text-right" style="max-width: 70px">
            <skeleton :isLoading="loading.social">
              <label class="body-2 grey--text">Nama</label>
            </skeleton>
          </v-col>
          <v-col cols="2" class="text-left">
            <skeleton :isLoading="loading.social">
              <label class="body-2 black--text font-weight-bold"
                >{{ socialData?.name }} ({{
                  socialData.gender ? 'L' : 'P'
                }})</label
              >
            </skeleton>
          </v-col>
          <v-col cols="2" class="text-right" style="max-width: 120px">
            <skeleton :isLoading="loading.social">
              <label class="body-2 grey--text">Tanggal Lahir</label>
            </skeleton>
          </v-col>
          <v-col cols="2" class="text-left">
            <skeleton :isLoading="loading.social">
              <label class="body-2 black--text font-weight-bold"
                >{{ socialData.birthDate }} ({{ socialData.age }} tahun)</label
              ></skeleton
            >
          </v-col>
          <v-col class="text-right">
            <h3>Pembuatan General Consent {{ serviceType }}</h3>
          </v-col>
          <v-col cols="1" class="pt-0" style="max-width: 50px">
            <v-btn icon @click="onCloseButtonClick()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex" v-else>
          <h4 class="pl-13 mr-3">Formulir General Consent</h4>
          <v-chip :class="`chip-${input.status.class}`">{{
            input.status.text
          }}</v-chip>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          :class="
            isFromServiceDashboard || isInpatientEmr
              ? 'card-layout-service'
              : 'card-layout'
          "
        >
          <v-form lazy-validation ref="form">
            <v-row class="pa-5">
              <v-col cols="6">
                <h2 class="text-left pl-5 primary--text mb-2">
                  Identitas
                </h2>
                <div class="pl-5">
                  <v-row class="align-center pt-2">
                    <v-col cols="4" class="text-left">
                      <skeleton :isLoading="loading.data || loading.social">
                        <label class="black--text">Yang bertandatangan</label>
                      </skeleton>
                    </v-col>
                    <v-col>
                      <skeleton :isLoading="loading.data || loading.social">
                        <v-radio-group
                          :readonly="disableState"
                          row
                          class="pt-0 mt-0"
                          v-model="input.signer"
                          hide-details="false"
                        >
                          <v-radio label="Pasien" value="pasien"></v-radio>
                          <v-radio label="Wali" value="wali"></v-radio>
                        </v-radio-group>
                      </skeleton>
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col cols="4" class="text-left">
                      <skeleton :isLoading="loading.data || loading.social">
                        <label class="required black--text"
                          >Nama
                          {{
                            input.signer === 'pasien' ? 'Pasien' : 'Wali'
                          }}</label
                        ></skeleton
                      >
                    </v-col>
                    <v-col cols="5">
                      <skeleton :isLoading="loading.data || loading.social">
                        <v-text-field
                          :readonly="disableState"
                          placeholder="Nama Lengkap"
                          validate-on-blur
                          :rules="[v => !!v || 'Wajib Diisi']"
                          dense
                          class="mt-0 pt-0"
                          v-model="input.name"
                        ></v-text-field
                      ></skeleton>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-left">
                      <skeleton :isLoading="loading.data || loading.social">
                        <label class="black--text">Alamat Lengkap</label>
                      </skeleton>
                    </v-col>
                    <v-col cols="6">
                      <skeleton :isLoading="loading.data || loading.social">
                        <v-textarea
                          :readonly="disableState"
                          placeholder="Masukkan alamat di sini"
                          style="width: 22rem;"
                          outlined
                          rows="5"
                          v-model="input.address"
                          hide-details="false"
                        ></v-textarea>
                      </skeleton>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="6">
                <v-row class="align-center pt-9">
                  <v-col cols="4" class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text">Nomor Telepon</label>
                    </skeleton>
                  </v-col>
                  <v-col cols="5">
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-text-field
                        :readonly="disableState"
                        placeholder="No Telp"
                        dense
                        type="number"
                        class="mt-0 pt-0"
                        v-model="input.telp"
                        hide-details="false"
                      ></v-text-field
                    ></skeleton>
                  </v-col>
                </v-row>
                <v-row class="align-center mb-5">
                  <v-col cols="4" class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >No. Identitas/KTP/SIM</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col cols="5">
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-text-field
                        :readonly="disableState"
                        placeholder="No. Identitas/KTP/SIM"
                        dense
                        class="mt-0 pt-0"
                        v-model="input.identityNumber"
                        hide-details="false"
                      ></v-text-field
                    ></skeleton>
                  </v-col>
                </v-row>
                <v-divider style="width: 74%" class="mb-5"></v-divider>
                <v-row class="align-center mb-5">
                  <v-col cols="4" class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text required"
                        >Petugas Pemberi Penjelasan</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col cols="5">
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-combobox
                        :readonly="disableState"
                        :loading="loading.staff"
                        :rules="[v => !!v || 'Wajib Diisi']"
                        :clearable="!disableState"
                        placeholder="Nama Petugas"
                        :items="resource.staff"
                        item-text="text"
                        append-icon="mdi-chevron-down"
                        dense
                        :search-input.sync="searchStaff"
                        class="mt-0 pt-0"
                        v-model="input.staff"
                      ></v-combobox
                    ></skeleton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="pa-5">
            <v-col>
              <h2 class="text-left primary--text pl-5 mb-4">
                Persetujuan Pasien
              </h2>
              <div class="pl-5">
                <!-- persetujuan layanan -->
                <h3 class="black--text text-left">
                  Persetujuan Pelayanan
                </h3>
                <v-row class="align-center pt-2 mb-1">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien menyetujui atas pelayanan yang akan
                        diberikan oleh klinik?
                      </label>
                    </skeleton>
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        v-model="input.patientAgreement.patientConsentService"
                        :readonly="disableState"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Ya" :value="true"></v-radio>
                        <v-radio label="Tidak" :value="false"></v-radio>
                      </v-radio-group>
                    </skeleton>
                  </v-col>
                </v-row>
                <!-- ketentuan pembayaran -->
                <h3 class="black--text text-left">
                  Ketentuan Pembayaran
                </h3>
                <v-row class="align-center pt-2 mb-1">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien menyetujui atas ketentuan pembayaran yang
                        telah ditentukan oleh klinik?</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        v-model="input.patientAgreement.patientConsentPayment"
                        :readonly="disableState"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Setuju" :value="true"></v-radio>
                        <v-radio
                          label="Tidak Setuju"
                          :value="false"
                        ></v-radio> </v-radio-group
                    ></skeleton>
                  </v-col>
                </v-row>
                <!-- hak dan kewajiban pasien -->
                <h3 class="black--text text-left mb-1">
                  Hak dan Kewajiban Pasien
                </h3>
                <v-row class="align-center pt-2 mb-1">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien menyetujui atas hak dan kewajiban pasien
                        yang telah ditentukan oleh klinik?</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        v-model="input.patientAgreement.patientRightsObligation"
                        :readonly="disableState"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Setuju" :value="true"></v-radio>
                        <v-radio
                          label="Tidak Setuju"
                          :value="false"
                        ></v-radio> </v-radio-group
                    ></skeleton>
                  </v-col>
                </v-row>
                <!-- Tata Tertib Klinik -->
                <h3 class="black--text text-left">
                  Tata Tertib Klinik
                </h3>
                <v-row class="align-center pt-2 mb-1">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien menyetujui atas tata tertib klinik yang
                        telah ditentukan oleh klinik?</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        v-model="input.patientAgreement.patientTermCondition"
                        :readonly="disableState"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Setuju" :value="true"></v-radio>
                        <v-radio
                          label="Tidak Setuju"
                          :value="false"
                        ></v-radio> </v-radio-group
                    ></skeleton>
                  </v-col>
                </v-row>
                <!-- Kebutuhan Penerjemah Bahasa -->
                <h3 class="black--text text-left">
                  Kebutuhan Penerjemah Bahasa
                </h3>
                <v-row class="align-center pt-2 mb-1">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien membutuhkan penerjemah bahasa?</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        :readonly="disableState"
                        v-model="input.patientAgreement.patientTranslatorBahasa"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Ya" :value="true"></v-radio>
                        <v-radio
                          label="Tidak"
                          :value="false"
                        ></v-radio> </v-radio-group
                    ></skeleton>
                  </v-col>
                </v-row>
                <!-- Kebutuhan Rohaniawan -->
                <h3 class="black--text text-left">
                  Kebutuhan Rohaniawan
                </h3>
                <v-row class="align-center pt-2">
                  <v-col class="text-left">
                    <skeleton :isLoading="loading.data || loading.social">
                      <label class="black--text"
                        >Apakah pasien membutuhkan rohaniawan?</label
                      ></skeleton
                    >
                  </v-col>
                  <v-col>
                    <skeleton :isLoading="loading.data || loading.social">
                      <v-radio-group
                        v-model="input.patientAgreement.patientRohaniawanNeeds"
                        :readonly="disableState"
                        row
                        hide-details="false"
                        class="mt-0 pt-0"
                      >
                        <v-radio label="Ya" :value="true"></v-radio>
                        <v-radio
                          label="Tidak"
                          :value="false"
                        ></v-radio> </v-radio-group
                    ></skeleton>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col class="pt-10">
              <!-- Pelepasan Informasi -->
              <h3 class="black--text text-left mb-3">
                Pelepasan Informasi
              </h3>
              <v-row class="align-center pt-2">
                <v-col class="text-left py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien menyetujui atas ketentuan pelepasan
                      informasi pasien yang telah ditentukan oleh klinik?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      v-model="input.patientAgreement.patientConsentRelease"
                      :readonly="disableState"
                      row
                      hide-details="false"
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Setuju" :value="true"></v-radio>
                      <v-radio
                        label="Tidak Setuju"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col class="text-left py-2">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien menyetujui atas pengiriman data ke
                      SatuSehat?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      v-model="input.patientAgreement.satuSehatAgreement"
                      :readonly="disableState"
                      row
                      hide-details="false"
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Setuju" :value="true"></v-radio>
                      <v-radio
                        label="Tidak Setuju"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col class="text-left pt-1 pb-2">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien setuju bahwa hasil pemeriksaan penunjang
                      dapat diberikan kepada pihak penjamin?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-3">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      v-model="
                        input.patientAgreement.patientConsentInspectionGuarantor
                      "
                      :readonly="disableState"
                      row
                      hide-details="false"
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Setuju" :value="true"></v-radio>
                      <v-radio
                        label="Tidak Setuju"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col class="text-left py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien setuju bahwa hasil pemeriksaan penunjang
                      dapat diakses oleh peserta didik?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      v-model="
                        input.patientAgreement.patientConsentInspectionLearner
                      "
                      :readonly="disableState"
                      row
                      hide-details="false"
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Setuju" :value="true"></v-radio>
                      <v-radio
                        label="Tidak Setuju"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col class="text-left py-3">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien setuju bahwa informasi pasien dapat
                      diberikan kepada fasyankes tujuan rujukan?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-3">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      v-model="
                        input.patientAgreement
                          .patientConsentInformationFasyankes
                      "
                      :readonly="disableState"
                      row
                      hide-details="false"
                      class="mt-0 pt-0"
                    >
                      <v-radio label="Setuju" :value="true"></v-radio>
                      <v-radio
                        label="Tidak Setuju"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <v-row class="align-center mb-2">
                <v-col class="text-left py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah ada orang lain yang berhak mendapatkan informasi
                      pasien?</label
                    ></skeleton
                  >
                </v-col>
                <v-col class="py-0">
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      row
                      :readonly="disableState"
                      hide-details="false"
                      class="pt-0 mt-0"
                      v-model="input.restrictedInfo"
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio
                        label="Tidak"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>
              <template v-if="input.restrictedInfo">
                <v-row
                  v-for="(x, index) in input.visitorAllowed"
                  :key="'nameLabel-' + index"
                  class="mb-2"
                >
                  <v-col cols="2" class="text-left pb-0">
                    <label class="black--text font-weight-bold">Nama</label>
                  </v-col>
                  <v-col cols="4" class="pt-2 pb-0" :key="'name-' + index">
                    <v-text-field
                      :readonly="disableState"
                      placeholder="Nama"
                      dense
                      class="mt-0 pt-0"
                      v-model="input.visitorAllowed[index].name"
                      @keyup="addVisitor($event, index)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    class="text-left pb-0"
                    :key="'relationLabel-' + index"
                  >
                    <label class="black--text font-weight-bold">Hubungan</label>
                  </v-col>
                  <v-col cols="4" class="pt-2 pb-0" :key="'relation-' + index">
                    <v-combobox
                      :readonly="disableState"
                      placeholder="Hubungan"
                      append-icon="mdi-chevron-down"
                      dense
                      v-model="input.visitorAllowed[index].relation"
                      class="mt-0 pt-0"
                      :items="resource.familyStatus"
                      item-text="text"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </template>
              <!-- Privasi -->
              <h3 class="black--text text-left">
                Privasi
              </h3>
              <v-row class="align-center pt-2">
                <v-col class="text-left">
                  <skeleton :isLoading="loading.data || loading.social">
                    <label class="black--text"
                      >Apakah pasien/wali mengizinkan pasien untuk dijenguk oleh
                      keluarga/kerabat?</label
                    ></skeleton
                  >
                </v-col>
                <v-col>
                  <skeleton :isLoading="loading.data || loading.social">
                    <v-radio-group
                      row
                      :readonly="disableState"
                      hide-details="false"
                      class="mt-0 pt-0"
                      v-model="input.privacy"
                    >
                      <v-radio label="Ya" :value="true"></v-radio>
                      <v-radio
                        label="Tidak"
                        :value="false"
                      ></v-radio> </v-radio-group
                  ></skeleton>
                </v-col>
              </v-row>

              <template v-if="input.privacy">
                <v-row
                  :key="'privacyLabel-' + index"
                  v-for="(_, index) in input.person"
                  class="align-center"
                >
                  <v-col class="text-left" cols="6">
                    <label class="black--text font-weight-bold"
                      >Tuliskan nama orang yang tidak diberi izin menjenguk
                      pasien</label
                    >
                  </v-col>
                  <v-col class="pt-0 pb-0" :key="'person-' + index" cols="6">
                    <v-text-field
                      placeholder="Nama"
                      :readonly="disableState"
                      class="mt-0 pt-0"
                      hide-details="false"
                      v-model="input.person[index]"
                      @keyup="addPerson($event, index)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer v-if="printOnlyState"></v-spacer>
        <v-btn
          class="text-none"
          :outlined="!printOnlyState"
          v-if="!isEditing"
          color="primary"
          style="letter-spacing: 0.01px"
          depressed
          :loading="loading.save || loading.data || loading.social"
          @click="printConsent"
          :disabled="disabled"
        >
          <font-awesome-icon icon="print" class="mr-1" />
          Print
        </v-btn>
        <template v-if="!printOnlyState">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isFromServiceDashboard && !isDetail"
            class="text-none px-4"
            color="primary"
            style="letter-spacing: 0.01px"
            depressed
            @click="addGeneralConsent()"
            :loading="loading.save || loading.data || loading.social"
            >Simpan
          </v-btn>
          <v-btn
            v-if="!isFromServiceDashboard && isDetail && !isEditing"
            class="text-none px-4"
            color="primary"
            style="letter-spacing: 0.01px"
            depressed
            @click="() => (isEditing = true)"
            >Ubah
          </v-btn>
        </template>
        <div
          class="d-flex"
          v-if="isDetail && !isFromServiceDashboard && isEditing"
        >
          <v-btn
            v-if="!isFromServiceDashboard && isEditing"
            class="text-none px-4"
            color="error"
            style="letter-spacing: 0.01px"
            depressed
            outlined
            :loading="loading.save || loading.data || loading.social"
            @click="() => (isEditing = false)"
            >Batal
          </v-btn>
          <v-btn
            v-if="!isFromServiceDashboard && isEditing"
            class="text-none px-4 ml-3"
            color="primary"
            :loading="loading.save || loading.data || loading.social"
            style="letter-spacing: 0.01px"
            depressed
            @click="updateGeneralConsent"
            >Simpan Perubahan
          </v-btn>
        </div>
      </v-card-actions>
      <v-snackbar
        timeout="4000"
        content-class="d-flex justify-space-between white--text align-center"
        elevation="0"
        small
        light
        absolute
        :color="snackbar.color"
        v-model="snackbar.show"
      >
        {{ snackbar.text }}
        <v-btn fab small text @click.native="() => (snackbar.show = false)"
          ><v-icon color="white" small>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </template>
  </v-card>
</template>

<script>
// mixins
import Documents from '@/mixin/documents';
import PrintMixin from '@/mixin/printMixin';
import jwtMixin from '@/mixin/jwtMixin';
import AlertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import mapGeneralConsentData from '@/mixin/MapperMixins/Documents/mapGeneralConsentData';

// utils
import moment from 'moment-timezone';
import Constant from '@/const';
const axios = require('axios');
const _ = require('lodash');

// api
import { getRegistrationById } from '@/fetchApi/patient';
import {
  postGeneralConsent,
  getGeneralConsent,
} from '@/fetchApi/Documents/GeneralConsent';

// components
import Skeleton from '../../SharedComponent/Skeleton';

export default {
  name: 'GeneralConsentFrom',
  mixins: [
    AlertMixin,
    PrintMixin,
    Documents,
    jwtMixin,
    formatMixin,
    mapGeneralConsentData,
  ],
  components: { Skeleton },
  props: {
    isFromServiceDashboard: Boolean,
    isInpatientEmr: { type: Boolean, default: false },
    serviceType: {
      type: String,
      default: '',
    },
    rmNumber: {
      type: String,
      default: '',
    },
    idInpatient: {
      type: String,
      default: undefined,
    },
    isEmr: {
      type: Boolean,
      defaul: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      isDetail: false,
      isEditing: false,
      searchStaff: '',
      radioClick: '',
      socialData: '',
      id_emr: '',
      loading: {
        staff: false,
        save: false,
        social: false,
        data: false,
      },
      input: {
        signer: '',
        name: '',
        address: '',
        telp: '',
        identityNumber: '',
        rmNumber: '',
        staff: '',
        person: [''],
        patientAgreement: {
          patientConsentService: true,
          patientConsentPayment: true,
          patientRightsObligation: true,
          satuSehatAgreement: true,
          patientTermCondition: true,
          patientTranslatorBahasa: true,
          patientRohaniawanNeeds: true,
          patientConsentRelease: true,
          patientConsentInspectionGuarantor: true,
          patientConsentInspectionLearner: true,
          patientConsentInformationFasyankes: true,
        },

        privacy: false,
        restrictedInfo: false,
        visitorAllowed: [
          {
            name: '',
            relation: '',
          },
        ],
        timestamps: null,
      },
      snackbar: {
        text: '',
        show: false,
        color: '',
      },
      resource: {
        staff: [],
        familyStatus: [
          'Ayah',
          'Ibu',
          'Anak',
          'Saudara Kandung',
          'Suami',
          'Istri',
          'Bibi',
          'Paman',
          'Kakek',
          'Nenek',
          'Cucu',
          'Cicit',
          'Tetangga',
          'Rekan Kerja',
          'Lain-lain',
        ],
        pointToPrint: {
          a1: `Saya/pasien menyetujui untuk perawatan di ${
            this.$store.getters.userLoggedIn.clinic.name
          } sebagai pasien ${this.serviceType.toLowerCase()} tergantung kepada kebutuhan medis. Perawatan dapat meliputi pemeriksaan radiologi, pemeriksaan laboratorium, perawatan rutin dan prosedur seperti pemasangan infus atau suntikan dan evaluasi (misal: wawancara dan pemeriksaan fisik).`,
          b1:
            'Persetujuan yang saya/ pasien berikan tidak termasuk persetujuan untuk prosedur/ tindakan invasif (misal : Operasi) atau tindakan yang mempunyai resiko tinggi.',
          c1: `Jika saya/ pasien memutuskan untuk menghentikan perawatan medis untuk diri saya/ pasien sendiri. Saya memahami dan menyadari bahwa ${this.$store.getters.userLoggedIn.clinic.name} atau dokter tidak bertanggung jawab atas hasil yang merugikan saya /pasien.`,
          a2: `Saya/ pasien memahami informasi yang ada dalam diri saya/ pasien, termasuk diagnosis dan hasil tes diagnosis yang akan digunakan untuk perawatan medis, ${this.$store.getters.userLoggedIn.clinic.name} akan menjamin kerahasiaannya`,
          b2: '', // text directly rendered in print mixin
          c2: `Saya/ pasien memberikan wewenang kepada ${this.$store.getters.userLoggedIn.clinic.name} untuk memberikan informasi tentang diagnosis, hasil pelayanan dan pengobatan saya\nkepada :`,
          d2: `Saya/ pasien memberi wewenang kepada ${this.$store.getters.userLoggedIn.clinic.name} untuk memberikan informasi tentang diagnosis, hasil pelayanan dan pengobatan bila diperlukan untuk memproses klaim asuransi/ perusahaan dan atau lembaga pemerintah.`,
          a3:
            'Saya/ pasien memiliki hak untuk mengambil bagian dalam keputusan mengenai penyakit saya/ pasien dan dalam hal perawatan medis dan rencana pengobatan.',
          b3: `Saya/ pasien telah mendapatkan informasi tentang “Hak dan Kewajiban Pasien” di Klinik ${this.$store.getters.userLoggedIn.clinic.name} melalui leaflet yang disediakan.`,
          c3: `Saya/ pasien memahami bahwa ${this.$store.getters.userLoggedIn.clinic.name} tidak bertanggung jawab atas kehilangan barang-barang pribadi dan barang berharga yang dibawa ke klinik.`,
          a4:
            'Saya/ pasien tidak diperkenankan untuk membawa barang berharga ke ruang rawat inap. Jika ada, anggota keluarga atau teman harus diminta untuk membawa pulang barang berharga tersebut.',
          b4:
            'Saya/ pasien telah menerima informasi tentang peraturan yang diberlakukan oleh klinik dan saya beserta keluarga bersedia untuk mematuhinya, termasuk akan mematuhi jam berkunjung pasien sesuai dengan aturan di klinik.',
          c4:
            'Anggota keluarga saya yang menunggu saya, bersedia untuk selalu memakai tanda pengenal khusus yang diberikan oleh klinik.',
          privacy:
            'Saya (mengizinkan/ tidak mengizinkan) klinik memberi akses bagi keluarga dan handai taulan serta orang-orang yang akan menegok saya (sebutkan nama bila ada permintaan khusus yang tidak diijinkan) : (nama orang yang tidak diberi izin menjenguk pasien)',
          bill:
            'Saya memahami tentang informasi biaya pengobatan dan biaya tindakan yang dijelaskan oleh petugas klinik.',
          syariah:
            'Saya menyetujui baik sebagai pasien atau wakil pasien bahwa klinik menerapkan pelayanan Kesehatan berdasarkan prinsip syari’ah yang terdiri dari assesmen spiritual, pemakaian hijab untuk Muslimah, mengingatkan waktu shalat, pemakaian kerudung menutup dada ibu menyusui, dan penunggu pasien adalah mahram atau keluarga.',
          sign:
            'Dengan tanda tangan saya di bawah, saya menyatakan bahwa saya telah membaca dan memahami isi persetujuan umum/ general consent.',
        },
        rights: {
          r1:
            'Mendapat pelayanan kesehatan optimal/ sebaik-baiknya sesuai standar profesi kedokteran',
          r2:
            'Hak atas informasi yang jelas dan benar tentang penyakit dan tindakan medis yang akan dilakukan dokter',
          r3: 'Hak memilih dokter dan pelayanan kesehatan yang merawat pasien',
          r4:
            'Hak atas rahasia kedokteran/ data penyakit, status, diagnosis, dll',
          r5:
            'Hak untuk memberi persetujuan / menolak atas tindakan medis yang akan dilakukan pada pasien',
          r6: 'Hak untuk menghentikan program pengobatan',
          r7:
            'Hak untuk mencari pendapat kedua/pendapat dari dokter lain/ di rumah sakit',
          r8: 'Hak atas isi rekaman medis/ data medis',
          r9: 'Hak untuk didampingi anggota keluarga dalam keadaan kritis',
          r10:
            'Hak untuk memeriksa dan menerima penjelasan tentang biaya yang dikenakan/dokumen pembayaran/bon/bill',
          r11:
            'Pasien berhak mengajukan usul, saran perbaikan atas pelayanan kesehatan terhadap dirinya',
          r12:
            'Hak untuk mendapatkan ganti rugi kalau terjadi kelalaian dan tindakan yang tidak mengikuti prosedur operasi profesi kesehatan',
        },
        obligation: {
          o1:
            'Memberi keterangan yang jujur tentang penyakit dan perjalanan penyakit terhadap petugas kesehatan',
          o2: 'Mematuhi nasihat dokter dan perawat',
          o3: 'Harus ikut menjaga kesehatan dirinya',
          o4: 'Memenuhi imbalan jasa pelayanan',
        },
      },
      disabled: false,
    };
  },
  watch: {
    searchStaff() {
      this.searchStaffData(this);
    },
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    disableState: {
      get() {
        return (
          this.printOnlyState ||
          (this.isDetail && !this.isEditing) ||
          this.isFromServiceDashboard
        );
      },
    },
    printOnlyState: {
      get() {
        return (
          (this.isEmr && this.serviceType === 'Rawat Inap') ||
          this.isInpatientEmr
        );
      },
    },
  },
  created() {
    this.$nextTick(async () => {
      await this.getSocialData();
      this.getGeneralConsentData();
      this.getStaff();
    });
  },
  methods: {
    searchStaffData: _.debounce(v => {
      v.getStaff(v.searchStaff);
    }),
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    async getSocialData() {
      try {
        this.loading.social = true;
        const response = await getRegistrationById(this.rmNumber);
        this.socialData = {
          name: response.name,
          gender: response.gender,
          birthDate: moment(response.birth.date, 'YYYY-MM-DD').format(
            'D MMMM YYYY',
          ),
          age: response.age,
          address: this.formatAddress(response.address),
          telp: response.phone_number,
          identityNumber: response.identity.number,
        };
        this.id_emr = response.id_emr;
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.loading.social = false;
      }
    },
    getStaff(keyword = '') {
      this.loading.staff = true;
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/master/staff?search=${keyword || ''}`))
          .then(response => {
            const data = response.data.data;
            this.resource.staff = data.map(staff => {
              return {
                id: staff._id,
                text: staff.detail.name,
                role: staff.role.role,
              };
            });
          })
          .catch(() => {
            this.resource.staff = [];
          })
          .finally(() => {
            this.loading.staff = false;
          });
      });
    },
    printConsent() {
      if (this.$refs.form.validate()) {
        const doc = this.generateGeneralConsentDoc(this.input, this.socialData);
        this.printDocument(doc, {
          docName: `General Consent ${this.serviceType}`,
          rmNumber: this.rmNumber,
        });
      }
    },
    addVisitor(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.visitorAllowed[index].name !== '' &&
        this.input.visitorAllowed.length - 1 === index
      ) {
        this.input.visitorAllowed.push({
          name: '',
          relation: '',
        });
      } else if (
        this.input.visitorAllowed[index].name === '' &&
        this.input.visitorAllowed.length !== 1
      ) {
        this.input.visitorAllowed.splice(index, 1);
      }
    },
    addPerson(event, index) {
      if (event.keyCode === 9) return;
      if (
        this.input.person[index] !== '' &&
        this.input.person.length - 1 === index
      ) {
        this.input.person.push('');
      } else if (
        this.input.person[index] === '' &&
        this.input.person.length !== 1
      ) {
        this.input.person.splice(index, 1);
      }
    },
    async updateGeneralConsent() {
      await this.addGeneralConsent(true);
    },
    async addGeneralConsent(isUpdate) {
      try {
        this.loading.save = true;
        if (this.$refs.form.validate()) {
          const payload = this.mapGeneralConsentPayload(
            this.id_emr,
            this.idInpatient,
            this.input,
          );

          await postGeneralConsent(payload);

          this.snackbar = {
            show: true,
            text: `General consent berhasil ${
              isUpdate ? 'diubah' : 'disimpan'
            }`,
            color: '#31b057',
          };
          this.getGeneralConsentData();
        }
      } catch {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, data general consent gagal disimpan',
          color: 'error',
        };
      } finally {
        this.loading.save = false;
        this.isEditing = false;
      }
    },
    async getGeneralConsentData() {
      try {
        this.loading.data = true;

        const response = await getGeneralConsent(this.idInpatient, this.id_emr);

        this.input = this.mapToFormGeneralConsent(response.data.data);
        this.disabled = false;
        this.isDetail = true;
      } catch (error) {
        if (error.response.status === 404) this.disabled = true;
        this.input = {
          ...this.input,
          patientAgreement: {
            patientConsentService: true,
            patientConsentPayment: true,
            patientRightsObligation: true,
            satuSehatAgreement: true,
            patientTermCondition: true,
            patientTranslatorBahasa: true,
            patientRohaniawanNeeds: true,
            patientConsentRelease: true,
            patientConsentInspectionGuarantor: true,
            patientConsentInspectionLearner: true,
            patientConsentInformationFasyankes: true,
          },
          signer: 'pasien',
          name: this.socialData.name,
          address: this.socialData.address,
          telp: this.socialData.telp,
          identityNumber: this.socialData.identityNumber,
          age: this.socialData.age,
          gender: this.socialData.gender,
          rmNumber: this.rmNumber,
          birthDate: this.socialData.birthDate,
          person: [''],
          staff: {
            text: this.$store.getters.userLoggedIn.name,
            role: this.$store.getters.userLoggedIn.role,
            id: this.$store.getters.userLoggedIn.id,
          },
          privacy: false,
          restrictedInfo: false,
          visitorAllowed: [
            {
              name: '',
              relation: '',
            },
          ],
        };
        this.isDetail = false;
      } finally {
        this.loading.data = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-layout {
  height: 67vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.card-layout-service {
  height: 77vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}

.required:after {
  content: ' *';
  color: red;
}

@media screen and (max-width: 1254px) {
  .card-layout {
    height: 57vh;
  }
}

.chip-created {
  border-radius: 4px !important;
  background-color: #f0f9ff !important;
  font-size: 0.6vw;
  border: 1px solid #2f80ed;
  color: #2f80ed;
}
.chip-signed {
  border-radius: 4px !important;
  background-color: #e5fff0 !important;
  font-size: 0.6vw;
  border: 1px solid #2d965a;
  color: #2d965a;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
