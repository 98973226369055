import Constant from '@/const';
const axios = require('axios');

const deleteDrugMargin = async () => {
  try {
    await axios.delete(Constant.apiUrl.concat('/setting/drug-margin'));
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default deleteDrugMargin;
