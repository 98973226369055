<template>
  <v-card>
    <v-container fluid class="container-layout">
      <section-title title="data subyektif" />
      <subjective-data />
      <section-title title="data obyektif" />
      <objective-data />
      <section-title title="assesmen nyeri" />
      <pain-assessment />
      <section-title title="skrining" />
      <nutrition-screening :isAdult="isAdult" />
      <v-divider class="my-5"></v-divider>
      <fall-risk
        :isAdult="isAdult"
        :kidAge="kidAge"
        :patientData="patientData"
      />
      <v-row class="d-flex justify-end mb-6 pr-7">
        <v-btn
          @click="changeForm(0)"
          rounded
          text
          :color="hasInfectiousAssessmentData ? 'success' : 'primary'"
          class="text-capitalize"
          ><span
            style="letter-spacing: .04rem"
            class="primary--text ml-4 font-weight-bold"
            :class="{
              'primary--text': !hasInfectiousAssessmentData,
              'success--text': hasInfectiousAssessmentData,
            }"
            >Assesmen Infeksius
            <v-icon :color="hasInfectiousAssessmentData ? 'success' : 'primary'"
              >mdi-arrow-right</v-icon
            ></span
          ></v-btn
        >
        <v-btn
          @click="changeForm(1)"
          rounded
          text
          :color="hasFluidBalanceData ? 'success' : 'primary'"
          class="ml-5 text-capitalize"
          ><span
            style="letter-spacing: .04rem"
            class="ml-4 font-weight-bold"
            :class="{
              'primary--text': !hasFluidBalanceData,
              'success--text': hasFluidBalanceData,
            }"
            >Form Keseimbangan Cairan
            <v-icon :color="hasFluidBalanceData ? 'success' : 'primary'"
              >mdi-arrow-right</v-icon
            ></span
          ></v-btn
        >
      </v-row>
      <section-title title="tindakan gawat darurat" />
      <emergency-action />
      <section-title title="masalah keperawatan" />
      <nursing-problem />
    </v-container>
    <v-card-actions class="pb-0">
      <v-btn text class="text-none" @click="$emit('close-form')">Kembali</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        tile
        depressed
        width="108"
        v-if="!isEmr"
        class="text-none"
        @click="saveData()"
        >Simpan</v-btn
      >
    </v-card-actions>
    <v-dialog
      persistent
      :max-width="selectedForm ? 1300 : 1600"
      v-model="dialog"
    >
      <fluid-balance
        @save="saveFluidBalance"
        :existing-table="fluidBalanceData"
        @close-form="closeForm()"
        v-if="selectedForm === 1 && dialog"
      />
      <infectious-assessment
        :existing-data="infectiousAssessmentData"
        @save="saveInfectiousAssessmentData"
        @close-form="closeForm()"
        v-if="selectedForm === 0 && dialog"
      />
    </v-dialog>
    <v-snackbar timeout="4000" v-model="snackbar" color="success"
      ><span class="font-weight-bold">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          fab
          small
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import SectionTitle from '../../../SharedComponent/Assesment/SectionTitle.vue';
import SubjectiveData from '../../../SharedComponent/Assesment/SubjectiveData.vue';
import ObjectiveData from '../../../SharedComponent/Assesment/ObjectiveData.vue';
import PainAssessment from '../../../SharedComponent/Assesment/PainAssessment.vue';
import NutritionScreening from '../../../SharedComponent/Assesment/NutritionScreening.vue';
import FallRisk from '../../../SharedComponent/Assesment/FallRisk.vue';
import EmergencyAction from '../../../SharedComponent/Assesment/EmergencyAction.vue';
import NursingProblem from '../../../SharedComponent/Assesment/NursingProblem.vue';
import FluidBalance from '../../../SharedComponent/Assesment/FluidBalance.vue';
import InfectiousAssessment from '../../../SharedComponent/Assesment/InfectiousAssessment.vue';
import { createNamespacedHelpers } from 'vuex';
import { postAssessment } from '../../../../fetchApi/Assessment/IGD';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import AlertMixin from '@/mixin/alertMixin';

const { mapGetters: mapIGDAssessmentGetters } = createNamespacedHelpers(
  'IGDAssessment',
);
const { mapGetters: mapSubjectiveDataGetters } = createNamespacedHelpers(
  'SubjectiveData',
);
const { mapGetters: mapObjectiveDataGetters } = createNamespacedHelpers(
  'ObjectiveData',
);
const { mapGetters: mapPainAssessmentGetters } = createNamespacedHelpers(
  'PainAssessment',
);
const { mapGetters: mapNutritionScreeningGetters } = createNamespacedHelpers(
  'NutritionScreening',
);
const { mapGetters: mapFallRiskGetters } = createNamespacedHelpers('FallRisk');
const { mapGetters: mapEmergencyActionGetters } = createNamespacedHelpers(
  'EmergencyAction',
);
const { mapGetters: mapNursingProblemGetters } = createNamespacedHelpers(
  'NursingProblem',
);

export default {
  name: 'NurseAssessment',
  mixins: [AlertMixin],
  components: {
    SectionTitle,
    SubjectiveData,
    ObjectiveData,
    NutritionScreening,
    PainAssessment,
    InfectiousAssessment,
    FluidBalance,
    NursingProblem,
    EmergencyAction,
    FallRisk,
  },
  props: {
    patientData: {
      type: Object,
    },
    hasinfectiousdata: {
      type: Boolean,
    },
    existingFluidBalanceData: {
      type: Array,
    },
    existingInfectiousData: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: false,
      selectedForm: null,
      hasInfectiousAssessmentData: false,
      snackbarText: '',
      isEditingFluidBalance: '',
      isEditinginfectiousAssessment: '',
      fluidBalanceData: [],
      infectiousAssessmentData: {},
    };
  },
  mounted() {
    this.fluidBalanceData = this.existingFluidBalanceData;
    this.hasInfectiousAssessmentData = this.hasinfectiousdata;
    this.infectiousAssessmentData = this.existingInfectiousData || {};
  },
  computed: {
    ...mapIGDAssessmentGetters(['getIsEmr']),
    ...mapSubjectiveDataGetters([
      'getAnamnesis',
      'getAllergy',
      'getPriorDisease',
      'getTreatmentHistory',
      'getPsychologyStatus',
      'getPsychologyStatusCheckbox',
      'getSocialEconomy',
      'getSpiritual',
    ]),
    ...mapObjectiveDataGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
    ]),
    ...mapPainAssessmentGetters([
      'getScore',
      'getPainFactor',
      'getPainLike',
      'getPainRadiation',
      'getSeverity',
      'getPainFrequency',
      'getPainType',
      'getPainDuration',
      'getDescription',
    ]),
    ...mapNutritionScreeningGetters([
      'getIsThin',
      'getIsLossWeight',
      'getIsHasCondition',
      'getIsMalnutritionRisk',
      'getIsUnplannedWeightLoss',
      'getIsUnplannedWeightLossAmount',
      'getIsFoodIntakePoorDueToDecreasedAppetite',
      'getIsHaveSeriousIllness',
    ]),
    ...mapFallRiskGetters([
      'getSurgery',
      'getDiagnose',
      'getCogntiveImpairment',
      'getMedicamentosa',
      'getHeparinLockTherapy',
      'getMentalStatus',
      'getFallHistory',
      'getSecDiagnose',
      'getWalker',
      'getWalk',
    ]),
    ...mapEmergencyActionGetters([
      'getBasicLifeSupport',
      'getInvasiveAction',
      'getSplintAction',
      'getSupport',
    ]),
    ...mapNursingProblemGetters(['getNursingProblem']),
    isEmr: {
      get() {
        return this.getIsEmr;
      },
    },

    // Subjective data
    anamnesis: {
      get() {
        return this.getAnamnesis;
      },
      set(value) {
        this.setAnamnesis(value);
      },
    },
    allergy: {
      get() {
        return this.getAllergy;
      },
      set(value) {
        this.setAllergy(value);
      },
    },
    priorDisease: {
      get() {
        return this.getPriorDisease;
      },
      set(value) {
        this.setPriorDisease(value);
      },
    },
    treatmentHistory: {
      get() {
        return this.getTreatmentHistory;
      },
      set(value) {
        this.setTreatmentHistory(value);
      },
    },
    psychologyStatusCheckbox: {
      get() {
        return this.getPsychologyStatusCheckbox;
      },
      set(value) {
        this.setPsychologyStatusCheckbox(value);
      },
    },
    psychologyStatus: {
      get() {
        return this.getPsychologyStatus;
      },
      set(value) {
        this.setPsychologyStatus(value);
      },
    },
    socialEconomy: {
      get() {
        return this.getSocialEconomy;
      },
      set(value) {
        this.setSocialEconomy(value);
      },
    },
    spiritual: {
      get() {
        return this.getSpiritual;
      },
      set(value) {
        this.setSpiritual(value);
      },
    },
    // Objective data
    sistol: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastol: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respirationRate: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
    // Pain assessment data
    score: {
      get() {
        return this.getScore;
      },
      set(value) {
        this.setScore(value);
      },
    },
    pain_factor: {
      get() {
        return this.getPainFactor;
      },
      set(value) {
        this.setPainFactor(value);
      },
    },
    pain_like: {
      get() {
        return this.getPainLike;
      },
      set(value) {
        this.setPainLike(value);
      },
    },
    pain_radiation: {
      get() {
        return this.getPainRadiation;
      },
      set(value) {
        this.setPainRadiation(value);
      },
    },
    severity: {
      get() {
        return this.getSeverity;
      },
      set(value) {
        this.setSeverity(value);
      },
    },
    pain_frequency: {
      get() {
        return this.getPainFrequency;
      },
      set(value) {
        this.setPainFrequency(value);
      },
    },
    pain_duration: {
      get() {
        return this.getPainDuration;
      },
      set(value) {
        this.setPainDuration(value);
      },
    },
    pain_type: {
      get() {
        return this.getPainType;
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription(value);
      },
    },

    // Nutrition screening data
    isThin: {
      get() {
        return this.getIsThin;
      },
      set(value) {
        this.setIsThin(value);
      },
    },
    isLossWeight: {
      get() {
        return this.getIsLossWeight;
      },
      set(value) {
        this.setIsLossWeight(value);
      },
    },
    isHasCondition: {
      get() {
        return this.getIsHasCondition;
      },
      set(value) {
        this.setIsHasCondition(value);
      },
    },
    isMalnutritionRisk: {
      get() {
        return this.getIsMalnutritionRisk;
      },
      set(value) {
        this.setIsMalnutritionRisk(value);
      },
    },
    isUnplannedWeightLoss: {
      get() {
        return this.getIsUnplannedWeightLoss;
      },
      set(value) {
        this.setIsUnplannedWeightLoss(value);
      },
    },
    isUnplannedWeightLossAmount: {
      get() {
        return this.getIsUnplannedWeightLossAmount;
      },
      set(value) {
        this.setIsUnplannedWeightLossAmount(value);
      },
    },
    isFoodIntakePoorDueToDecreasedAppetite: {
      get() {
        return this.getIsFoodIntakePoorDueToDecreasedAppetite;
      },
      set(value) {
        this.setIsFoodIntakePoorDueToDecreasedAppetite(value);
      },
    },
    isHaveSeriousIllness: {
      get() {
        return this.getIsHaveSeriousIllness;
      },
      set(value) {
        this.setIsHaveSeriousIllness(value);
      },
    },
    // Fall risk data
    surgery: {
      get() {
        return this.getSurgery;
      },
      set(value) {
        this.setSurgery(value);
      },
    },
    diagnose: {
      get() {
        return this.getDiagnose;
      },
      set(value) {
        this.setDiagnose(value);
      },
    },
    cogntiveImpairment: {
      get() {
        return this.getCogntiveImpairment;
      },
      set(value) {
        this.setCogntiveImpairment(value);
      },
    },
    medicamentosa: {
      get() {
        return this.getMedicamentosa;
      },
      setSurgery(value) {
        this.setMedicamentosa(value);
      },
    },
    heparinLockTherapy: {
      get() {
        return this.getHeparinLockTherapy;
      },
      set(value) {
        this.setHeparinLockTherapy(value);
      },
    },
    mentalStatus: {
      get() {
        return this.getMentalStatus;
      },
      set(value) {
        this.setMentalStatus(value);
      },
    },
    fallHistory: {
      get() {
        return this.getFallHistory;
      },
      set(value) {
        this.setFallHistory(value);
      },
    },
    secDiagnose: {
      get() {
        return this.getSecDiagnose;
      },
      set(value) {
        this.setSecDiagnose(value);
      },
    },
    walker: {
      get() {
        return this.getWalker;
      },
      set(value) {
        this.setWalker(value);
      },
    },
    walk: {
      get() {
        return this.getWalk;
      },
      set(value) {
        this.setWalk(value);
      },
    },
    // Emergency actio data
    basicLifeSupport: {
      get() {
        return this.getBasicLifeSupport;
      },
      set(value) {
        this.setBasicLifeSupport(value);
      },
    },
    splintAction: {
      get() {
        return this.getSplintAction;
      },
      set(value) {
        this.setSplintAction(value);
      },
    },
    invasiveAction: {
      get() {
        return this.getInvasiveAction;
      },
      set(value) {
        this.setInvasiveAction(value);
      },
    },
    support: {
      get() {
        return this.getSupport;
      },
      set(value) {
        this.setSupport(value);
      },
    },
    // Nursing problem data
    nursingProblem: {
      get() {
        return this.getNursingProblem;
      },
      set(value) {
        this.setNursingProblem(value);
      },
    },
    hasFluidBalanceData() {
      return this.fluidBalanceData.length > 0;
    },
    isAdult() {
      const birthDate = moment(this.patientData.meta.birthDate, 'YYYY-MM-DD');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
    kidAge() {
      let result,
        age = this.patientData.meta.age;
      if (age < 3) {
        result = {
          text: '<3 Tahun',
          score: 4,
        };
      }
      if (age >= 3 && age <= 7) {
        result = {
          text: '3-7 Tahun',
          score: 3,
        };
      }
      if (age > 7 && age <= 13) {
        result = {
          text: '7-13 Tahun',
          score: 2,
        };
      }
      if (age > 13) {
        result = {
          text: '>13 Tahun',
          score: 1,
        };
      }
      return result;
    },
  },
  methods: {
    saveFluidBalance(data) {
      this.fluidBalanceData = data;
      if (this.isEditingFluidBalance) {
        this.formatSnackbar('Form Keseimbangan Cairan Berhasil Diubah');
      } else {
        this.formatSnackbar('Form Keseimbangan Cairan Berhasil Disimpan');
      }
      this.isEditingFluidBalance = '';
    },
    saveInfectiousAssessmentData(data) {
      Object.assign(this.infectiousAssessmentData, data);
      this.hasInfectiousAssessmentData = Object.values(
        this.infectiousAssessmentData,
      )?.some(i => Boolean(i.checked));
      if (this.isEditinginfectiousAssessment) {
        this.formatSnackbar('Form Assesmen Infeksius Berhasil Diubah');
      } else {
        this.formatSnackbar('Form Assesmen Infeksius Berhasil Disimpan');
      }
      this.isEditinginfectiousAssessment = '';
    },
    formatSnackbar(text) {
      this.snackbar = true;
      this.snackbarText = text;
    },
    changeForm(value) {
      if (value == 0) {
        this.isEditinginfectiousAssessment = this.hasInfectiousAssessmentData;
      }
      if (value == 1) {
        this.isEditingFluidBalance = this.hasFluidBalanceData;
      }
      this.dialog = true;
      this.selectedForm = value;
    },
    closeForm() {
      this.selectedForm = null;
      this.dialog = false;
    },
    close() {
      this.$emit('close-form');
    },
    filterVariable(data, isCheck = false) {
      let result;
      if (data === undefined) {
        result = !isCheck ? null : 'empty';
      } else {
        result = data;
      }
      return result;
    },
    async saveData() {
      // Declare variable for infectious assessment data
      let airborne = [],
        droplet = [],
        contact = [];

      // Filter and mapping infectious assessment data
      const filteredinfectiousAssessmentData = Object.entries(
        this.infectiousAssessmentData,
      ).filter(item => item[1]?.checked || typeof item[1] === 'string');

      filteredinfectiousAssessmentData.forEach(item => {
        if (typeof item[1] !== 'string' && item[1].type === 'airborne') {
          airborne.push(item[1].text);
        }
        if (typeof item[1] !== 'string' && item[1].type === 'droplet') {
          droplet.push(item[1].text);
        }
        if (typeof item[1] !== 'string' && item[1].type === 'contact') {
          contact.push(item[1].text);
        }
      });

      // Mapping payload
      let payload = {
        id_unit: this.$store.getters.selectedPatient.meta.id_poly,
        id_registration: this.patientData.id_registration,
        id_emr: this.patientData.id_emr,
        isAdult: this.isAdult,
        detail_nursery: [
          {
            subjective: {
              anamnesis: this.anamnesis,
              disease_history: this.priorDisease,
              allergy_history: this.allergy,
              treatment_history: this.treatmentHistory,
              psychological_status: this.psychologyStatusCheckbox
                ? this.psychologyStatus
                : ['Tidak Ada Kelainan'],
              social_economy: this.socialEconomy,
              spiritual: this.spiritual,
            },
            objective: {
              sistol: this.sistol === '' ? null : this.sistol,
              diastol: this.diastol === '' ? null : this.diastol,
              heart_rate: this.pulse === '' ? null : this.pulse,
              weight: this.weight === '' ? null : this.weight,
              height: this.height === '' ? null : this.height,
              belly_circumference:
                this.belly_circumference === ''
                  ? null
                  : this.belly_circumference,
              respiration_rate:
                this.respiration_rate === '' ? null : this.respiration_rate,
              saturation: this.saturation === '' ? null : this.saturation,
              temperature: this.temperature === '' ? null : this.temperature,
            },
            pain_assesment: {
              type: this.pain_type,
              detail: {
                score: this.score,
                pain_factor: this.pain_factor,
                pain_like: this.pain_like,
                pain_radiation: this.pain_radiation,
                severity: this.severity,
                pain_frequency: this.pain_frequency,
                pain_duration: this.pain_duration,
                description: this.description,
              },
            },
            nutrition_screening: {
              children: {},
              adult: {},
            },
            fall_risk_assessment: {
              ageScore: this.kidAge.score,
              genderScore:
                this.patientData.meta.gender === 'Laki-laki' ||
                this.patientData.meta.gender === true
                  ? 2
                  : 1,
              children: {},
              adult: {},
            },
            infectious_and_immunocompromise: {
              airborne: {
                chosen: airborne,
                other:
                  filteredinfectiousAssessmentData.filter(
                    i => i[0] === 'other_airborne',
                  )?.[0]?.[1] || null,
              },
              droplet: {
                chosen: droplet,
                other:
                  filteredinfectiousAssessmentData.filter(
                    i => i[0] === 'other_droplet',
                  )?.[0]?.[1] || null,
              },
              contact: {
                chosen: contact,
                other:
                  filteredinfectiousAssessmentData.filter(
                    i => i[0] === 'other_contact',
                  )?.[0]?.[1] || null,
              },
            },
            fluid_balance: this.fluidBalanceData.map(item => {
              return {
                added_at: item.time,
                oral: item.sub_income[0].value,
                in: item.sub_income
                  .slice(1)
                  .map(v => {
                    return {
                      name: v.label,
                      amount: +v.value,
                    };
                  })
                  .concat(
                    item.otherSubIncome.map(y => {
                      return {
                        name: y.label,
                        amount: +y.value,
                      };
                    }),
                  ),
                out: item.outcome.map(i => {
                  return {
                    name: i.label,
                    amount: +i.value,
                  };
                }),
              };
            }),
            igd_actions: {
              basic_life_support: this.basicLifeSupport,
              invasive_actions: this.invasiveAction,
              splint_actions: this.splintAction,
              support: this.support,
            },
            nursing_problem: this.nursingProblem.map(item => {
              return {
                _id: false,
                data: {
                  text: item.content?.text || '',
                  value: item.content?.value || '',
                },
                plan: item.treatmentPlan.map(i => {
                  return i.content?.text || '';
                }),
              };
            }),
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
              nurse_by: this.$store.getters.userLoggedIn.id,
              nurse_created_at: moment().format(),
            },
          },
        ],
      };
      // Assign variable which depends isAdult variable
      if (this.isAdult) {
        payload = {
          ...payload,
          detail_nursery: [
            {
              ...payload.detail_nursery[0],
              nutrition_screening: {
                children: {},
                adult: {
                  isUnplannedWeightLoss: {
                    check:
                      this.isUnplannedWeightLoss === true
                        ? this.filterVariable(
                            this.isUnplannedWeightLossAmount?.check,
                            true,
                          )
                        : this.filterVariable(
                            this.isUnplannedWeightLoss?.check,
                          ),
                    score:
                      this.isUnplannedWeightLoss === true
                        ? this.filterVariable(
                            this.isUnplannedWeightLossAmount?.score,
                          )
                        : this.filterVariable(
                            this.isUnplannedWeightLoss?.score,
                          ),
                  },
                  isFoodIntakePoorDueToDecreasedAppetite: {
                    check: this.filterVariable(
                      this.isFoodIntakePoorDueToDecreasedAppetite?.check,
                    ),
                    score: this.filterVariable(
                      this.isFoodIntakePoorDueToDecreasedAppetite?.score,
                    ),
                  },
                  isHaveSeriousIllness: {
                    check: this.filterVariable(
                      this.isHaveSeriousIllness?.check,
                    ),
                    score: this.filterVariable(
                      this.isHaveSeriousIllness?.score,
                    ),
                  },
                },
              },
              fall_risk_assessment: {
                children: {},
                adult: {
                  fallHistory: {
                    check: this.filterVariable(this.fallHistory?.check),
                    score: this.filterVariable(this.fallHistory?.score),
                  },
                  secondaryDiagnosis: {
                    check: this.filterVariable(this.secDiagnose?.check),
                    score: this.filterVariable(this.secDiagnose?.score),
                  },
                  walkTool: {
                    check: this.filterVariable(this.walker?.check, true),
                    score: this.filterVariable(this.walker?.score),
                  },
                  useHeparinLockTherapy: {
                    check: this.filterVariable(this.heparinLockTherapy?.check),
                    score: this.filterVariable(this.heparinLockTherapy?.score),
                  },
                  howToWalk: {
                    check: this.filterVariable(this.walk?.check, true),
                    score: this.filterVariable(this.walk?.score),
                  },
                  mentalStatus: {
                    check: this.filterVariable(this.mentalStatus?.check, true),
                    score: this.filterVariable(this.mentalStatus?.score),
                  },
                },
              },
            },
          ],
        };
      } else {
        payload = {
          ...payload,
          detail_nursery: [
            {
              ...payload.detail_nursery[0],
              nutrition_screening: {
                adult: {},
                children: {
                  isThin: {
                    check: this.filterVariable(this.isThin?.check, true),
                    score: this.filterVariable(this.isThin?.score),
                  },
                  isWeightLossLastMonth: {
                    check: this.filterVariable(this.isLossWeight?.check, true),
                    score: this.filterVariable(this.isLossWeight?.score),
                  },
                  isThereOfTheseCondition: {
                    check: this.filterVariable(
                      this.isHasCondition?.check,
                      true,
                    ),
                    score: this.filterVariable(this.isHasCondition?.score),
                  },
                  isThereCausesMalnourished: {
                    check: this.filterVariable(
                      this.isMalnutritionRisk?.check,
                      true,
                    ),
                    score: this.filterVariable(this.isMalnutritionRisk?.score),
                  },
                },
              },
              fall_risk_assessment: {
                adult: {},
                children: {
                  ageScore: this.kidAge.score,
                  genderScore:
                    this.patientData.meta.gender === 'Laki-laki' ||
                    this.patientData.meta.gender === true
                      ? 2
                      : 1,
                  diagnosis: {
                    check: this.filterVariable(this.diagnose?.value, true),
                    score: this.filterVariable(this.diagnose?.score),
                  },
                  cognitiveImpairment: {
                    check: this.filterVariable(
                      this.cogntiveImpairment?.value,
                      true,
                    ),
                    score: this.filterVariable(this.cogntiveImpairment?.score),
                  },
                  surgery: {
                    check: this.filterVariable(this.surgery?.value, true),
                    score: this.filterVariable(this.surgery?.score),
                  },
                  medicinalUse: {
                    check: this.filterVariable(this.medicamentosa?.value, true),
                    score: this.filterVariable(this.medicamentosa?.score),
                  },
                },
              },
            },
          ],
        };
      }
      try {
        const response = await postAssessment({
          payload,
          role: 'perawat',
        });
        if (response.status === 200) {
          Swal.fire('Data Asesmen IGD Berhasil Ditambahkan', '', 'success');
          this.close();
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--outlined {
  border: 2px solid currentColor;
}
::v-deep .theme--light.v-label {
  color: black;
}
.container-layout {
  height: 73vh;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: grey;
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.grid-table {
  display: grid;
  grid-template-columns: 150px 218px 100px 150px 100px;
  border-top: 3px solid #f4f5f5;
  border-bottom: 3px solid #f4f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}
.grid-oral {
  display: grid;
  grid-template-columns: 150px 320px 250px 30px 30px;
  padding-top: 10px;

  .data {
    display: grid;
    grid-template-columns: 150px 100px;
  }
  .data-in {
    display: grid;
    grid-template-columns: 220px 100px;
  }
}
.grid-cairan {
  display: grid;
  grid-template-columns: 180px 120px 120px 130px 100px;
}

.grid-liquid {
  display: grid;
  grid-template-columns: 100px 100px 50px;
  grid-column-gap: 10px;
  text-align: left;
}
::v-deep #input-606 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-620 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-623 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-626 {
  padding-top: 0;
  padding-bottom: 0;
}
::v-deep #input-629 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
