import Constant from '@/const';
const axios = require('axios');

const getPreviewMargin = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/drugs/margin-preview'),
      { params },
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getPreviewMargin;
