<template>
  <v-card class="pa-3">
    <div v-if="isHasUnprintedLetter" class="alert-chip">
      <v-icon small color="#d32f2f" class="mr-1">mdi-alert</v-icon>
      <span>Surat ini belum diprint</span>
    </div>
    <v-form ref="form">
      <v-row class="px-15 mx-10 mt-0 mb-16 scrollbar">
        <v-col>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nomor Surat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ numberLetter }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Nama Pasien</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.name }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No RM</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.rmNumber }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Jenis Kelamin</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ genderMap }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">Alamat</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.address }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold">No. KTP</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <label class="">{{ patientData.meta.identityNumber }}</label>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Dokter Pemeriksa</label>
            </v-col>
            <v-col cols="7" class="">
              <v-autocomplete
                append-icon="mdi-chevron-down"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                class="pt-0 mt-0"
                placeholder="Pilih Dokter"
                clearable
                auto-select-first
                :items="resource.doctor"
                item-text="doctorName"
                item-value="doctorId"
                return-object
                v-model="input.doctor"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row>
            <v-col>
              <v-radio-group
                row
                class="mt-0 pt-0"
                hide-details="false"
                v-model="input.death_type"
              >
                <v-radio
                  label="Kematian"
                  value="dead"
                  @click="
                    radioSelected = 'death';
                    checkDisabled();
                  "
                ></v-radio>
                <v-radio
                  label="Death on Arrival (DOA)"
                  value="death on arrival"
                  @click="
                    radioSelected = 'doa';
                    checkDisabled();
                  "
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4 style="text-align: center" class="blue--text">
                Telah meninggal dunia pada
              </h4>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Tanggal</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <v-menu
                ref="menuDate"
                v-model="menuDate"
                :close-on-content-click="false"
                :return-value.sync="input.date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.date"
                    placeholder="Pilih tanggal"
                    append-icon="mdi-chevron-down"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="[v => !!v || 'Wajib diisi']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.date"
                  :max="today"
                  no-title
                  scrollable
                  @input="
                    $refs.menuDate.save(input.date);
                    menuDate = false;
                  "
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Jam</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <v-text-field
                v-model="input.time"
                placeholder="Pilih Jam"
                :rules="[v => !!v || 'Wajib diisi']"
                type="time"
                @keydown="preventDefaultBehaviour($event)"
                single-line
                dense
              />
            </v-col>
          </v-row>
          <v-row v-show="input.death_type === 'dead'" align="center">
            <v-col cols="4" class="text-left">
              <label class="font-weight-bold required">Jenis Penyakit</label>
            </v-col>
            <v-col cols="7" class="text-left">
              <v-select
                append-icon="mdi-chevron-down"
                v-if="input.death_type === 'dead'"
                :rules="[v => !!v || 'Wajib diisi']"
                dense
                placeholder="Pilih Penyakit"
                :items="resource.diseaseType"
                v-model="input.disease_type"
              ></v-select>
              <v-select
                append-icon="mdi-chevron-down"
                v-else-if="input.death_type === 'death on arrival'"
                dense
                placeholder="Pilih Penyakit"
                :items="resource.diseaseType"
                v-model="input.disease_type"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="pa-0">
      <v-btn class="text-none" plain @click="$emit('close-form')">
        Kembali
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        tile
        outlined
        color="primary"
        class="text-none"
        width="116"
        :disabled="disabled"
        @click="
          input.death_type === 'dead' ? printDeathLetter() : printDOALetter()
        "
      >
        <font-awesome-icon icon="print" />
        Print
      </v-btn>
      <v-btn
        color="primary"
        tile
        elevation="0"
        class="text-none"
        width="108"
        @click="addMortalityLetter()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Constant from '@/const';
import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import Letter from '@/mixin/Letter';
import 'jspdf-autotable';
import moment from 'moment-timezone';
import jwtMixin from '@/mixin/jwtMixin';
import printMixin from '@/mixin/printMixin';

import { postUpdateLetterStatus } from '@/fetchApi/Letter';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'Mortality',
  mixins: [AlertMixin, jwtMixin, printMixin, Letter],
  props: {
    isHasUnprintedLetter: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clientData: this.$store.getters.userLoggedIn.clinic,
      menuDate: false,
      radioSelected: 'death',
      today: moment().format(),
      resource: {
        doctor: [],
        diseaseType: ['Menular', 'Tidak Menular'],
        days: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
      },
      input: {
        death_type: 'dead',
        date: null,
        doctor: '',
        time: null,
        disease_type: '',
      },
      timestamps: new Date(),
      numberLetter: '',
      disabled: false,
    };
  },
  computed: {
    letterData() {
      return Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
    },
    isPhysiotherapy() {
      return (
        this.patientData.meta.polyName.toLowerCase() === 'poli fisioterapi'
      );
    },
    genderMap() {
      const gender = this.patientData.meta.gender;
      return typeof gender === 'boolean'
        ? gender
          ? 'Laki-laki'
          : 'Perempuan'
        : gender;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(async () => {
        await this.getDoctor();
        this.getDeathLetter();
      });
    });
  },
  methods: {
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    getDoctor() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/staff?role[]=Dokter&role[]=Bidan&role[]=dokter&role[]=bidan${
              this.isPhysiotherapy
                ? '&role[]=fisioterapis&role[]=Fisioterapis'
                : ''
            }`,
          ),
        )
        .then(response => {
          if (response.status === 200) {
            var data = response.data.data;
            this.resource.doctor = data.map(doctor => {
              return {
                doctorId: doctor._id.toString(),
                doctorName: doctor.detail.name,
              };
            });

            // this.getOutpatientLetter();
          }
        })
        .catch(() => {
          this.resource.doctor = [];
        });
    },
    checkDisabled() {
      this.disabled =
        (this.input.disease_type === '' && this.input.death_type === 'dead') ||
        (this.input.date === null &&
          this.input.time === null &&
          this.input.death_type === 'death on arrival')
          ? true
          : false;
    },
    addMortalityLetter() {
      if (this.$refs.form.validate()) {
        const payload = {
          id_registration: this.patientData.id_registration,
          letter_number: this.numberLetter,
          detail: {
            ...this.input,
            doctor: this.input.doctor.doctorId,
            disease_type: this.input.disease_type.toLowerCase() || '',
            isInfectius: this.input.disease_type === '' ? null : false,
            timestamps: {
              created_by: this.$store.getters.userLoggedIn.id,
            },
          },
        };
        // if (this.radioSelected === 'doa' && this.input.disease_type === '') {
        //   this.input.disease_type = '';
        // }

        axios
          .post(Constant.apiUrl.concat('/letter/death-patient-letter'), payload)
          .then(() => {
            Swal.fire(
              'Surat Keterangan Kematian Berhasil Dibuat',
              '',
              'success',
            );
            this.getDeathLetter();
            this.$emit('get-letter-status');
            // this.$emit('close-form');
          })
          .catch(err => {
            this.showErrorAxios(err);
          });
      }
    },
    async printDeathLetter() {
      const doc = this.generateDeathLetter(
        {
          ...this.patientData,
          meta: {
            ...this.patientData.meta,
            gender: this.patientData.meta.gender ? 'Laki-laki' : 'Perempuan',
          },
        },
        this.input,
      );
      this.printDocument(doc, {
        docName: 'Surat Keterangan Kematian',
        rmNumber: this.patientData.rmNumber,
      });
      const payload = {
        letter: 'DeathLetter',
        id_registration: this.patientData.id_registration,
      };
      await postUpdateLetterStatus(payload);
      this.$emit('get-letter-status');
    },
    async printDOALetter() {
      const doc = this.generateDOALetter(
        {
          ...this.patientData,
          meta: {
            ...this.patientData.meta,
            gender: this.patientData.meta.gender ? 'Laki-laki' : 'Perempuan',
          },
        },
        this.input,
      );
      this.printDocument(doc, {
        docName: 'Surat Keterangan Death on Arrival',
        rmNumber: this.patientData.rmNumber,
      });
      const payload = {
        letter: 'DeathLetter',
        id_registration: this.patientData.id_registration,
      };
      await postUpdateLetterStatus(payload);
      this.$emit('get-letter-status');
    },
    generateNumber() {
      axios
        .get(Constant.apiUrl.concat('/letter/death-patient-letter/generate'))
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    getDeathLetter() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/letter/death-patient-letter/registration/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          this.disabled = false;
          const death = response.data.data;
          this.input = {
            death_type: _.get(death, 'detail.death_type', ''),
            date: _.get(death, 'detail.date', ''),
            doctor: {
              doctorId: death.detail.doctor.id,
              doctorName: death.detail.doctor.name,
              sip: death.detail.doctor.sip,
            },
            time: _.get(death, 'detail.time', ''),
            disease_type: death.detail.disease_type
              ? _.startCase(death.detail.disease_type)
              : '',
          };
          (this.timestamps = _.get(
            death,
            'detail.timestamps.created_at',
            new Date(),
          )),
            (this.numberLetter = death.letter_number);
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.disabled = true;
            this.input.doctor = {
              doctorId: this.patientData.meta.doctorId,
              doctorName: this.patientData.meta.doctor,
            };
            this.generateNumber();
          } else this.showErrorAxios(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-chip {
  background-color: #ffebee;
  border: 1px solid #ededed;
  color: #616161;
  font-size: 85%;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.7vh 0.8vw;
}

.body-container {
  padding-left: 150px;
}
.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
  &__data {
    height: 20px;
  }
}

.scrollbar {
  height: 400px;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #c2c2c2;
  }
}
.required::after {
  content: '*';
  color: #eb4747;
}

::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
