<template>
  <div class="main-container text-start">
    <div class="d-flex justify-center" v-if="getLoadingState.dashboard">
      <v-progress-circular
        indeterminate
        size="100"
        class="mt-5"
      ></v-progress-circular>
    </div>
    <template v-else>
      <global-setting-dashboard
        v-if="getSelectedSetting === 'global-setting' && isRenderDashboard"
      />
      <category-setting-dashboard
        v-if="
          (getSelectedSetting === 'global-category-setting' ||
            getSelectedSetting === 'category-setting') &&
            isRenderDashboard
        "
      />
      <template v-if="!isRenderDashboard">
        <h1 class="pt-8">Margin dan Harga</h1>
        <p class="pb-5">
          Silakan pilih level setting dan harga terlebih dahulu
        </p>
        <v-btn
          depressed
          class="text-capitalize"
          style="letter-spacing: 0.001px;"
          color="primary"
          @click="openDialog(0)"
        >
          Mulai
          <v-icon class="ml-1">mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="dialogWidth[selectedForm] || '100vw'"
        transition="dialog-transition"
      >
        <setting-option-dialog
          @start-setting="startSetting"
          v-if="dialog && selectedForm === 0"
        />
        <setting-global-margin-and-price-dialog
          v-if="dialog && selectedForm === 1"
        />
        <global-margin-and-price-calculation-dialog
          @finish-setting="finishSetting"
          v-if="dialog && selectedForm === 2"
        />
        <setting-category-margin-and-price-dialog
          @finish-setting="finishSetting"
          v-if="dialog && selectedForm === 3"
        />
        <global-category-setting-dialog
          @finish-setting="finishSetting"
          v-if="dialog && selectedForm === 4"
        />
      </v-dialog>
      <v-snackbar
        :timeout="4000"
        v-model="snackbar.show"
        :color="snackbar.color"
      >
        <div class="d-flex justify-space-between">
          <span class="mr-auto">{{ snackbar.text }}</span>
          <v-icon
            class="ml-auto"
            dark
            small
            @click="() => (snackbar.show = false)"
          >
            mdi-close
          </v-icon>
        </div>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import { createNamespacedHelpers } from 'vuex';

import SettingOptionDialog from './components/SettingOptionDialog.vue';
import SettingGlobalMarginAndPriceDialog from './components/GlobalSetting/SettingGlobalMarginAndPriceDialog.vue';
import GlobalMarginAndPriceCalculationDialog from './components/GlobalSetting/GlobalMarginAndPriceCalculationDialog.vue';
import GlobalSettingDashboard from './components/GlobalSetting/GlobalSettingDashboard.vue';
import SettingCategoryMarginAndPriceDialog from './components/CategorySetting/SettingCategoryMarginAndPriceDialog.vue';
import CategorySettingDashboard from './components/CategorySetting/CategorySettingDashboard.vue';
import GlobalCategorySettingDialog from './components/GlobalCateogrySetting/GlobalCategorySettingDialog.vue';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'PriceAndMarginDashboardStore',
);

export default {
  name: 'PriceAndMarginDashboard',
  components: {
    SettingOptionDialog,
    SettingGlobalMarginAndPriceDialog,
    GlobalMarginAndPriceCalculationDialog,
    GlobalSettingDashboard,
    SettingCategoryMarginAndPriceDialog,
    CategorySettingDashboard,
    GlobalCategorySettingDialog,
  },
  data() {
    return {
      isRenderDashboard: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getDialog',
      'getSelectedForm',
      'getSelectedSetting',
      'getInputState',
      'getLoadingState',
    ]),
    dialog: {
      get() {
        return this.getDialog;
      },
    },
    selectedForm: {
      get() {
        return this.getSelectedForm;
      },
    },
    dialogWidth() {
      return {
        0: '45vw',
        1: '50vw',
        2: '80vw',
        3: '100vw',
        4: '100vw',
      };
    },
  },
  mounted() {
    this.getDrugMargin();
  },
  methods: {
    ...mapActions(['resolveGetDrugMargin']),
    ...mapMutations([
      'setDialog',
      'setSelectedForm',
      'clearPriceState',
      'resolveGetPreviewCalculation',
    ]),
    async getDrugMargin() {
      try {
        await this.resolveGetDrugMargin();
        this.isRenderDashboard = true;
      } catch (error) {
        if (
          error.response.data.pesan === 'Bad Request: Margin belum disetting'
        ) {
          this.isRenderDashboard = false;
        }
      }
    },
    openDialog(value) {
      this.setSelectedForm(value);
      this.setDialog(true);
    },
    openSettingOptionDialog() {
      this.setDialog(true);
      this.setSelectedForm(0);
    },
    async openConfirmationDialog({
      title,
      iconHtml,
      html,
      confirmButtonText,
      cancelButtonText,
    }) {
      const result = await Swal.fire({
        iconHtml,
        html,
        confirmButtonText,
        cancelButtonText,
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>${title}</p></div>`,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        showCancelButton: true,
        reverseButtons: true,
      });
      return result;
    },
    startSetting() {
      this.setDialog(false);
      this.setSelectedForm('');
      this.openConfirmationDialog({
        title: 'Pastikan setting level sudah sesuai dengan aturan klinik Anda!',
        iconHtml:
          '<span style="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Proses ini tidak dapat diubah ketika sistem sudah berjalan</p></div>',
        confirmButtonText: 'Lanjut <span class="mdi mdi-arrow-right"></span>',
        cancelButtonText: 'Cek kembali',
      }).then(result => {
        if (result.isConfirmed) {
          switch (this.getSelectedSetting) {
            case 'global-setting':
              this.openDialog(1);
              break;
            case 'category-setting':
              this.openDialog(3);
              break;
            case 'global-category-setting':
            case 'global-specific-setting':
              this.openDialog(4);
              break;
            default:
              return;
          }
        } else {
          this.openDialog(0);
        }
      });
    },
    finishSetting(data) {
      const confirmationAlertText = !this.isRenderDashboard
        ? {
            title: 'Konfirmasi Pengaturan',
            iconHtml:
              '<span style="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
            html:
              '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Setelah pengaturan ini disimpan, level setting tidak dapat</p><p>diubah. Namun, Anda masih dapat mengatur margin, PPN,</p><p>dan pengaturan lain sesuai kebutuhan sebelum menyimpan.</p></div>',
            confirmButtonText: 'Simpan Setting',
            cancelButtonText: 'Cek kembali',
          }
        : {
            title: 'Anda yakin ingin melakukan perubahan?',
            iconHtml:
              '<span style="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
            html:
              '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Perubahan akan memengaruhi perhitungan di E-resep, BHP,</p><p>dan Penjualan Obat Bebas. Pastikan pengaturan sudah</p><p>sesuai.</p></div>',
            confirmButtonText: 'Simpan Perubahan',
            cancelButtonText: 'Cek kembali',
          };

      this.openConfirmationDialog(confirmationAlertText).then(async result => {
        try {
          if (result.isConfirmed) {
            await data.confirmationFunction();
            await this.getDrugMargin();
            await this.resolveGetPreviewCalculation();
            this.setDialog(false);
            this.setSelectedForm('');
            this.clearPriceState();
            this.isRenderDashboard = true;
            this.snackbar = {
              show: true,
              text: data.snackbarText,
              color: 'success',
            };
            return; // TODO (SOME FUNCTION GOES HERE)
          } else {
            this.openDialog(data.origin);
          }
        } catch (e) {
          // console.log(e);
          this.snackbar = {
            show: true,
            text: 'Terjadi kesalahan, gagal menyimpan setting harga farmasi',
            color: 'error',
          };
          this.openDialog(data.origin);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  margin: 0 1vw !important;
  width: 100%;
}

p {
  color: #9e9e9e;
}
</style>
